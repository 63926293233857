<template>
  <a-card title="发布账号管理">
    <a-space >
      <a-form layout="inline" @submit="handleTableChange" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item v-if="userRole != UserRoleBlogger"  label="" >
          <a-select
            v-model:value="formSearch.bloggers"
            :options="bloggerSuggestion"
            @search="searchBloggers"
            @change="onChangeBloggers"
            mode="multiple"
            placeholder="博主名称"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-input placeholder="平台" v-model:value="formSearch.platform" />
        </a-form-item>
        <a-form-item>
          <a-input placeholder="账号昵称" v-model:value="formSearch.account_name" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            @click="search"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>
    
    </a-space>
    
    <a-space>
      <a-button  type='primary' @click="handleAccountEditorShow('new', {})" >新增账号绑定</a-button>
      <template v-if="userRole != UserRoleBlogger">
        <a-button  type='primary' @click="handleExportAccounts" >导出发布账号</a-button>
        <a-button type="primary" @click="handleImportAccounts" >导入发布账号</a-button>
        <a href="https://docs.qq.com/sheet/DVFhicHl4QUdPRnpE?tab=BB08J2" target="__blank">博主发布账号绑定模板</a>
        <input ref='uploadInput' style="width:0px;" type= "file" class="dl-none" name="icon" @change="onChangeFile"/>
      </template>
    </a-space>
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    :scroll="{ x: true }"
    >
      <template #username="{ record }">
        <span>{{ record.username ? record.username : '-' }}</span>
      </template>
      <template #home_url="{ record }">
        <a :href="record.home_url" >{{record.home_url}}</a>
      </template>
      <template #action="{ record }" >
        <a @click="handleAccountEditorShow('edit', record)" >编辑</a>
          <a-divider type="vertical" />
        <a style="color: red;" @click="handleDeleteAccount(record)">删除</a>
      </template>
   
    </a-table>
  </a-card>

  <AccountEditor v-model:visible="accountEditorVisible" :record="accountEditorModel" @ok="handleAccountEditorSuccess"  @cancel="accountEditorVisible=false" />

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
import AccountEditor from '@/components/AccountEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'
import {
  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
} from '@/utils/macro'

let _searchBloggerDebounce

export default defineComponent({
  components: {
    AccountEditor,
    SearchOutlined
  },
  data(){
    return {
      loading: false,
      accountEditorVisible: false,
      accountEditorModel: {},
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      bloggerSuggestion: [],
       formSearch: {
        platform: '',
        user_id: 0,
        account_name: '',
        bloggers: [],
      },

      uploadLoading: false,
    }
  },
  mounted(){
    this.search()
  },
  computed:{},
  methods: {
    onChangeBloggers(item, options){
      if(options.length > 1) {
        message.error("只能选择一项")
        this.formSearch.bloggers = [this.formSearch.bloggers[0]]
        return false
      }
      for(let v of options) {
        this.formSearch.user_id = v.id
        return
      }
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
    handleAccountEditorSuccess: function() {
      this.accountEditorVisible = false
      this.search()
    },
    handleAccountEditorShow: function(type, initial = {}) {
      this.accountEditorVisible = true
      this.accountEditorModel = {
        form_type: type,
        ...initial
      }
    },
    handleDeleteAccount(record){
      Modal.confirm({
        content: () => `确认删除 ${record.account_name} 吗？操作不可恢复，但是可以重新添加`,
        onOk: () => {
          request.delete(`/api/bloggers/${record.user_id}/accounts/${record.id}`).then(() => {
            this.search()
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    handleExportAccounts(){
      let querys = []
      let q = this.searchQuery({
        is_export: 1
      })
      for (let k in q) {
        querys.push(`${k}=${encodeURIComponent(q[k])}`)
      }
      window.open(`/api/bloggers/0/accounts?` +  querys.join('&'))
    },
    searchQuery(query = {}){
      return {
        offset: (this.pagination.current - 1) * this.pagination.pageSize,
        limit: this.pagination.pageSize,
        platform: this.formSearch.platform,
        account_name: this.formSearch.account_name,
        user_id: this.formSearch.user_id,
        ...query
      }
    },
    async search (query = {}) {
      this.loading = true
      const rsp = await request.get('/api/bloggers/0/accounts', this.searchQuery(query))
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      return rsp.data
    },
    async handleTableChange (pagi) {
      if (!pagi) {
        pagi = this.pagination
      }
      this.pagination = {
        ...pagi
      }
      await this.search()
    },
    handleImportAccounts(){
      this.uploadInput.click()
    },
    async onChangeFile(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/bloggers/accounts/import`, files[0] ).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.search()
          this.uploadLoading = false
        })
        this.uploadInput.value = []

      }

    }
  },
  setup(props, context) {
    const store = useStore()
    return {
      userRole: store.state.user.role,
      UserRoleOperator,
      UserRoleDirectorManager,
      UserRoleDirector,
      UserRoleBlogger,
      UserRoleAuditor,
      uploadInput: null,
      columns: [
        {
          title: '账号昵称',
          dataIndex: 'account_name',
          key: 'account_name',
          width: 150,
        },
        {
          title: '所属平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 150,
        },
        {
          title: '主页地址',
          dataIndex: 'home_url',
          key: 'home_url',
          width: 200,
        },
         {
          title: '账号 ID',
          dataIndex: 'account_id',
          key: 'account_id',
          width: 100,
        },
        {
          title: '博主用户名',
          dataIndex: 'username',
          key: 'username',
          width: 150,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 150,
          slots: { customRender: 'action' },
        },

      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>