
import { defineComponent, reactive, toRaw, ref } from 'vue';
import type { UnwrapRef } from 'vue';
import request from '@/utils/request'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { message } from 'ant-design-vue';
import md5 from 'js-md5'
import { isMobile } from '@/utils/client'

interface FormState {
  username: string;
  password: string;
  // mobile?: string;
  vcode?: string;
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
   
    const showPhone = ref(false)
    const countDown = ref(0)
    const timer: any = ref(null)

    const formState: UnwrapRef<FormState> = reactive({
      username: '',
      password: '',
      // mobile: '',
      vcode: '',
    });
    if (isMobile()) {
        router.push({
          path: '/mobile/login'
        })
        return {
          formState
        }
    }
    const store = useStore()
    const getLoginPhoneCode = () => {
      startCountDown()
      request.post('/api/login', {
        username: formState.username,
        password: md5(formState.password),
      })
    }
    const startCountDown = () => {
      // 如果倒计时没有在进行中
      if (countDown.value === 0) {
          countDown.value = 60; // 设置初始倒计时时间
          // countDown.value = 10; // 设置初始倒计时时间

          // 启动定时器，每秒更新倒计时
          timer.value = setInterval(() => {
          if (countDown.value > 0) {
              countDown.value--;
          } else {
              // 倒计时结束，清除定时器
              clearInterval(timer.value);
              timer.value = null;
          }
          }, 1000);
      }
    }
    const onSubmit = async () => {
      const ret: any = await request.post('/api/login', {
        username: formState.username,
        password: md5(formState.password),
        // mobile: formState.mobile,
        vcode: formState.vcode,
      })
      console.log(123123, ret);
      if (ret.code==100101) {
        console.log(4444, ret);
        showPhone.value = true
        startCountDown()
      } else {

        if (ret.data && ret.data.user_id) {
          await request.get('/api/users/auth').then((res:any) => {
            message.success("登录成功")
            store.commit("setLogin", res.data)
            router.push({
              path: '/keywords/apply'
            })
            console.log(ret)
          }).catch((res) => {
            console.log(res);
          })
          
          
        }
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formState,
      showPhone,
      onSubmit,
      countDown,
      getLoginPhoneCode,
    };
  },
});
