<template>

  <a-card :title="pageTitle">
    <div>
      <a-tabs type="card" v-model:activeKey="keywordStatus">
        <a-tab-pane key="0" :tab="`待审核 (${statusCounts[0]})`"></a-tab-pane>
        <a-tab-pane key="1" :tab="`已通过 (${statusCounts[1]})`"></a-tab-pane>
        <a-tab-pane key="3" :tab="`已发布 (${statusCounts[3]})`"></a-tab-pane>
        <a-tab-pane key="2" :tab="`未通过 (${statusCounts[2]})`"></a-tab-pane>
        <a-tab-pane key="4" :tab="`已失效 (${statusCounts[4]})`"></a-tab-pane>
      </a-tabs>
      <!-- <a-radio-group v-model:value="keywordStatus" button-style="solid">
        <a-radio-button default value="-1">全部</a-radio-button>
        <a-radio-button value="0">待审核</a-radio-button>
        <a-radio-button value="1">已通过</a-radio-button>
        <a-radio-button value="3">已发布</a-radio-button>
        <a-radio-button value="2">未通过</a-radio-button>
      </a-radio-group> -->
    </div>
    <a-space>
     <a-form :model="formSearch" :label-col=" { span: 4 }" :wrapper-col="{ span: 14}" style="margin: 10px auto;" >
       <a-form-item label="关键词">
          <a-input placeholder="关键词" v-model:value="formSearch.text" />
        </a-form-item>
        <a-form-item v-if="[1,2,3].indexOf(user_role) > -1" label="博主" >
            <a-select
              v-model:value="formSearch._blogger_ids"
              :options="bloggerSuggestion"
              @search="searchBloggers"
              @change="onChangeBloggers"
              mode="multiple"
              placeholder="博主名称"
              style="width: 200px"
            >
            </a-select>
        </a-form-item>
        <a-form-item v-if="user_role != 1"  label="平台">
          <a-select
              v-model:value="formSearch.platform"
              :options="platformsSuggestion"
              placeholder="平台"
            >
            </a-select>
        </a-form-item>
       <!-- <a-form-item  label="商务">
           <a-select
          v-model:value="formSearch._director_ids"
          :options="directorSuggestion"
          @search="searchDirectors"
          @change="onChangeDirectors"
          mode="multiple"
          placeholder="商务名称"
        />
       </a-form-item>

        <a-form-item label="申请日期" >
          <a-range-picker
            v-model:value="formSearch.apply_time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item> -->
      
        
      <a-form-item>
        <a-button
          type="primary"
          @click="handleTableChange()"
        >
         搜索
        </a-button>
      </a-form-item>
     
    </a-form>
    </a-space>
   
        
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    :scroll="{ x: true }"
    >
      <template #role="{ record }">
        <a>{{ RoleMap[record.role] }}</a>
      </template>
       <template #status="{ record }">
        <span>
          <a-tag v-if="record.status == 1" color="green"  >{{ KeywordStatusMap[record.status]  + (record.audit_status > 0 ? ' (AUTO)' : '')}}</a-tag>
          <a-tag v-else-if="record.status == 3" color="red"  >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag v-else-if="record.status == 4" color="orange"  >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag  v-else-if="record.status == 2" color="orange" >{{ KeywordStatusMap[record.status]  + (record.audit_status > 0 ? ' (AUTO)' : '')}} : {{record.reason}}</a-tag>
          <a-tag v-else   >{{ KeywordStatusMap[record.status] + (record.audit_status > 0 ? ' (AUTO)' : '') }}</a-tag>
        </span>
      </template>
      <template #publish_url="{ record }" >
        <a
          v-if="record.published_count  > 0"
          @click="handleShowPublishedURL(record)"
        >
        查看({{record.published_count}})
        </a>
      </template>
      <template #stats_count="{ record }" >
        <a v-if="record.stats_count > 0"  @click="handleViewKeywordStatus(record)" >
          查看({{record.stats_count}})
        </a>
      </template>
       <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-DD-MM HH:mm:ss')}}</span>
      </template>
   
      <template #action="{ record }" >
        <span>
          <template v-if="[1,3].indexOf(record.status) > -1" >
            <a
              @click="handleShowPublishEditor(record)"
            >
            发布
            </a>
            
          </template>
          <!-- <a-space>
            <template v-if="pageType == 'manage'" >
              <a-button
                v-if="record.status == 0 "
                type="primary"
                size="small"
                @click="onAudit(record, 1)"
              >
                通过
              </a-button>
              <a-button
                v-if="record.status == 0 "
                danger
                size="small"
                @click="onAudit(record, 2)"
              >
                拒绝
              </a-button>
              <a-divider type="vertical" />
            </template>
            <template v-if="pageType == 'apply'" >
              <a
                v-if="[1,3].indexOf(record.status) > -1"
                @click="handleShowPublishEditor(record)"
              >
              发布链接
              </a>
              <a-divider type="vertical" />
             
            </template>
          </a-space> -->
          <!-- <a @click="onDeleteKeyword(record)">删除 {{record.username}}</a>
          
           -->
          <template  v-if="[0, 2, 1].indexOf(record.status) > -1 || user_role == 1 " >
            <a @click="handleShowKeywordEditor('edit', record)" >编辑 </a>
            <a style="color: red;" @click="onDeleteKeyword(record)"> 删除</a>
          </template>
         
          <template v-if="record.sum_stats > 0"  >
            <a @click="handleViewKeywordStatus(record)" >数据</a>
          </template>
        </span>
      </template>
    </a-table>
  </a-card>

  <input ref='uploadAuditKeywordInput' style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadAuditKeyword"/>
  <input ref='uploadStatKeywordInput' style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadStatKeyword"/>
  <input ref='uploadCreateKeywordInput' style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadCreateKeyword"/>
  <input ref='uploadPublishKeywordInput' style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadPublishKeyword"/>
  <PublishEditor v-model:visible="publishEditorVisible" @ok="onPublishEditorSuccess" @cancel="publishEditorVisible = false" :record="publishEditorInitial" />
  <KeywordEditor v-model:visible="keywordEditorVisible" @ok="onKeywordEditorSuccess" @cancel="keywordEditorVisible = false" :record="keywordEditorInitial"  />
  <ViewSourceStats v-model:visible="viewSourceStatsVisible" :record="viewSourceStatsInitial" />
  <ViewPublishedURL v-model:visible="viewPublishedURLVisible" :record="viewPublishedURLInitial" />
  <RejectEditor v-model:visible="rejectEditorVisible" :record="rejectEditorInitial"  @ok="onRejectSuccess" @cancel="rejectEditorVisible = false"  />
</template>
<script >
import { defineComponent, ref, reactive, watch} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import { Modal } from 'ant-design-vue';
import {UserRoleMap, KeywordStatusMap, PlatformSug} from '@/utils/macro'
import _ from 'lodash'
import KeywordEditor from '@/components/KeywordEditor'
import PublishEditor from '@/components/PublishEditor'
import ViewSourceStats from '@/components/ViewSourceStats'
import ViewPublishedURL from '@/components/ViewPublishedURL'
import RejectEditor from '@/components/RejectEditor'

import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'

let _searchBloggerDebounce = null 
let _searchBooksDebounce = null
let _searchDirectorDebounce = null

let pageTypeTitle = {
  "manage": "关键词列表",
  "apply": '关键词申请',
  'stats': '关键词数据'
}

export default defineComponent({
  components: {
    KeywordEditor,
    PublishEditor,
    ViewSourceStats,
    ViewPublishedURL,
    RejectEditor
  },
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      pageType: "manage",
      pageTitle: "关键词列表",

      statusCounts: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
      },

      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        text: '',
        _blogger_ids: [],
        _book_ids: [],
        _director_ids: [],
        blogger_ids: '',
        book_ids: '',
        director_id: '',
        platform: '',
        apply_time_range: [],
        status: "0" ,
      },

      bloggerSuggestion: [],
      booksSuggestion: [],
      directorSuggestion: [],
      platformsSuggestion: [].concat(PlatformSug),
      keywordStatus: "0",

      keywordEditorVisible: false,
      keywordEditorInitial: {},
      publishEditorVisible: false,
      publishEditorInitial: {},

      viewSourceStatsInitial: [],
      viewSourceStatsVisible: false,

      viewPublishedURLInitial: [],
      viewPublishedURLVisible: false,

      rejectEditorInitial: {},
      rejectEditorVisible: false,

    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{
    keywordStatus: function (v, ov){
      this.handleTableChange()
    },
    $route: {
       handler(route,oldval){
        if (route.query.type) {
          this.pageType = route.query.type
        }
        this.pageTitle = pageTypeTitle[this.pageType]

      },
      // 深度观察监听
      deep: true
    }
  },
  mounted(){
    this.handleTableChange()
  },
  methods: {
    getStatusCount(){
       request.get(`/api/keywords/status/summary`, {}).then((res) =>this.statusCounts = {
         ...res.data
       })
    },
    searchParam(param) {
      let timeRange = []
      for (let t of this.formSearch.apply_time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      let q = {       
        ...this.formSearch,
        apply_time_range: timeRange.join(','),
        status: + this.keywordStatus,
        ...param
      }
      return q
    },
    genQuery (param){
      let q = this.searchParam(param)
      let querys = []
      for (let k in q) {
        querys.push(`${k}=${encodeURIComponent(q[k])}`)
      }
      return querys.join('&')
    },
    handleUploadCreateKeyword(e){
      if (e.key == "1") {
        window.open("/keywords_create_tmp.xlsx")
        return
      }
      this.uploadCreateKeywordInput.click()
    },
    async onChangeUploadCreateKeyword(e){
      let files = e.target.files;
      if(files){
        request.upload(`/api/keywords/csv/create`, files[0]).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
        })
      }

    },
    handleUploadAuditKeyword(e){
      if (e.key == "1") {
        
        window.open(`/api/keywords/csv/export?` + this.genQuery({
          status: 0,
        }))
        return
      }
      this.uploadAuditKeywordInput.click()
    },
    async onChangeUploadAuditKeyword(e){
      let files = e.target.files;
      if(files){
        request.upload(`/api/keywords/csv/audit`, files[0]).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
        })
        this.uploadAuditKeywordInput.value = null
      }
    },
    handleUploadPublishKeyword(e){
      if (e.key == "1") {
        window.open(`/api/keywords/csv/export?` + this.genQuery({
          status: 1,
        }))
        return
      }
      this.uploadPublishKeywordInput.click()
    },
    async onChangeUploadPublishKeyword(e){
      let files = e.target.files;
      if(files){
        request.upload(`/api/keywords/csv/publish`, files[0] ).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
        })
        this.uploadPublishKeywordInput.value = null
      }

    },
    handleUploadStatKeyword(e){
      if (e.key == "1") {
        window.open(`/api/keywords/csv/export?` + this.genQuery({
          status: 3,
        }))
        return
      }
      this.uploadStatKeywordInput.click()
    },
    async onChangeUploadStatKeyword(e){
      let files = e.target.files;
      if(files){
        request.upload(`/api/keywords/csv/stats`, files[0] ).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
        })
        this.uploadStatKeywordInput.value = null
      }
    },
    handleExportStatsData(){
      window.open(`/api/keywords/csv/export?` + this.genQuery({
          status: 3,
          is_stats: true
        }))
    },
    async handleViewKeywordStatus(record){

      let res = await request.get(`/api/keywords/${record.id}/stats`, {})
      this.viewSourceStatsInitial = res.data.list
      this.viewSourceStatsVisible = true
    },
    async handleShowPublishedURL(record) {
      let res = await request.get(`/api/keywords/${record.id}/published`, {})
      this.viewPublishedURLInitial = res.data
      this.viewPublishedURLVisible = true
    },

    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_ids = [this.formSearch._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_id = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },

    onChangeBloggers(item, options){
     let ids = []
     for(let v of options) {
       ids.push(v.id)
     }
     this.formSearch.blogger_ids = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
        request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
     searchBooks(bookName) {
       clearTimeout(_searchBooksDebounce)
       _searchBooksDebounce = setTimeout(() => {
          request.get(`/api/books`, {
            book_name: bookName
          }).then((rsp) => {
            this.booksSuggestion = []
            for (let v of rsp.data.list) {
              this.booksSuggestion.push({
                value: v.book_name + '-' + v.author,
                id: v.id
              })
            }
          })
        }, 600)
    },
    onChangeBooks(item, options){
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.book_ids = ids.join(',')
    },
    onAudit(record, toStatus){
      let tip = toStatus == 1 ? `通过` : `拒绝`
      if (toStatus == 1) {
        Modal.confirm({
          content: () => `确认${tip}「${record.text}」吗？`,
          onOk: () => {
            request.put(`/api/keywords/audit/${record.id}`, {
              status: toStatus,
            }).then(() => {
              this.handleTableChange()
            })
          },
          onCancel() {
            Modal.destroyAll();
          },
        });
      } else {
        this.rejectEditorVisible = true
        this.rejectEditorInitial = {
          ...record,
        }
      }
     
    },
    onRejectSuccess(){
      this.handleTableChange()
    },
    onKeywordEditorSuccess(){
      this.keywordEditorVisible = false
      this.handleTableChange()
    },
    handleShowKeywordEditor(type, initial = {}) {
      this.keywordEditorVisible = true
      this.keywordEditorInitial = reactive({
        form_type:  type,
        ...initial,
      })
    },
    onPublishEditorSuccess(){
      this.publishEditorVisible = false
      this.handleTableChange()
    },
    handleShowPublishEditor(initial = {}) {
      this.publishEditorVisible = true
      
      this.publishEditorInitial = reactive({
        ...initial,
      })
    },
    onDeleteKeyword(record){
      Modal.confirm({
        content: () => `确认删除「${record.text}」吗？操作不可恢复，但是可以重新添加`,
        onOk: () => {
          request.delete(`/api/keywords/${record.id}`).then(() => {
            this.handleTableChange()
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.getStatusCount()
      this.loading = true

      let timeRange = []
      for (let t of this.formSearch.apply_time_range) {
         timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      let q = this.searchParam({
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
      })
      const rsp = await request.get('/api/keywords', q)
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };


    return {
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        //  {
        //   title: '平台',
        //   dataIndex: 'platform',
        //   key: 'platform',
        // },
        {
          title: '关键词',
          dataIndex: 'text',
          key: 'text',
        },
        //  {
        //   title: '申请时间',
        //   dataIndex: 'created_at',
        //   key: 'created_at',
        //   slots: { customRender: 'created_at' },
        // },
        // {
        //   title: '博主名称',
        //   dataIndex: 'blogger_name',
        //   key: 'blogger_name',
        // },
        {
          title: '书名',
          dataIndex: 'book_name',
          key: 'book_name',
        },
        //  {
        //   title: '干预方式',
        //   dataIndex: 'intervene',
        //   key: 'intervene',
        // },
          {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        //  {
        //   title: '发布地址',
        //   dataIndex: 'publish_url',
        //   key: 'publish_url',
        //   slots: { customRender: 'publish_url' },
        // },
        //  {
        //   title: '数据',
        //   dataIndex: 'sum_stats',
        //   key: 'sum_stats',
        //   slots: { customRender: 'sum_stats' },
        // },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.ant-table td { white-space: nowrap; }

</style>