<template>

  <a-card :title="pageTitle">
    <div>
      <a-tabs type="card" v-model:activeKey="keywordStatus">
        <a-tab-pane key="-1" :tab="'全部'"></a-tab-pane>
        <a-tab-pane key="0" :tab="`待审核 (${statusCounts[0]})`"></a-tab-pane>
        <a-tab-pane key="1" :tab="`已通过 (${statusCounts[1]})`"></a-tab-pane>
        <a-tab-pane key="3" :tab="`已发布 (${statusCounts[3]})`"></a-tab-pane>
        <a-tab-pane key="2" :tab="`未通过 (${statusCounts[2]})`"></a-tab-pane>
        <a-tab-pane key="4" :tab="`已失效 (${statusCounts[4]})`"></a-tab-pane>
      </a-tabs>
      <!-- <a-radio-group v-model:value="keywordStatus" button-style="solid">
        <a-radio-button default value="-1">全部</a-radio-button>
        <a-radio-button value="0">待审核</a-radio-button>
        <a-radio-button value="1">已通过</a-radio-button>
        <a-radio-button value="3">已发布</a-radio-button>
        <a-radio-button value="2">未通过</a-radio-button>
      </a-radio-group> -->
    </div>
    <a-space>
    <!-- :label-col=" { span: 4 }" :wrapper-col="{ span: 14}" layout="inline"  -->
     <a-form :model="formSearch" @submit="handleTableChange" @keyup.enter="handleTableChange" style="margin: 10px auto;" >
      <a-row :gutter="24">
        <a-col :span="8">
        <a-form-item label="关键词">
          <a-input placeholder="关键词" v-model:value="formSearch.text" />
        </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item v-if="isOperatorOrAuditor || isDirectorOrDirectorManager" label="博主" >
             <a-select
                v-model:value="formSearch._blogger_ids"
                :options="bloggerSuggestion"
                @search="searchBloggers"
                @change="onChangeBloggers"
                mode="multiple"
                placeholder="博主名称"
                style="width: 200px"
              >
              </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item   label="书名">
            <a-select
              v-model:value="formSearch._book_ids"
              :options="booksSuggestion"
              @search="searchBooks"
              @change="onChangeBooks"
              mode="multiple"
              placeholder="书名"
              style="width: 200px"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" v-if="!isOperatorOrAuditor || login_user_id == '1' || login_user_id == '33363'">
          <a-form-item label="平台">
            <a-select
                v-model:value="formSearch.platform"
                :options="platformsSuggestion"
                placeholder="平台"
              >
              </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
       <a-form-item  label="商务" v-if="user_role != UserRoleDirector">
           <a-select
          v-model:value="formSearch._director_ids"
          :options="directorSuggestion"
          @search="searchDirectors"
          @change="onChangeDirectors"
          mode="multiple"
          placeholder="商务名称"
        />
       </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="申请日期" >
          <a-range-picker
            v-model:value="formSearch.apply_time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="发布排序" >
          <a-radio-group v-model:value="formSearch.order_published"  >
            <a-radio-button value="">无</a-radio-button>
            <a-radio-button value="published_count asc">升序</a-radio-button>
            <a-radio-button value="published_count desc">降序</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :span="8">
         <a-form-item label="拉新排序" >
          <a-radio-group v-model:value="formSearch.order_stats"  >
            <a-radio-button value="">无</a-radio-button>
            <a-radio-button value="stats_count asc">升序</a-radio-button>
            <a-radio-button value="stats_count desc">降序</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :span="8">
      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
        >
         搜索
        </a-button>
      </a-form-item>
    </a-col>
     </a-row>
    </a-form>
    </a-space>
    <div>
    <a-space  style="margin: 10px 0;flex-wrap: wrap;">
       <!-- 关键词申请 Tab -->
      <template v-if="pageType == 'apply'">
        <a-button
          type="primary"
          @click="handleShowKeywordEditor('new')"
        >
        新增关键词
        </a-button>
        <a-dropdown-button type="primary" >
          批量申请
          <template #overlay>
            <a-menu @click="handleUploadCreateKeyword">
              <a-menu-item key="1">
                下载关键词模板
              </a-menu-item>
              <a-menu-item key="2">
                上传申请关键词
              </a-menu-item>
            </a-menu>
          </template>

        </a-dropdown-button>
      </template>
       <a-button
          v-if="isOperatorOrAuditor || isDirectorOrDirectorManager"
          type="primary"
          @click="handelExportKeywords"
        >
        导出关键词
        </a-button>
        <!-- 关键词列表 Tab -->
      <template v-if="pageType == 'manage'">
        <a-space v-if="isOperatorOrAuditor" >
          <a-dropdown-button type="primary" >
            批量审核
            <template #overlay>
              <a-menu @click="handleUploadAuditKeyword">
                <a-menu-item key="1">
                  导出待审核词
                </a-menu-item>
                <a-menu-item key="2">
                  上传审核结果
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
          <a-dropdown-button type="primary">
            导入统计数据
            <template #overlay>
              <a-menu @click="handleUploadStatKeyword">
                <a-menu-item key="3">
                导出昨日发布列表
                </a-menu-item>
                <a-menu-item key="4">
                导出指定时间段发布列表
                </a-menu-item>
                <a-menu-item key="1">
                下载投放数据模板
                </a-menu-item>
                <a-menu-item key="2">
                上传投放数据
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>

         
          <a-dropdown-button type="primary" >
            批量修改状态
            <template #overlay>
              <a-menu @click="handleUploadChangeStatusKeyword">
                <a-menu-item key="1">
                  下载模板
                </a-menu-item>
                <a-menu-item key="2">
                  上传数据
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
          <a-dropdown-button type="primary" >
            批量修改关键词
            <template #overlay>
              <a-menu @click="handleUploadUpdateKeyword">
                <a-menu-item key="1">
                  下载模板
                </a-menu-item>
                <a-menu-item key="2">
                  上传数据
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
          <a-dropdown-button type="primary" >
            批量修改商务
            <template #overlay>
              <a-menu @click="handleUploadChangeDirector">
                <a-menu-item key="1">
                  下载模板
                </a-menu-item>
                <a-menu-item key="2">
                  上传数据
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </a-space>
        <a-dropdown-button type="primary" >
            批量发布
            <template #overlay>
              <a-menu @click="handleUploadPublishKeyword">
                <a-menu-item key="1">
                  导出待发布词
                </a-menu-item>
                <a-menu-item key="2">
                  上传发布结果
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
          <a-dropdown-button type="primary" v-if="(isOperatorOrAuditor || daifa_perms)">
            批量下发
            <template #overlay>
              <a-menu @click="handleUploadReourseKeyword">
                <a-menu-item key="1">
                  下载模板
                </a-menu-item>
                <a-menu-item key="2">
                  上传数据
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
          <a-dropdown-button type="primary" style="margin-left:10px" v-if="[UserRoleOperator].indexOf(user_role) > -1 || daifa_perms">
            批量上传资源包
            <template #overlay>
              <a-menu @click="handleCreateUploadPkgs">
                <a-menu-item key="1">上传文件</a-menu-item>
                <a-menu-item key="2">下载模板</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>

          
        
      </template>
      <a-spin v-if="uploadLoading" />
      <a-button v-if="pageType == 'stats' " type="primary" @click="handleExportStatsData" >导出数据</a-button>
    </a-space>
    </div>
   
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    :scroll="{ x: true }"
    >
      <template #images="{ record }">
        <a-image-preview-group>
            <a-image
            :width="30"
            :src="item" v-for="item in record.images" :key="item"
          />
        </a-image-preview-group>
      </template>
      <template #sign_proof="{ record }">
        <a-image-preview-group>
            <a-image
            :width="30"
            :src="record.sign_proof" :key="item"
          />
        </a-image-preview-group>
      </template>
      <!-- sign_proof -->
      <template #role="{ record }">
        <a>{{ RoleMap[record.role] }}</a>
      </template>
       <template #status="{ record }">
        <span>
          <a-tag v-if="record.status == 1" color="green"  >{{ KeywordStatusMap[record.status] + (record.audit_status > 0 ? ' (AUTO)' : '') }}</a-tag>
          <a-tag v-else-if="record.status == 3" color="red"  >{{ KeywordStatusMap[record.status] }}</a-tag>
           <a-tag v-else-if="record.status == 4" color="orange"  >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag  v-else-if="record.status == 2" color="grey" >{{ KeywordStatusMap[record.status] + (record.audit_status > 0 ? ' (AUTO)' : '') }}: {{record.reason}}</a-tag>
          <a-tag v-else   >{{ KeywordStatusMap[record.status] + (record.audit_status > 0 ? ' (AUTO)' : '') }}</a-tag>
        </span>
      </template>
      <template #publish_url="{ record }" >
        <a
          v-if="record.status > 0 && record.status != 2"
          @click="handleShowPublishedURL(record)"
        >
        查看({{record.published_count}})
        </a>
      </template>
      <template #stats_count="{ record }" >
        <a v-if="record.status > 0 && record.status != 2"  @click="handleViewKeywordStatus(record)" >
          查看({{record.stats_count}})
        </a>
      </template>
       <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
       <template #package_count="{ record }" >
        <a-button v-if="[UserRoleOperator].indexOf(user_role) > -1 || daifa_perms" type="link" @click="previewResourcePkgsList(record)">{{record.package_count}}</a-button>
        <span v-else>{{record.package_count}}</span>
      </template>
      
      <template #action="{ record }" >
        <span>
         
          <a-space>
            <template v-if="pageType == 'manage' && isOperatorOrAuditor && record.audit_status == 0" >
              <a-button
                v-if="record.status == 0 "
                type="primary"
                size="small"
                @click="onAudit(record, 1)"
              >
                通过
              </a-button>
              <a-button
                v-if="record.status == 0 "
                danger
                size="small"
                @click="onAudit(record, 2)"
              >
                拒绝
              </a-button>
            </template>
            <template  v-if="record.status == 1 || record.status==3" >
              <a  @click="handleShowPublishEditor(record)"> 发布链接 </a>
            </template>
            <template  v-if="[0, 1, 2].indexOf(record.status) > -1 " >
              <a @click="handleShowKeywordEditor('edit', record)" >编辑</a>
              <a-divider type="vertical" />
              <a style="color: red;" @click="onDeleteKeyword(record)">删除</a>
            </template>
            <template  v-if="isOperatorOrAuditor" >
              <a style="color: orange;" @click="onInvalidKeyword(record)">失效</a>
            </template>
            
            <template v-if="record.sum_stats > 0"  >
              <a @click="handleViewKeywordStatus(record)" >查看数据</a>
            </template>
            <a @click="handleViewKeywordResource(record)" v-if="(record.status == 1 || record.status==3) && isOperatorOrAuditor">资源管理</a>
            <a @click="handleViewResourcePkgs(record)" v-if="(record.status == 1 || record.status==3) &&(isOperatorOrAuditor || daifa_perms)">创建悬赏任务资源包</a>
          </a-space>
        </span>
      </template>
    </a-table>
  </a-card>

  <input ref='uploadCreateKeywordInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadCreateKeyword"/>
  <input ref='uploadAuditKeywordInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadAuditKeyword"/>
  <input ref='uploadPublishKeywordInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadPublishKeyword"/>
  <input ref='uploadStatKeywordInput'  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadStatKeyword"/>
  <input ref='uploadChangeStatusInput'  accept=".csv" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadChangeStatusKeyword"/>
  <input ref='uploadChangeDirectorInput'  accept=".csv" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadChangeDirector"/>
  <input ref='uploadResourceInput'  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadResource"/>
  <input ref='uploadPkgsInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadPkgs"/>
  <input ref='uploadUpdateKeyword' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadUpdateKewyord"/>

  <PublishEditor v-model:visible="publishEditorVisible" @ok="onPublishEditorSuccess" @cancel="publishEditorVisible = false" :record="publishEditorInitial" />
  <KeywordEditor v-model:visible="keywordEditorVisible" @ok="onKeywordEditorSuccess" @cancel="keywordEditorVisible = false" :record="keywordEditorInitial"  />
  <ViewSourceStats v-model:visible="viewSourceStatsVisible" :record="viewSourceStatsInitial" />
  <ViewPublishedURL v-model:visible="viewPublishedURLVisible" :record="viewPublishedURLInitial" />
  <RejectEditor v-model:visible="rejectEditorVisible" :record="rejectEditorInitial"  @ok="onRejectSuccess" @cancel="rejectEditorVisible = false"  />
  <ResourceManage v-model:visible="resourceManageVisible" :record="viewResourceInitial"  @ok="onResourceSuccess" @cancel="resourceManageVisible = false"  />

  <ResourcePkgsModel v-if="resourcePkgsVisible" v-model:visible="resourcePkgsVisible" :showPlatform="false" :showKeyword="false" :record="viewResourcePkgsInitial"  @ok="onResourcePkgsSuccess" @cancel="resourcePkgsVisible = false" />
</template>
<script >
import { defineComponent, ref, reactive, watch, h} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import { Modal } from 'ant-design-vue';
import {UserRoleMap, KeywordStatusMap, PlatformSug} from '@/utils/macro'
import _ from 'lodash'
import KeywordEditor from '@/components/KeywordEditor'
import PublishEditor from '@/components/PublishEditor'
import ViewSourceStats from '@/components/ViewSourceStats'
import ViewPublishedURL from '@/components/ViewPublishedURL'
import RejectEditor from '@/components/RejectEditor'
import ResourceManage from '@/components/ResourceManage'
import ResourcePkgsModel from './resources/components/ResourcePkgsModel'

import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'
import {
  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
  CREATE_RESOURCE_PACKAGE,
} from '@/utils/macro'

let _searchBloggerDebounce = null 
let _searchBooksDebounce = null
let _searchDirectorDebounce = null

let pageTypeTitle = {
  "manage": "关键词列表",
  "apply": '关键词申请',
}

export default defineComponent({
  components: {
    KeywordEditor,
    PublishEditor,
    ViewSourceStats,
    ViewPublishedURL,
    ResourceManage,
    RejectEditor,
    ResourcePkgsModel,
  },
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      pageType: "manage",
      pageTitle: "关键词列表",

      statusCounts: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
      },

      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        query: '',
        text: '',
        _blogger_ids: [],
        _book_ids: [],
        _director_ids: [],
        blogger_ids: '',
        book_ids: '',
        director_id: '',
        platform: '',
        apply_time_range: [],
        status: "-1" ,

        order_published: '',
        order_stats: '',
      },

      bloggerSuggestion: [],
      booksSuggestion: [],
      directorSuggestion: [],
      platformsSuggestion: [].concat(PlatformSug),
      keywordStatus: "-1",

      keywordEditorVisible: false,
      keywordEditorInitial: {},
      publishEditorVisible: false,
      publishEditorInitial: {},

      viewSourceStatsInitial: [],
      viewSourceStatsVisible: false,

      viewPublishedURLInitial: [],
      viewPublishedURLVisible: false,

      rejectEditorInitial: {},
      rejectEditorVisible: false,

      uploadLoading: false,
      resourceManageVisible: false,
      viewResourceInitial: [],

      resourcePkgsVisible: false,
      viewResourcePkgsInitial: {},

    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    login_user_id: () => {
      const store = useStore()
      return store.state.user.user_id
    },
    isOperatorOrAuditor: function() {
      return [UserRoleOperator, UserRoleAuditor].indexOf(this.user_role) > -1
    },
    isDirectorOrDirectorManager: function() {
      return [UserRoleDirector, UserRoleDirectorManager].indexOf(this.user_role) > -1
    },
    daifa_perms: () => {
      const store = useStore()
      return store.state.user.perms && store.state.user.perms.includes(CREATE_RESOURCE_PACKAGE)
    },
    // showResourceBtn() {
    //   return record.status == 1 || record.status==3
    // }
  },
  watch:{
    keywordStatus: function (v, ov){
      this.handleTableChange()
    },
    $route: {
       handler(route,oldval){
         this.onRoute(route)

      },
      // 深度观察监听
      deep: true
    }
  },
  mounted(){
    const route = useRoute()
    this.onRoute(route)
    if (this.user_role == 1) {
      this.searchBloggers()
    }
   
    this.searchBooks()
    this.searchDirectors()
    this.handleTableChange()
  },
  methods: {
    previewResourcePkgsList(item) {
      console.log(item);
      this.$router.push({name:'ResourcesPkgs', query:{keyword_id:item.id,keyword:item.text}})
    },
    showErrorModal(fails) {
      if(fails && fails.length > 0 ){
        let vnode = []

        for (let o of fails) {
          vnode.push(<div>{o}</div>)
        }
        Modal.error({
          title: "发生错误",
          content: () => <div >{vnode}</div>,
        });
      }
    },
    onRoute(route){
      if (route.path == '/keywords/apply') {
           this.pageType = 'apply'
         } else {
           this.pageType = 'manage'
         }
        this.pageTitle = pageTypeTitle[this.pageType]

    },
    getStatusCount(){
       request.get(`/api/keywords/status/summary`, {}).then((res) =>this.statusCounts = {
         ...this.statusCounts,
         ...res.data
       })
    },
    searchParam(param) {
      let timeRange = []
      for (let t of this.formSearch.apply_time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      let q = {
        ...this.formSearch,
        apply_time_range: timeRange.join(','),
        status: + this.keywordStatus,
        ...param
      }

      let orders = []
      if (this.formSearch.order_published != "" ) {
        orders.push(this.formSearch.order_published)
      }
      if (this.formSearch.order_stats != "" ) {
        orders.push(this.formSearch.order_stats)
      }
      q.orderby = orders.join(',')
      return q
    },
    genQuery (param){
      let q = this.searchParam(param)
      let querys = []
      for (let k in q) {
        querys.push(`${k}=${encodeURIComponent(q[k])}`)
      }
      return querys.join('&')
    },
    handleUploadCreateKeyword(e){
      if (e.key == "1") {
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/FgSms5Rqehk74dtB3WYcqm98nQh")
        return
      }
      this.uploadCreateKeywordInput.click()
    },
    async onChangeUploadCreateKeyword(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/create`, files[0]).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadCreateKeywordInput.value = null
      }

    },
    handelExportKeywords(e){
      window.open(`/api/keywords?` + this.genQuery({
        export: 1
      }))
    },
    handleUploadAuditKeyword(e){
      if (e.key == "1") {
        
        window.open(`/api/keywords/csv/export?` + this.genQuery({
          status: 0,
        }))
        return
      }
      this.uploadAuditKeywordInput.click()
    },
    async onChangeUploadAuditKeyword(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/audit`, files[0]).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadAuditKeywordInput.value = null
      }
    },
    handleUploadChangeStatusKeyword(e){
       if (e.key == "1") {
        window.open("/keyword_change_status_tmp.csv")
        return
      }
      this.uploadChangeStatusInput.click()
    },
    handleUploadReourseKeyword(e){
       if (e.key == "1") {
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/Uptcss5bAhvvdVtKE0RcvDlCneR")
        return
      }
      this.uploadResourceInput.click()
    },
    onChangeUploadChangeStatusKeyword(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/update_status`, files[0] ).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadChangeStatusInput.value = null
      }
    },
    handleUploadUpdateKeyword(e){
      if (e.key == "1") {
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/F3ASswd4HhB1a5tlyYhcWWvAncd?from=from_copylink")
        return
      }
      this.uploadUpdateKeyword.click()
    },
    onChangeUploadUpdateKewyord(e) {
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/update_keyword`, files[0] ).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadChangeStatusInput.value = null
      }
    },
    handleUploadChangeDirector(e){
       if (e.key == "1") {
        window.open("/keyword_change_director_tmp.csv")
        return
      }
      this.uploadChangeDirectorInput.click()
    },
    onChangeUploadChangeDirector(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/change_director`, files[0] ).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadChangeDirectorInput.value = null
      }
    },
    onChangeUploadResource(e){
      let files = e.target.files;
      console.log(files);
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keyword_resources/import`, files[0] ).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        }).catch(err=> {
          this.uploadLoading = false
        })
        this.uploadResourceInput.value = null
      }
    },
    handleUploadPublishKeyword(e){
      if (e.key == "1") {
        window.open(`/api/keywords/csv/export?` + this.genQuery({
          status: 1,
        }))
        return
      }
      this.uploadPublishKeywordInput.click()
    },
    async onChangeUploadPublishKeyword(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/publish`, files[0] ).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadPublishKeywordInput.value = null
      }

    },
    handleUploadStatKeyword(e){
      if (e.key == "1") {
        window.open("/published_stats_v3.csv")
        return
      } else if (e.key == "3") {
        window.open(`/api/keywords/csv/export-stats`)
        return
      } else if (e.key == "4") {
        let timeRange = []
        for (let t of this.formSearch.apply_time_range) {
          timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
        }
        window.open(`/api/keywords/csv/export-stats?time_range=${timeRange.join(',')}`)
        return
      }
      this.uploadStatKeywordInput.click()
    },
    async onChangeUploadStatKeyword(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/stats`, files[0] ).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadStatKeywordInput.value = null
      }
    },
    handleExportStatsData(){
      window.open(`/api/keywords/csv/export?` + this.genQuery({
          status: 3,
          is_stats: true
        }))
    },
    async handleViewKeywordStatus(record){

      let res = await request.get(`/api/keywords/${record.id}/stats`, {})
      this.viewSourceStatsInitial = res.data.list
      this.viewSourceStatsVisible = true
    },
    async handleViewKeywordResource(record){
      this.viewResourceInitial = record
      this.resourceManageVisible = true
    },
    async handleViewResourcePkgs(record){
      console.log('record', record);
      this.viewResourcePkgsInitial = {...record, form_type: 'new'}
      this.resourcePkgsVisible = true
      
    },
    onResourcePkgsSuccess(val) {
      if (typeof val == 'boolean') {
        this.resourcePkgsVisible = val
        this.handleTableChange(this.pagination)
      }
    },
    async handleShowPublishedURL(record) {
      // let res = await request.get(`/api/keywords/${record.id}/published`, {})
      this.viewPublishedURLInitial = record
      this.viewPublishedURLVisible = true
    },

    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_ids = [this.formSearch._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_id = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },

    onChangeBloggers(item, options){
     let ids = []
     for(let v of options) {
       ids.push(v.id)
     }
     this.formSearch.blogger_ids = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
     searchBooks(bookName) {
       clearTimeout(_searchBooksDebounce)
       _searchBooksDebounce = setTimeout(() => {
          request.get(`/api/books`, {
            book_name: bookName
          }).then((rsp) => {
            this.booksSuggestion = []
            for (let v of rsp.data.list) {
              this.booksSuggestion.push({
                value: v.book_name + '-' + v.author,
                id: v.id
              })
            }
          })
        }, 600)
    },
    onChangeBooks(item, options){
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.book_ids = ids.join(',')
    },
    onAudit(record, toStatus){
      let tip = toStatus == 1 ? `通过` : `拒绝`
      if (toStatus == 1) {
        Modal.confirm({
          content: () => `确认${tip}「${record.text}」吗？`,
          onOk: () => {
            request.put(`/api/keywords/audit/${record.id}`, {
              status: toStatus,
            }).then(() => {
              this.handleTableChange(this.pagination)
            })
          },
          onCancel() {
            Modal.destroyAll();
          },
        });
      } else {
        this.rejectEditorVisible = true
        this.rejectEditorInitial = {
          ...record,
        }
      }
     
    },
    onRejectSuccess(){
      this.handleTableChange(this.pagination)
    },
    onResourceSuccess() {
      this.resourceManageVisible = false
      this.handleTableChange(this.pagination)
    },
    onKeywordEditorSuccess(){
      this.keywordEditorVisible = false
      this.handleTableChange(this.pagination)
    },
    handleShowKeywordEditor(type, initial = {}) {
      this.keywordEditorVisible = true
      this.keywordEditorInitial = reactive({
        form_type:  type,
        ...initial,
      })
    },
    onPublishEditorSuccess(){
      this.publishEditorVisible = false
      this.handleTableChange()
    },
    handleShowPublishEditor(initial = {}) {
      this.publishEditorVisible = true
      
      this.publishEditorInitial = reactive({
        ...initial,
      })
    },
    onDeleteKeyword(record){
      Modal.confirm({
        content: () => `确认删除「${record.text}」吗？操作不可恢复，但是可以重新添加`,
        onOk: () => {
          request.delete(`/api/keywords/${record.id}`).then(() => {
            this.handleTableChange(this.pagination)
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    onInvalidKeyword(record){
      Modal.confirm({
        content: () => `确认失效「${record.text}」吗？操作不可恢复`,
        onOk: () => {
          request.put(`/api/keywords/invalid/${record.id}`).then(() => {
            this.handleTableChange(this.pagination)
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.getStatusCount()
      this.loading = true

      let timeRange = []
      for (let t of this.formSearch.apply_time_range) {
         timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      let q = this.searchParam({
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
      })
      const rsp = await request.get('/api/keywords', q)
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    handleCreateUploadPkgs(e) {
      if (e.key == "1") { // 上传文件
        this.uploadPkgsInput.click()
      } else if (e.key=='2') { // 下载模板
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/NjPqswyvohyWuYtoF0ScAXlAnRe")
      }
    },
    onChangeUploadPkgs(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/resource_pkgs/import`, files[0]).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadStatusInput.value = null
      }
    },
  },
  setup(props, context) {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };

    

    return {
      UserRoleOperator,
      UserRoleDirectorManager,
      UserRoleDirector,
      UserRoleBlogger,
      UserRoleAuditor,
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      uploadChangeStatusInput: null,
      uploadChangeDirectorInput: null,
      uploadResourceInput: null,
      uploadPkgsInput: null,
      uploadUpdateKeyword: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
         {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 80,
          fixed: 'left' 
        },
        {
          title: '关键词',
          dataIndex: 'text',
          key: 'text',
          fixed: 'left',
          width: 100,
        },
        {
          title: 'UCID',
          dataIndex: 'sign_id',
          key: 'sign_id',
          width: 100,
        },
        {
          title: '截图',
          dataIndex: 'sign_proof',
          slots: { customRender: 'sign_proof' },
          key: 'sign_proof',
          width: 100,
        },
        {
          title: '推广类型',
          dataIndex: 'promotion_type',
          key: 'promotion_type',
          width: 100,
        },
        {
          title: '推广截图',
          dataIndex: 'images',
          key: 'images',
          slots: { customRender: 'images' },
          width: 150,
        },
        
        {
          title: '博主名称',
          dataIndex: 'blogger_name',
          key: 'blogger_name',
          width: 100,
        },
        {
          title: '图书名称',
          dataIndex: 'book_name',
          key: 'book_name',
          width: 100,
        },
         {
          title: '商务',
          dataIndex: 'director_name',
          key: 'director_name',
          width: 100,
        },
        {
          title: '版权',
          dataIndex: 'copyright',
          key: 'copyright',
          width: 100,
        },
          {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 80,
        },
         {
          title: '发布',
          dataIndex: 'publish_url',
          key: 'publish_url',
          slots: { customRender: 'publish_url' },
          width: 80,
        },
         {
          title: '数据',
          dataIndex: 'stats_count',
          key: 'stats_count',
          slots: { customRender: 'stats_count' },
          width: 80,
        },
         {
          title: '申请时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 120,
        },
         {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: 120,
        },
         {
          title: '关键词 ID',
          dataIndex: 'id',
          key: 'id',
        },
         {
          title: '资源包数量',
          dataIndex: 'package_count',
          key: 'package_count',
          slots: { customRender: 'package_count' },
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 430,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.ant-table td { white-space: nowrap; }
:deep(.ant-image) {
  margin-right:8px;
}
</style>