<template>
  <a-modal v-model:visible="visible" title="无效原因"  @ok="handleOk" @cancel="handleCancel">
    <a-form :model="form" style="margin: 10px auto;" :rules="rules" ref="formRef" >
      <a-form-item label="" name="reason">
        <a-textarea
          v-model:value="form.reason"
          placeholder="请输入无效原因"
          :auto-size="{ minRows: 2, maxRows: 5 }" />
      </a-form-item>
    </a-form>
      
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';

export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      form: {reason: ''},
      rules: {reason: [{required: true,message:'请输入无效原因',trigger:'blur'}]}
    }
  },
  methods: {
    moment,
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.$emit("ok", {
          id: this.record.id,
          status: 2,
          reason: this.form.reason
        })
      })
    },
    handleCancel() {
      this.$emit("cancel", false)
    },
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    return { 
      dataSource,
      visible,
    };
  },
});
</script>