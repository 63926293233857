<template>
  <div>    
    <a-row :span="24">
      <a-col :span="12">
        <a-upload 
          v-model:file-list="fileList"
          ref="UploadRef"
          :customRequest="handleUpload"
          :multiple="true"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
          <a-button><upload-outlined></upload-outlined>选择文本文件</a-button>
        </a-upload>
      </a-col>
      <a-col>
        <a-upload 
          v-model:file-list="fileListDirectory"
          ref="UploadFolderRef"
          style="margin-top:10px;"
          :customRequest="handleUpload"
          :multiple="true" directory>
          <a-button><upload-outlined></upload-outlined>选择文本文件夹</a-button>
        </a-upload>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap } from '@/utils/macro'
import { Modal, message, Upload } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import OSS from 'ali-oss'

export default defineComponent({
  components: {
    UploadOutlined,
  },
  data() {
    return {
      fileList: [],
      fileListDirectory: [],
      uploadLoading: false,
    }
  },
  methods: {
    async handleUpload({file, onSuccess, onError,onProgress}) {
      if (!file) {
          message.error("请选择文件")
          return
      }
      let files = file;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/resources/import-text`, file).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.created.length} 条，失败: ${rsp.data.fails.join(",")}`)
          this.uploadLoading = false
          if (rsp.data.created && rsp.data.created.length) {
            let ids = rsp.data.created.map(v=>v.id)
            this.$emit('batchTextIds', ids)
          }
          onSuccess()
        })
        this.uploadTextInput.value = null
      }

      return

      // const name = object
      // const result = await client.multipartUpload(name, file, {
      //   progress(process) {
      //     let percent = Math.round((process*100))
      //     onProgress({percent},file)
      //   }
      // });

      // onSuccess()
    },
    showErrorModal(fails) {
      if(fails && fails.length > 0 ){
        let vnode = []
        for (let o of fails) {
          vnode.push(<div>{o}</div>)
        }
        Modal.error({
          title: "发生错误",
          content: () => <div >{vnode}</div>,
        });
      }
    },
  }
})
</script>
<style lang='scss' scoped>

</style>