<template>

  <a-card title="看板">
   
    <a-space>
     <a-form  :model="formSearch" :label-col=" { span: 4 }" style="margin: 10px auto;" >
        <a-form-item label="统计日期" >
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
        <a-form-item label="平台" >
          <a-select 
            style="width: 100px"
            v-model:value="formSearch.platform"
            :options="platformsSuggestion"
            placeholder="平台"
          >
          </a-select>
        </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          @click="load()"
        >
         查询
        </a-button>
      </a-form-item>
     
    </a-form>
    </a-space>
    <a-card title="新增关键词" >
    <a-row>
      <a-col :span="3">
        <a-statistic title="提交总量" :value="pageInfo.keyword_total" />
      </a-col>
      <a-col :span="3">
        <a-statistic title="审核中" :value="pageInfo.keyword_status[0]" />
      </a-col>
      <a-col :span="3">
        <a-statistic title="审核通过" :value="pageInfo.keyword_status[1] + pageInfo.keyword_status[3]" />
      </a-col>
      <a-col :span="4">
        <a-statistic title="审核不通过" :value="pageInfo.keyword_status[2]" />
      </a-col>
      <a-col :span="4">
        <a-statistic title="提交博主数量" :value="pageInfo.keyword_blogger_total" />
      </a-col>
     
    </a-row>
    </a-card>
    <a-card title="发布统计" >
      <a-row>
        <a-col :span="5">
          <a-statistic title="已发布关键词数" :value="pageInfo.keyword_status[3]"  />
        </a-col>
         <a-col :span="4">
          <a-statistic title="已发布条数" :value="pageInfo.published_total"  />
        </a-col>
        <a-col :span="5">
          <a-statistic title="已通过未发布关键词数" :value="pageInfo.keyword_status[1]"  />
        </a-col>
        <a-col :span="4">
          <a-statistic title="已发布博主数量" :value="pageInfo.published_blogger_total"  />
        </a-col>
       
      </a-row>
    </a-card>
    <a-card title="拉新统计" >
      <a-row>
        <a-col :span="6">
          <a-statistic title="拉新总量" :value="pageInfo.stats_sum" />
        </a-col>
        <a-col :span="7">
          <a-statistic title="有拉新的关键词数" :value="pageInfo.keyword_count" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="有拉新的博主数量" :value="pageInfo.stats_blogger_total" />
        </a-col>
       
      </a-row>
    </a-card>

  </a-card>

</template>
<script >
import { defineComponent, ref, reactive, watch} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import { Modal } from 'ant-design-vue';
import {UserRoleMap, KeywordStatusMap, PlatformSug} from '@/utils/macro'
import _ from 'lodash'

import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'

export default defineComponent({
  components: {},
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
     

      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        platform: '',
        time_range: []
      },
      pageInfo: {
        blogger_status: {},
        keyword_count: 0,
        keyword_status: {},
        stats_sum: 0,
        keyword_total: 0,// 提交总量 
        stats_blogger_total: 0, // 有拉新的博主数量
        keyword_blogger_total: 0,// 提交的博主数量
        published_blogger_total: 0,// 已发布博主数量
      },
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{},
  mounted(){
    this.load()
  },
  methods: {
    async load () {
      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      const rsp = await request.get('/api/stats/dashboard', {
        time_range: timeRange.join(','),
        platform: this.formSearch.platform,
      })
      this.loading = false
      this.pageInfo = rsp.data
      this.pageInfo.keyword_total = 0
      for (let s in this.pageInfo.keyword_status) {
        this.pageInfo.keyword_total += this.pageInfo.keyword_status[s]
      }

    

    },
  },
  setup(props, context) {
    const timeRange = ref([]);
    
    const disabledDateRangeDate = (current) => {
      if (!timeRange.value || timeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(timeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        timeRange.value = [];
      }
    };

    const onDateRangeCalendarChange = (val) => {
      timeRange.value = val;
    };


    return {
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeCalendarChange,
      columns: [
        
        {
          title: '商务',
          dataIndex: 'director_name',
          key: 'director_name',
        },
         {
          title: '数据',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
          slots: { customRender: 'stats_sum' },
        },
        //  {
        //   title: '统计日期',
        //   dataIndex: 'stats_date',
        //   key: 'stats_date',
        // },
      ],
       platformsSuggestion: [].concat(PlatformSug)
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>