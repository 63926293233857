<template>

  <a-card title="通知发布">
   
    <a-space>
     <a-form layout="inline" :model="formSearch"  style="margin: 10px auto;" >
       
       <a-form-item label="平台" >
          <a-select
            v-model:value="formSearch.platform"
            placeholder="平台"
            style="width: 200px"
          >
            <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
              {{i}}
            </a-select-option>
          </a-select>
        </a-form-item>
      
        <a-form-item label="通知日期" >
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      
        
      <a-form-item>
       <a-space>
        <a-button
          type="primary"
          @click="handleTableChange()"
        >
         查询
        </a-button>
        <a-button
          type="primary"
          @click="handleCreateNotification()"
        >
         创建通知
        </a-button>
       </a-space>
      </a-form-item>
      
    </a-form>
    </a-space>
    

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    :scroll="{ x: true }"
    >
    <template #created_at="{ record }" >
      <span >{{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
    </template>
    <template #method="{ record }" >
      <span >{{record.method == 1 ? "条件范围" : "指定对象"}}</span>
    </template>
    <template #target_role="{ record }" >
      <span >{{ UserRoleMap[record.target_role] }}</span>
    </template>
    <template #target_platform="{ record }" >
      <span >{{ record.target_platform ? record.target_platform : "所有" }}</span>
    </template>
    <template #platform="{ record }" >
      <span >{{ record.platform ? record.platform : "所有" }}</span>
    </template>
    <template #published_at="{ record }" >

      <span >{{!record.published_at ? "未发布": moment(record.published_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
    </template>
    <template #action="{ record }" >
        <span>
         
          <a-space>
            <a-button
              v-if="!record.published_at "
              type="primary"
              size="small"
              @click="onPublish(record)"
            >
              发布
            </a-button>
            <a-button
            v-if="!record.published_at"
              size="small"
              @click="onEdit(record)"
            >
              编辑
            </a-button>
            <a-button
            v-if="!record.published_at"
              danger
              size="small"
              @click="onDelete(record)"
            >
              删除
            </a-button>
           
          </a-space>
        </span>
      </template>
  </a-table>
  </a-card>
  <NotificationEditor :visible="notificationEditorVisible" @ok="onNotificationEditorSuccess" @cancel="notificationEditorVisible = false" :record="notificationEditorRecord"  />
</template>
<script >
import { defineComponent, ref} from 'vue'
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';

import _ from 'lodash'
import { useStore } from 'vuex'
import moment from 'moment'

import request from '@/utils/request'
import {UserRoleMap, PlatformEnum} from '@/utils/macro'
import NotificationEditor from '@/components/NotificationEditor.vue'

export default defineComponent({
  components: {
    NotificationEditor,
  },
  data(){
    return {
      moment,
      UserRoleMap,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      notificationEditorVisible: false,
      notificationEditorRecord: {},
      formSearch: {
        query: '',
        platform: '',
        time_range: [],
      },
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  mounted(){
    this.handleTableChange()
  },
  methods: {
    onPublish(record){
      Modal.confirm({
          content: () => `确认发布「${record.content}」吗？`,
          onOk: () => {
            request.post(`/api/op_notifications/${record.id}/public`, {
              
            }).then(() => {
              this.handleTableChange(this.pagination)
            })
          },
          onCancel() {
            Modal.destroyAll();
          },
        });
    },
    onDelete(record){
      Modal.confirm({
          content: () => `确认删除「${record.content}」吗？`,
          onOk: () => {
            request.delete(`/api/op_notifications/${record.id}`, {
              
            }).then(() => {
              this.handleTableChange(this.pagination)
            })
          },
          onCancel() {
            Modal.destroyAll();
          },
        });
    },
    onEdit(record){
      this.notificationEditorRecord = {
        ...record,
        method: `${record.method}`
      }
      this.notificationEditorVisible = true
    },
    onNotificationEditorSuccess(){
      this.notificationEditorVisible = false
      this.handleTableChange()
    },
    handleCreateNotification(){
      this.notificationEditorRecord = {}
      this.notificationEditorVisible = true
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true

      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      const rsp = await request.get('/api/op_notifications', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        platform: this.formSearch.platform,
        time_range: timeRange.join(','),
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
    const timeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!timeRange.value || timeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(timeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        timeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      timeRange.value = val;
    };


    return {
      PlatformEnum,
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
      {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 150,
        },
         
        {
          title: '通知内容',
          dataIndex: 'content',
          key: 'content',
          width: 200,
        },
        {
          title: '通知方式',
          dataIndex: 'method',
          key: 'method',
          slots: { customRender: 'method' },
          width: 100,
        },
        {
          title: '发布对象角色',
          dataIndex: 'target_role',
          key: 'target_role',
          slots: { customRender: 'target_role' },
          width: 150,
        },
        {
          title: '发布对象平台',
          dataIndex: 'target_platform',
          key: 'target_platform',
          slots: { customRender: 'target_platform' },
          width: 150,
        },
        {
          title: '操作人',
          dataIndex: 'username',
          key: 'username',
          width: 150,
        },
        {
          title: '通知数量',
          dataIndex: 'published_count',
          key: 'published_count',
          width: 100,
        },
         {
          title: '通知时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 150,
        },
        {
          title: '发布时间',
          dataIndex: 'published_at',
          key: 'published_at',
          slots: { customRender: 'published_at' },
          width: 150,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>