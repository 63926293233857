<template>
  <a-card title="支付信息">
    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="用户ID" >
          <a-input placeholder="用户ID" v-model:value="formSearch.user_id" allowClear @keyup.enter="searchQuery" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="searchQuery"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #icon="{ record }" >
        <img :src="record.icon" alt="" style="height:40px">
      </template>
      <template #payment_method="{ record }" >
        <span >{{record.payment_method=='alipay'?'支付宝':'银行卡'}}</span>
      </template>
      <template #is_activated="{ record }" >
        <span>
          <a-tag v-if="record.is_activated == 1" color="green"  >启用</a-tag>
          <a-tag  v-else >未启用</a-tag>
        </span>
      </template>
      <template #updated_at="{ record }" >
        <span >{{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
    </a-table>

  </a-card>

</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';

export default defineComponent({
  components: {
    SearchOutlined
  },
  data(){
    return {
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        user_id: '',
      },
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/users/bank_cards', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    searchQuery() {
      this.handleTableChange()
    },
  },
  setup() {
    

    return {
      columns: [
        {
          title: '用户ID',
          dataIndex: 'user_id',
          key: 'user_id',
          width: 100,
        },
        {
          title: '银行LOGO',
          dataIndex: 'icon',
          key: 'icon',
          slots: { customRender: 'icon' },
          width: 120,
        },
        {
          title: '银行名称',
          dataIndex: 'bank_name',
          key: 'bank_name',
          width: 120,
        },
        {
          title: '卡号/支付宝号',
          dataIndex: 'card_code',
          key: 'card_code',
          width: 180,
        },
        {
          title: '持卡人',
          dataIndex: 'bank_account',
          key: 'bank_account',
          width: 100,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          width: 120,
        },
        {
          title: '身份证',
          dataIndex: 'id_card',
          key: 'id_card',
          width: 180,
        },
        {
          title: '付款方式',
          dataIndex: 'payment_method',
          key: 'payment_method',
          slots: { customRender: 'payment_method' },
          width: 100,
        },
        {
          title: '启用状态',
          dataIndex: 'is_activated',
          key: 'is_activated',
          slots: { customRender: 'is_activated' },
          width: 100,
        },
        {
          title: '更新时间',
          dataIndex: 'updated_at',
          key: 'updated_at',
          slots: { customRender: 'updated_at' },
          width: 170,
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 170,
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>