<template>
  <a-modal v-model:visible="visible" title="资源数据预览" :footer="null" :destroyOnClose="true" @ok="handleOk" @cancel="handleCancel">
    <a-list
      class="demo-loadmore-list"
      item-layout="horizontal"
      :data-source="previewList"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <template #actions>
            <a key="list-loadmore-delete" @click="unbindSourcePkgs(item)">删除</a>
          </template>
          <div v-if="item.category==='text'" style="margin-bottom:10px">{{ item.text }}</div>
          <a-image v-if="item.category==='image'" :src="item.raw_url" style="margin-bottom:10px" />
          <div v-if="item.category==='video'"  style="margin-bottom:10px">
            <video
              :src="item.raw_url"
              ref="video"
              style="width:100%;"
              controls
              preload="metadata"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="false"
              x5-video-orientation="landscape"
              > 抱歉，您的浏览器不支持内嵌视频! </video>
          </div>
        </a-list-item>
      </template>
    </a-list>
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import request from '@/utils/request'

export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      previewList: [],
      offset: 1,
      limit: 10
    }
  },
  watch:{
    record: function(record, v2) {
      console.log(record);
      if (record) {
        this.getResourceList()
      }
    },
  },
  created() {
    // this.getResourceList()
  },
  methods: {
    moment,
    async getResourceList() {
      this.previewList = []
      console.log(123123, this.record);
      const rsp = await request.get('/api/resources', {
        package_id: this.record.id
      })
      this.previewList = rsp.data.list
    },
    async unbindSourcePkgs(item) {
      const rsp = await request.delete(`/api/resource_pkgs/${this.record.id}/resources/${item.id}`)
      this.getResourceList()
    },
    handleOk() {
      this.$emit("ok", false)
    },
    handleCancel() {
      this.$emit("cancel", false)

    },
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    return { 
      dataSource,
      visible,
    };
  },
});
</script>