<template>
  <a-modal v-model:visible="visible" title="批量审核收入申请">
    <a-form :model="form" style="margin: 10px auto;" ref="formRef" >
      <a-form-item label="" name="reason">
        <a-radio-group v-model:value="form.isAccept">
          <a-radio :value="1">通过</a-radio>
          <a-radio :value="2">不通过</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="" name="reason" v-if="form.isAccept==2">
        <a-textarea
          v-model:value="form.audit_remark"
          placeholder="请输入原因"
          :auto-size="{ minRows: 2, maxRows: 5 }" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
    </template>
      
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import request from '@/utils/request'
import { Modal, message, Upload } from 'ant-design-vue';

export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      loading: false,
      form: {isAccept: 1,audit_remark:''},
    }
  },
  methods: {
    moment,
    handleOk() {
      const that = this
      if (this.form.isAccept==2&&!this.form.audit_remark) {
        this.loading = false
        return message.error("请输入原因")
      }
      that.loading = true
      that.fetchAllData(that.record).then(res=>{
        message.error('已完成审核')
        that.handleCancel()
        that.$emit('clearSelection')
        that.loading = false
      })
      .catch(error => {
          console.error("请求出错:", error);
          that.loading = false
      });
    },
    handleCancel() {
      this.form.isAccept=1
      this.form.audit_remark=''
      this.$emit("cancel", false)
    },
    async fetchData(item) {
      const that = this
        return new Promise((resolve, reject) => {
          if (that.form.isAccept==1) {
            request.post(`/api/payments/applications/${item}/audit/accept`).then(() => {resolve()}).catch(()=>reject())
          } else if (that.form.isAccept==2) {
            request.post(`/api/payments/applications/${item}/audit/reject`,{audit_remark:that.form.audit_remark}).then((rsp)=>{resolve()}).catch(()=>reject())
          }
          
        });
    },
    async fetchAllData(items) {
      const promises = items.map(item => this.fetchData(item));
      const results = await Promise.all(promises);
      return results;
    },
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    return { 
      dataSource,
      visible,
    };
  },
});
</script>