<template>
  <a-modal v-model:visible="visible" title="审核付款批次">
    <a-form :model="form" style="margin: 10px auto;" ref="formRef" >
      <a-form-item label="" name="reason">
        <a-radio-group v-model:value="form.isAccept">
          <a-radio :value="1">通过</a-radio>
          <a-radio :value="2">不通过</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="" name="reason" v-if="form.isAccept==2">
        <a-textarea
          v-model:value="form.audit_remark"
          placeholder="请输入原因"
          :auto-size="{ minRows: 2, maxRows: 5 }" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
    </template>
      
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import request from '@/utils/request'
import { Modal, message, Upload } from 'ant-design-vue';

export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      loading: false,
      form: {isAccept: 1,audit_remark:''},
    }
  },
  methods: {
    moment,
    handleOk() {
      this.$refs.formRef.validate().then((res) => {
        if (res) {
          console.log(res);
          this.loading = true
          if (this.form.isAccept==1) {
            this.handleAccept()
          } else if (this.form.isAccept==2) {
            this.handleRefuse()
          }
        } else {
          return
        }
      })
    },
    handleAccept() {
      request.post(`/api/payments/batches/${this.record.id}/audit/accept`).then((rsp) => {
        console.log(rsp);
        this.handleCancel()
        this.loading = false
      }).catch(()=>this.loading = false)
    },
    handleRefuse() {
      if (!this.form.audit_remark) {
        this.loading = false
        return message.error("请输入原因")
      }
      request.post(`/api/payments/batches/${this.record.id}/audit/reject`,{audit_remark:this.form.audit_remark}).then((rsp) => {
        console.log(rsp);
        this.handleCancel()
        this.loading = false
      }).catch(()=>this.loading = false)
    },
    handleCancel() {
      this.form.isAccept=1
      this.form.audit_remark=''
      this.$emit("cancel", false)
    },
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    return { 
      dataSource,
      visible,
    };
  },
});
</script>