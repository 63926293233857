<template>
  <a-card title="悬赏任务链接审核">
    <div>
      <a-tabs type="card" v-model:activeKey="linkStatus" @change="handleChangeTab">
        <a-tab-pane key="0" :tab="`待审核`"></a-tab-pane>
        <a-tab-pane key="1,2" :tab="`已审核`"></a-tab-pane>
      </a-tabs>
    </div>
    <a-space>
      <a-form layout="inline" class="resourcelink-form" :model="formSearch" @keyup.enter="handleTableChange()" style="margin: 10px auto;"   >
        <a-form-item label="平台">
          <a-select v-model:value="formSearch.platform" @change="handleTableChange()" placeholder="平台" :allowClear="true" style="width: 200px">
            <a-select-option v-for="(item, index) in platformList" :value="item.name" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="关键词">
          <a-select
            v-model:value="formSearch.keyword_id"
            :options="keywordsSuggestion"
            @search="searchKeywords"
            show-search
            :filter-option="false"
            :not-found-content="null"
            :allowClear="true"
            placeholder="选择关键词"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="资源包名称">
          <!-- <a-input placeholder="资源包名称" v-model:value="formSearch.package_title" /> -->
          <a-select
            v-model:value="formSearch.package_id"
            :options="resourcesSuggestion"
            @search="searchResources"
            show-search
            placeholder="选择资源包"
            :filter-option="false"
            :not-found-content="null"
            :allowClear="true"
            style="width: 200px"
          />
        </a-form-item>

        <a-form-item label="发布人">
          <a-input placeholder="发布人" v-model:value="formSearch.user_id" :allowClear="true" />
        </a-form-item>
        <a-form-item label="发布时间">
          <a-range-picker v-model:value="formSearch.time_range" :disabledDate="disabledDateRangeDate" @change="onDateRangeChange" @openChange="onDateRangeOpenChange" @calendarChange="onDateRangeCalendarChange" /></a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleTableChange()" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>

    </a-space>
    <a-row style="margin-bottom:10px" v-if="linkStatus=='0'">
      <a-dropdown-button type="primary">
        批量审核
        <template #overlay>
          <a-menu @click="handleUploadStatus">
            <a-menu-item key="1">批量导出待审核</a-menu-item>
            <a-menu-item key="2">批量导入结果</a-menu-item>
            <a-menu-item key="3">导出/导入模版</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>

      <input ref='uploadStatusInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadStatus"/>
  
    </a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #audited_at="{ record }" >
        <span >{{moment(record.audited_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #status="{ record }" >
        <span >{{record.status==1?'通过':record.status==2?'拒绝':''}}</span>
      </template>
      <template #snapshot="{ record }" >
        <div class="link-images">
          <a-image-preview-group>
              <a-image :width="80" :src="item" v-for="item in record.snapshot" :key="item" />
          </a-image-preview-group>
        </div>
      </template>
      <template #action="{ record }">
        <span>
          <a-button type="link" style="padding:0" @click="auditLink(record, '1')" >有效</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="auditLink(record, '2')" >无效</a-button>
        </span>
      </template>
    </a-table>
  </a-card>
  <AuditLinkModel v-model:visible="auditLinkVisible"  @cancel="auditLinkVisible = false" @ok="handleAuditSuccess" :record="linkRecordInitial" />
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap } from '@/utils/macro'
import { Modal, message, Upload } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import OSS from 'ali-oss'
import AuditLinkModel from './components/AuditLinkModel'

let _searchKeywordsDebounce = null
let _searchResourceDebounce = null

export default defineComponent({
  components: {
    SearchOutlined,
    AuditLinkModel,
  },
  data(){
    return {
      keywordsSuggestion: [],
      resourcesSuggestion: [],
      uploadLoading: false,
      auditLinkVisible: false,
      linkRecordInitial: {},
      linkStatus: '0',
      UserRoleMap,
      platformList: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      loading: false,
      dataSource: [],
      noticeMessage: '',
      formSearch: {
      },
      normalColumns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '资源包名称',
          dataIndex: 'package_title',
          key: 'package_title',
          width: 120,
        },
        {
          title: '悬赏任务链接',
          dataIndex: 'publish_url',
          key: 'publish_url',
          width: 120,
        },
        {
          title: '个人主页链接',
          dataIndex: 'home_url',
          key: 'home_url',
          width: 120,
        },
        {
          title: '发布内容截图',
          dataIndex: 'snapshot',
          key: 'snapshot',
          slots: { customRender: 'snapshot' },
          width: 300,
        },
        {
          title: '关键词',
          dataIndex: 'keyword',
          key: 'keyword',
          width: 150,
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 150,
        },
        {
          title: '发布时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '发布人',
          dataIndex: 'username',
          key: 'username',
          width: 120,
        },
      ],
      actionColumns: [
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
      auditColumns: [
        {
          title: '审核时间',
          dataIndex: 'audited_at',
          key: 'audited_at',
          slots: { customRender: 'audited_at' },
          width: 180,
        },
        {
          title: '审核人',
          dataIndex: 'operator',
          key: 'operator',
          width: 120,
        },
        {
          title: '审核结果',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 120,
        },
        {
          title: '无效原因',
          dataIndex: 'reason',
          key: 'reason',
          width: 120,
        },
      ],
      columns: []
    }
  },
  mounted(){
    if (this.user_role !== 1) {
      return
    }
    this.handleTableChange()
    this.getPlatforms()
    this.searchResources()
    this.searchKeywords()
    this.columns = [...this.normalColumns, ...this.actionColumns]
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    searchResources(text) {
      clearTimeout(_searchResourceDebounce)
      _searchResourceDebounce = setTimeout(() => {
        request.get(`/api/resource_pkgs`, {
          title: text
        }).then((rsp) => {
          this.resourcesSuggestion = []
          for (let v of rsp.data.list) {
            console.log(v);
            this.resourcesSuggestion.push({
              id: v.id,
              value: v.id,
              label: v.title,
            })
          }
        })
      }, 600)
    },
    searchKeywords(text) {
      clearTimeout(_searchKeywordsDebounce)
      _searchKeywordsDebounce = setTimeout(() => {
        request.get(`/api/keywords`, {
          text: text
        }).then((rsp) => {
          this.keywordsSuggestion = []
          for (let v of rsp.data.list) {
            this.keywordsSuggestion.push({
              id: v.id,
              value: v.id,
              label: v.text,
            })
          }
        })
      }, 600)
    },
    handleChangeTab(value) {
      console.log(value);
      if (value=='0') {
        this.columns = [...this.normalColumns, ...this.actionColumns]
      } else {
        this.columns = [...this.normalColumns, ...this.auditColumns]
      }
      this.handleTableChange()

    },
    async getPlatforms () {
      const rsp = await request.get('/api/platforms')
      this.platformList = rsp.data.platforms
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        let timeRange = []
        if (this.formSearch.time_range) {
          for (let t of this.formSearch.time_range) {
            timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
          }
        }
        const rsp = await request.get('/api/resource_published', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
          time_range: timeRange.join(','),
          audit_status: this.linkStatus,
        })
        this.loading = false
        this.dataSource = rsp.data.list
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    handleUploadStatus(e) {
      if (e.key == "1") { // 批量导出待审核
        window.open("/api/resource_published/export-audit")
      } else if (e.key=='2') { // 导入审核结果
        this.uploadStatusInput.click()
      } else if (e.key=='3') { // 下载
        window.open("https://rmnkqjcev8.feishu.cn/sheets/HlRbsCPbehy4mQtNPs1cscJ9nUd")
      }
    },
    auditLink(record, status) {
      if (status=='2') { // 拒绝
        console.log(record, status);
        this.linkRecordInitial = {...record}
        this.auditLinkVisible = true;
      } else {
        message.success("审核通过")
        this.handleAuditSuccess({
          id: record.id, 
          status: 1,
          reason: ''
        })
      }
    },
    async handleAuditSuccess(params) {
      console.log('success', params);
      await request.post(`/api/resource_published/${params.id}/audit`, params)
      this.auditLinkVisible = false;
      this.handleTableChange()

    },
    onChangeUploadStatus(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/keywords/csv/create`, files[0]).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadStatusInput.value = null
      }
    },
    showErrorModal(fails) {
      if(fails && fails.length > 0 ){
        let vnode = []
        for (let o of fails) {
          vnode.push(<div>{o}</div>)
        }
        Modal.error({
          title: "发生错误",
          content: () => <div >{vnode}</div>,
        });
      }
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {return false}
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {applyTimeRange.value = [];}
    };
    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };
    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      uploadStatusInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      // columns: [
      //   {
      //     title: 'ID',
      //     dataIndex: 'id',
      //     key: 'id',
      //     width: 100,
      //   },
      //   {
      //     title: '资源包名称',
      //     dataIndex: 'pkg_title',
      //     key: 'pkg_title',
      //     width: 120,
      //   },
      //   {
      //     title: '悬赏任务链接',
      //     dataIndex: 'publish_url',
      //     key: 'publish_url',
      //     width: 120,
      //   },
      //   {
      //     title: '个人主页链接',
      //     dataIndex: 'home_url',
      //     key: 'home_url',
      //     width: 120,
      //   },
      //   {
      //     title: '发布内容截图',
      //     dataIndex: 'snapshot',
      //     key: 'snapshot',
      //     slots: { customRender: 'snapshot' },
      //     width: 150,
      //   },
      //   {
      //     title: '关键词',
      //     dataIndex: 'keyword',
      //     key: 'keyword',
      //     width: 150,
      //   },
      //   {
      //     title: '平台',
      //     dataIndex: 'platform',
      //     key: 'platform',
      //     width: 150,
      //   },
      //   {
      //     title: '发布时间',
      //     dataIndex: 'created_at',
      //     key: 'created_at',
      //     slots: { customRender: 'created_at' },
      //     width: 180,
      //   },
      //   {
      //     title: '发布人',
      //     dataIndex: 'username',
      //     key: 'username',
      //     width: 120,
      //   },
      //   {
      //     title: '审核时间',
      //     dataIndex: 'audited_at',
      //     key: 'audited_at',
      //     slots: { customRender: 'audited_at' },
      //     width: 180,
      //   },
      //   {
      //     title: '审核人',
      //     dataIndex: 'auditor',
      //     key: 'auditor',
      //     width: 120,
      //   },
      //   {
      //     title: '审核结果',
      //     dataIndex: 'audit_status',
      //     key: 'audit_status',
      //     width: 120,
      //   },
      //   {
      //     title: '无效原因',
      //     dataIndex: 'reason',
      //     key: 'reason',
      //     width: 120,
      //   },
      //   {
      //     title: '操作',
      //     key: 'action',
      //     fixed: 'right',
      //     width: 200,
      //     slots: { customRender: 'action' },
      //   },
      // ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.resourcelink-form.ant-form-inline .ant-form-item {
  margin-bottom: 10px;
}
.link-images>img {
  height: 80px;
  margin-bottom: 5px;
  margin-right: 5px;
}
</style>