<template>
  <a-card title="悬赏任务资源包管理">
    <a-space>
      <a-form layout="inline" class="resourcepkgs-form" :model="formSearch" @keyup.enter="handleTableChange()" style="margin: 10px auto;"   >
        <a-form-item label="资源包名称">
          <a-input placeholder="资源包名称" v-model:value="formSearch.title" :allowClear="true" />
        </a-form-item>
        <a-form-item label="平台">
          <a-select v-model:value="formSearch.platform" @change="handleTableChange()" placeholder="平台" :allowClear="true" style="width: 200px">
            <a-select-option v-for="(item, index) in platformList" :value="item.name" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="关键词">
          <a-select
            v-model:value="formSearch.keyword_id"
            :options="keywordsSuggestion"
            @search="searchKeywords"
            show-search
            :allowClear="true"
            :filter-option="false"
            :not-found-content="null"
            placeholder="选择关键词"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="创建人">
          <a-input placeholder="创建人" v-model:value="formSearch.operator_id" :allowClear="true" />
        </a-form-item>
        <a-form-item label="创建时间">
          <a-range-picker v-model:value="formSearch.time_range" :disabledDate="disabledDateRangeDate" @change="onDateRangeChange" @openChange="onDateRangeOpenChange" @calendarChange="onDateRangeCalendarChange"></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleTableChange()" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>
    </a-space>
    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="updatePkgs(null)">创建资源包</a-button>
      <a-dropdown-button type="primary" style="margin-left:10px">
        批量上传资源包
        <template #overlay>
          <a-menu @click="handleCreateUploadPkgs">
            <a-menu-item key="1">上传文件</a-menu-item>
            <a-menu-item key="2">下载模板</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
      <a-button type="primary" @click="deleteBatch" style="margin-left:10px">批量删除</a-button>

      <input ref='uploadPkgsInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadPkgs"/>
  
    </a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    @change="handleTableChange"
    :row-selection="rowSelection"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #created_at="{ record }" >
        <span>{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #updated_at="{ record }" >
        <span>{{moment(record.updated_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #resource_count="{ record }" >
        <a-button type="link" @click="previewResourceList(record)">{{record.resource_count}}</a-button>
      </template>
      <template #target_users="{ record }" >
        <div v-for="(val, i) in record.target_users" :key="i">{{ val.username }}</div>
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="updatePkgs(record)" >编辑</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="addResources(record)" >新增资源</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="deletePkgs(record)" >删除</a-button>
        </span>
      </template>
    </a-table>
  </a-card>
  <PreviewResourceListModel v-model:visible="previewListVisible"  @cancel="handleRefresh" @ok="handleRefresh" :record="currentRecordInitial"/>
  <ResourcePkgsModel ref="ResourcePkgsModelRef" v-model:visible="resourcePkgsVisible" @cancel="resourcePkgsVisible = false" @ok="handleResourcePkgsSuccess" :record="currentRecordInitial"/>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, CREATE_RESOURCE_PACKAGE } from '@/utils/macro'
import { Modal, message, Upload } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import OSS from 'ali-oss'
import PreviewResourceListModel from './components/PreviewResourceListModel'
import ResourcePkgsModel from './components/ResourcePkgsModel'

let _searchKeywordsDebounce = null

export default defineComponent({
  components: {
    SearchOutlined,
    PreviewResourceListModel,
    ResourcePkgsModel
  },
  data(){
    return {
      UserRoleMap,
      resourcePkgsVisible: false,
      uploadLoading: false,
      previewListVisible: false,
      currentRecordInitial: {},
      platformList: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      loading: false,
      dataSource: [],
      noticeMessage: '',
      formSearch: {
        title: '',
      },
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
        }
      },
      selectedRowKeys: [],
      keywordsSuggestion: [],
    }
  },
  mounted(){
    if (this.user_role !== 1 && !this.daifa_perms) {
      return
    }
    this.getPlatforms()
    this.searchKeywords()
    if (this.$route.query.keyword_id) {
      this.formSearch.keyword_id = this.$route.query.keyword_id
      // this.handleTableChange()
      this.searchKeywords(this.$route.query.keyword)
    }
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    daifa_perms: () => {
      const store = useStore()
      return store.state.user.perms && store.state.user.perms.includes(CREATE_RESOURCE_PACKAGE)
    }
  },
  methods: {
    moment,
    async getPlatforms () {
      const rsp = await request.get('/api/platforms')
      this.platformList = rsp.data.platforms
    },
    searchKeywords(text) {
      clearTimeout(_searchKeywordsDebounce)
      _searchKeywordsDebounce = setTimeout(() => {
        request.get(`/api/keywords`, {
          text: text
        }).then((rsp) => {
          this.keywordsSuggestion = []
          for (let v of rsp.data.list) {
            this.keywordsSuggestion.push({
              id: v.id,
              value: v.id,
              label: v.text,
            })
          }
        })
      }, 600)
    },
    handleRefresh() {
      this.previewListVisible = false
      this.handleTableChange()
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        let timeRange = []
        if (this.formSearch.time_range) {
          for (let t of this.formSearch.time_range) {
            timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
          }
        }
        const rsp = await request.get('/api/resource_pkgs', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
          time_range: timeRange.join(','),
        })
        this.loading = false
        this.dataSource = rsp.data.list
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    deleteBatch() {
      if (!this.selectedRowKeys.length) {
        return message.error('请选择')
      }
      let that = this;
      Modal.confirm({
        title: `删除后不可恢复，确认继续删除吗？`,
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            that.selectedRowKeys.forEach(async (v, i, arr)=> {
            const rsp = await request.delete(`/api/resource_pkgs/${v}`)
              if (i==arr.length-1) {
                that.handleTableChange()
                Modal.destroy();
              }
            })
          } catch(err) {
            console.log(err);
          }
        },
      });
    },
    deletePkgs(initial={}) {
      let that = this;
      Modal.confirm({
        title: `删除后不可恢复，确认继续删除吗？`,
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            const rsp = await request.delete(`/api/resource_pkgs/${initial.id}`)
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
      });
    },
    previewResourceList(initial={}) {
      if (initial.resource_count) {
        this.currentRecordInitial = {...initial}
        this.previewListVisible = true
      }
    },
    handleCreateUploadPkgs(e) {
      if (e.key == "1") { // 上传文件
        this.uploadPkgsInput.click()
      } else if (e.key=='2') { // 下载模板
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/NjPqswyvohyWuYtoF0ScAXlAnRe")
      }
    },
    onChangeUploadPkgs(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/resource_pkgs/import`, files[0]).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadStatusInput.value = null
      }
    },
    showErrorModal(fails) {
      if(fails && fails.length > 0 ){
        let vnode = []
        for (let o of fails) {
          vnode.push(<div>{o}</div>)
        }
        Modal.error({
          title: "发生错误",
          content: () => <div >{vnode}</div>,
        });
      }
    },
    handleResourcePkgsSuccess(val) {
      if (typeof val == 'boolean') {
        this.resourcePkgsVisible = val
        this.$refs.ResourcePkgsModelRef.reset()
        this.handleTableChange()
      }

    },
    updatePkgs(initial) {
      if (!initial) {
        this.currentRecordInitial = {form_type: 'new'}
      } else {
        this.currentRecordInitial = {...initial, form_type: 'edit'}
      }
      this.resourcePkgsVisible = true
    },
    addResources(initial={}) {
      this.currentRecordInitial = {...initial, form_type: 'only_source'}
      this.resourcePkgsVisible = true
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {return false}
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {applyTimeRange.value = [];}
    };
    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };
    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      uploadPkgsInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '资源包名称',
          dataIndex: 'title',
          key: 'title',
          width: 120,
        },
        {
          title: '定向人',
          dataIndex: 'target_users',
          key: 'target_users',
          slots: { customRender: 'target_users' },
          width: 120,
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 120,
        },
        {
          title: '关键词',
          dataIndex: 'keyword',
          key: 'keyword',
          width: 150,
        },
        {
          title: '资源量',
          dataIndex: 'resource_count',
          key: 'resource_count',
          slots: { customRender: 'resource_count' },
          width: 180,
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '创建人',
          dataIndex: 'operator',
          key: 'operator',
          width: 120,
        },
        {
          title: '更新时间',
          dataIndex: 'updated_at',
          key: 'updated_at',
          slots: { customRender: 'updated_at' },
          width: 180,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.resourcepkgs-form.ant-form-inline .ant-form-item {
  margin-bottom: 10px;
}
</style>