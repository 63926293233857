<template>
  <a-modal v-model:visible="visible" title="新增核心团长" width="60%" :confirm-loading="confirmLoading" @cancel="handleCancel" @ok="handleOk">

    <a-row :gutter="16">
      <a-col :span="24">
        <div style="margin-bottom: 10px;" v-show="leaderName">已选核心团长：<a-tag color="green">{{ leaderName }}</a-tag></div>
      </a-col>
      <a-col :span="24">
        <a-select
            v-model:value="formBind._leader_id"
            :options="leaderSuggestion"
            @search="searchLeaders"
            @change="onChangeLeaders"
            mode="multiple"
            placeholder="核心团长名称"
            style="width: 100%"
          />
      </a-col>
    </a-row>
  
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import request from '@/utils/request'
import moment from 'moment';
import { Modal, message } from 'ant-design-vue';

let _searchLeaderDebounce = null 
let _searchManagerDebounce = null 

export default defineComponent({
  data(){
    return {
      confirmLoading: false,
      leaderSuggestion: [],
      managerSuggestion: [],
      formBind: {},
      leaderName: '',
      managerNames: [],
    }
  },
  methods: {
    moment,
    handleCancel() {
      this.formBind = {}
      this.$emit('cancel')
    },
    async handleOk() {
      this.confirmLoading = true;
      try {
        let id = this.formBind.leader_id
        const rsp = await request.post('/api/director_core/set/'+id)
        this.confirmLoading = false
        this.handleCancel()
        this.$emit('confirm')
      } catch(err) {
        this.confirmLoading = false
      }

    },
    onChangeLeaders(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formBind._leader_id = [this.formBind._leader_id[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
        console.log(v);
        this.leaderName = v.value
      }
      this.formBind.leader_id = ids.join(',')
    },
    searchLeaders(name) {
      clearTimeout(_searchLeaderDebounce)
      _searchLeaderDebounce =  setTimeout(() => {
        request.get(`/api/users`, {
          offset: 0,
          limit: 20,
          role: 3,
          username: name
        }).then((rsp) => {
          this.leaderSuggestion = []
          for (let v of rsp.data.list) {
            this.leaderSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    return { 
      visible
    };
  },
});
</script>