<template>
  <a-modal v-model:visible="visible" title="用户设置" :footer="null" >
   
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item required  label="用户名称">
          <a-input v-model:value="formState.username" />
      </a-form-item>
      <a-form-item :required="formState.form_type == 'new'" label="登录密码">
          <a-input v-model:value="formState.password" />
      </a-form-item>
      <a-form-item required label="角色">
          <a-select
          v-if="user_role == 1"
          v-model:value="formState.role"
          >
          <a-select-option :value='i' v-for='i of UserRoleList' :key='i' >
            {{UserRoleMap[i]}}
          </a-select-option>
          </a-select>

          <!-- <a-select
          v-else-if="user_role == 3&&user_label=='core'"
          v-model:value="formState.role"
          >
            <a-select-option  :value='"4"'  :key='4'>博主</a-select-option>
            <a-select-option  :value='"3"'  :key='3'>商务</a-select-option>
          </a-select> -->

          <a-select
            v-else
            v-model:value="formState.role"
            >
            <a-select-option :value='"4"'  :key='4' >
              博主
            </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item  required label="用户状态">
          <!-- <a-select
           style="width: 130px;"
            v-model:value="formState.status"
            v-if="user_role == 3&&user_label=='core'&&formState.role==3"
          >
          <a-select-option value='0' key='0' >
            待审核
          </a-select-option>
          </a-select> -->
          <a-select
           style="width: 130px;"
            v-model:value="formState.status"
          >
          <a-select-option :value='i' v-for='i of Object.keys(UserStatusMap)' :key='i' >
            {{UserStatusMap[i]}}
          </a-select-option>
          </a-select>
         
      </a-form-item>
      <a-form-item required label="手机号">
        <a-input v-model:value="formState.mobile" :maxlength="11" />
      </a-form-item>
      <template v-if="formState.role == 4" >
        <a-form-item required label="博主名称">
          <a-input v-model:value="formState.blogger_name" />
        </a-form-item>
        <a-form-item required label="博主平台">
          <a-select
          v-model:value="formState.blogger_platform"
          >
          <a-select-option :value='i' v-for='i of bloggerPlatforms' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item required label="博主类型">
          <a-select
          v-model:value="formState.blogger_category"
          >
          <a-select-option :value='i' v-for='i of bloggerCategory' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item required label="主页地址">
          <a-input type="url" v-model:value="formState.home_url" />
        </a-form-item>
        <!-- <a-form-item required label="博主来源">
          <a-select
          v-model:value="formState.source"
          >
          <a-select-option :value='i' v-for='i of bloggerSources' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
        </a-form-item>
         <a-form-item required v-if="formState.source == '推荐'" label="推荐人">
          <a-input v-model:value="formState.recommender" />
        </a-form-item> -->
      </template>
      <!-- <template v-if="[1,5,7].indexOf(+formState.role) > -1" >
         <a-form-item required label="手机号">
          <a-input v-model:value="formState.mobile" :maxlength="11" />
        </a-form-item>
      </template> -->
      <template v-if="[1,5].indexOf(+formState.role) > -1" >
         <a-form-item required label="绑定平台">
            <a-select
              v-model:value="formState.platform"
              >
              <a-select-option :value='v' v-for='v in PlatformEnum' :key='v' >
                {{v}}
              </a-select-option>
              </a-select>
        </a-form-item>
      </template>
      <template v-if="formState.origin_ids != 'loading' && formState.role == 2" >
        <a-form-item  label="绑定商务">
          <a-select
            v-model:value="formState._director_ids"
            :options="directorSuggestion"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="留空不更新"
          />
        </a-form-item>
      </template>
      <template v-if="[1,2,3,4,5].indexOf(+formState.role) > -1" >
        <a-form-item  label="权限">
          <a-checkbox v-model:checked="formState.permission">代发权限选择</a-checkbox>
        </a-form-item>
      </template>
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
          <a-button type="primary" @click="onSubmit">确认</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import {UserRoleMap, UserStatusMap, PlatformEnum} from '@/utils/macro'
import request from '@/utils/request'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'
import md5 from 'js-md5'

let _searchDirectorDebounce
const initialForm = {
  form_type: 'new',
  username: '',
  password: '',
  director_ids: '',
  role: 4,
  remark: '',
  status: 0,
  platform: '', // 运营专属

  blogger_name: '',
  blogger_platform: '',
  blogger_category: '',
  home_url : '',
  source: '',
  recommender: '',

  origin_ids: '',
  _director_ids: [],

  
}
let UserRoleList = Object.keys(UserRoleMap)
UserRoleList.shift()
export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      UserRoleList,
      UserRoleMap,
      PlatformEnum,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      directorSuggestion:[],
      directorSuggestionUnique: {},
     
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    user_label: () => {
      const store = useStore()
      return store.state.user.label
    }
  },
  mounted(){
    if (this.user_role == 1) {
      this.searchDirectors()
    }
    
  },
  watch:{
    record: function(record, v2) {
      if (record.form_type == 'new') {
        for (let item in initialForm) {
          this.formState[item] = '' + initialForm[item];
        }
        this.formState['password'] = ''
        this.formState['origin_ids'] = ''
        this.formState['_director_ids'] = []
        this.formState['permission'] = this.formState.role==1
        return
      } else {
        this.formState.form_type = 'edit'
        this.formState['password'] = ''
        for (let item in record) {
          this.formState[item] = '' + record[item];
        }
        this.formState['permission'] = this.formState.perms=='null'?false:true
        this.getDirectors(record.id)
      }
      this.formState['_director_ids'] = []
    },
    'formState.role': function(newVal) {
      if (this.record.form_type == 'new') {
        this.formState.permission = newVal==1
      }
    }
  },
  methods: {
    onChangeDirectors(item, options){
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      console.log("onChangeDirectors, director_ids",options, this.formState.director_ids)
      this.formState.director_ids = ids.join(',')
      console.log(this.formState.director_ids)
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name,
          limit: 9999,
        }).then((rsp) => {
          this.directorSuggestion = []
          this.directorSuggestionUnique = {}
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
            this.directorSuggestionUnique[v.id] = true
          }
        })
      }, 200)
    },
    getDirectors(directorManagerID) {
      this.formState.origin_ids = 'loading'
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/sub_directors`, {
          director_id: directorManagerID
        }).then((rsp) => {
          // this.directorSuggestion = []
          let ids = []
          for (let v of rsp.data.list) {
            this.formState._director_ids.push(v.username)
            ids.push(v.id)

            // 防止用户被提前删除, 把已有的数据放进去
            if (!this.directorSuggestionUnique[v.id]) {
              this.directorSuggestionUnique[v.id] = true
              this.directorSuggestion.push({
                value: v.username,
                id: v.id
              })
            }
           
            // this.directorSuggestion.push({
            //   value: v.username,
            //   id: v.id
            // })
          }
           
          this.formState.origin_ids = ids.join(',')
          this.formState.director_ids = ids.join(',')
        })
      }, 200)
    },
    async onSubmit(){

      // 商务可以添加博主类型用户
      if ([2,3].indexOf(this.user_role) > -1 && this.formState.role != 4) {
        return message.error("商务只可以添加「博主」类型用户")
      }
      if ([1,5,7].indexOf(+this.formState.role) > -1 && !(/^1\d{10}$/.test(this.formState.mobile))) {
        return message.error("请填写手机号")
      }
      let userId = ''
      if (this.formState.form_type == 'edit') {
        let param = {
          ...this.formState,
          role: +this.formState.role,
          status: + this.formState.status,
          password: this.formState.password ? md5(this.formState.password) : ''
        }
        if (this.formState.origin_ids == this.formState.director_ids) {
          delete(param.director_ids)
        }
        if (param.password === "") {
          delete(param.password)
        }
        console.log(this.formState, param);
        userId = this.record.id
        await request.put(`/api/users/${this.record.id}`, param)

      } else {
        if (!this.formState.username || 
        !this.formState.password ||
        !this.formState.role) {
          return message.error("请填写必填信息")
        }
        if ([1,5,7].indexOf(+this.formState.role) > -1 && !(/^1\d{10}$/.test(this.formState.mobile))) {
          return message.error("请填写手机号")
        }
        let param = {
          ...this.formState,
          role: +this.formState.role,
          status: + this.formState.status,
          password: md5(this.formState.password)
        }
        let res = await request.post('/api/users',param )
        console.log('res',res);
        userId = res.data.user_id

      }
      console.log('userId', userId);
      if (this.formState.permission) {
        await request.post(`/api/users/perms/grant`, { user_id: userId, permission:'CREATE_RESOURCE_PACKAGE' })
      } else {
        await request.post(`/api/users/perms/revoke`, { user_id: userId, permission:'CREATE_RESOURCE_PACKAGE' })
      }
     
      this.$emit("ok")
    },
    onCancle: function () {
      this.$emit("cancel", false)
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    let formState = reactive({
      ...initialForm
    })
   
    return {
      UserStatusMap,
      bloggerSources: [
        "自荐",
        "推荐"
      ],
      bloggerPlatforms: [
        '抖音',
        '快手',
        '小红书',
        '其他'
      ],
      bloggerCategory: [
        '小说',
        '动漫',
        '漫画',
        '壁纸头像',
        '配音',
        '游戏',
        '其他',
      ],
      visible,
      formState
    };
  },
});
</script>