<template>
  <a-modal v-model:visible="visible" @cancel="handleCancel" title="博主新增汇总"  :footer="null" width="80%">

    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="统计日期" >
          <a-range-picker v-model:value="formSearch.date_range" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #blogger_count="{ record }" >
        <a-button type="link" @click="openDetailDia(record, 0)" >{{record.blogger_count}}</a-button>
      </template>
      <template #valid_blogger_count="{ record }" >
        <a-button type="link" @click="openDetailDia(record, 1)" >{{record.valid_blogger_count}}</a-button>
      </template>
    </a-table>

    <BloggerAddNumsDia v-model:visible="bloggerVisible"  @cancel="bloggerVisible = false" :record="initial" />
  
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import request from '@/utils/request'
import moment from 'moment';
import BloggerAddNumsDia from './BloggerAddNumsDia.vue'
import { SearchOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  components: {BloggerAddNumsDia,SearchOutlined},
  props: {
    record: Object
  },
  data(){
    return {
      initial: {},
      bloggerVisible: false,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {},
    }
  },
  watch:{
    record(newVal, oldVal) {
      if (newVal) {
        this.handleTableChange()
      }
    },
  },
  methods: {
    moment,
    handleCancel() {
      this.formSearch = {}
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      console.log(123123123,this.record);
      this.loading = true
      try {
        let date_range = ''
        if (this.formSearch.date_range && this.formSearch.date_range.length) {
          date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        }
        const rsp = await request.get('/api/manager_leader/sub/summary/blogger', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          manager_id: this.record.manager_id,
          manager_leader_id: this.record.manager_leader_id,
          date_range,
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    openDetailDia(row, is_valid) {
      this.initial = {...row, is_valid}
      this.bloggerVisible = true
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    console.log('record', visible, record);
    watch(props, (newProps, oldProps) => {
      console.log(newProps);
    });
    return { 
      visible,
      columns: [
        {
          title: '商务主管名称',
          dataIndex: 'manager_name',
          key: 'manager_name',
          width: 100,
        },
        {
          title: '新增博主',
          dataIndex: 'blogger_count',
          key: 'blogger_count',
          slots: { customRender: 'blogger_count' },
          width: 100,
        },
        {
          title: '有效博主',
          dataIndex: 'valid_blogger_count',
          key: 'valid_blogger_count',
          slots: { customRender: 'valid_blogger_count' },
          width: 100,
        },
        {
          title: '统计日期',
          dataIndex: 'stats_date',
          key: 'stats_date',
          width: 100,
        },
      ],
    };
  },
});
</script>