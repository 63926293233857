<template>
  <a-card title="爆款书单管理">
    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" @keyup.enter="handleTableChange"  >
        <a-form-item label="平台">
          <a-select v-model:value="formSearch.platform" style="width: 120px"  :allowClear="true">
            <a-select-option v-for="(item, index) in platformList" :value="item.name" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="图书">
          <a-input placeholder="图书名称" v-model:value="formSearch.book_name" />
        </a-form-item>

        <a-form-item  label="状态">
          <a-select style="width: 120px;" v-model:value="formSearch.status" :allowClear="true" >
            <a-select-option :value='0' :key='0' >未上架</a-select-option>
            <a-select-option :value='1' :key='1' >上架</a-select-option>
            <a-select-option :value='2' :key='2' >无效</a-select-option>
          </a-select>
        </a-form-item>
       
        <!-- <a-form-item label="时间">
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item> -->

        <a-form-item>
          <a-button type="primary" @click="handleTableChange" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>

    </a-space>
    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="openBookDia(null)">新增</a-button>
      <!-- <a-button type="primary" @click="uploadBookListInput.click()" style="margin-left:10px">导入书单</a-button> -->
      <a-dropdown-button type="primary" style="margin-left:10px" >
        导入书单
        <template #overlay>
          <a-menu @click="handleUploadBookList">
            <a-menu-item key="1">
              下载模板
            </a-menu-item>
            <a-menu-item key="2">
              上传数据
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
      <!-- <a-button type="primary" style="margin-left:10px">下载模板</a-button>
      <a-button type="primary" style="margin-left:10px">上传文件</a-button>
      <a-button type="danger" style="margin-left:10px">批量删除</a-button> -->
      <a-spin v-if="uploadLoading" />
      <input ref='uploadBookListInput'  accept=".csv" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadChangeKeyword"/>
  
    </a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #cover_url="{ record }" >
        <img :src="record.cover_url" style="height:80px;" alt="">
      </template>
      <template #desc="{ record }" >
        <span>{{ record.desc.slice(0, 10) + '...' }}</span>
      </template>
      <template #status="{ record }" >
        <!-- <a-tag v-if="record.status===1" color="green">有效</a-tag>
        <a-tag v-else color="red">无效</a-tag> -->
        <a-tag v-if="record.status===2" color="red">无效</a-tag>
        <a-switch v-else v-model:checked="record.status" :checkedValue="1" :unCheckedValue="0" @change="handleChangeStatus($event, record, index)" />
      
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="openBookDia(record)" >编辑</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="deleteBook(record)" >删除</a-button>
        </span>
      </template>
    </a-table>
  </a-card>


  <a-modal v-model:visible="openDia" class="hotbook-model" :title="!currentData?'新增':'编辑'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" :label-col="{ style: { width: '100px' } }">
        <a-form-item label="平台" name="platform">
          <a-select v-model:value="formAdd.platform" style="width: 100%" >
            <a-select-option v-for="(item, index) in platformList" :value="item.name" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="推荐类型" name="recommend_type">
          <a-select v-model:value="formAdd.recommend_type" style="width: 100%" >
            <a-select-option v-for="(item, index) in recommendTypeList" :value="item" :key="index">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="图书名称" name="book_name">
          <a-input placeholder="图书名称" v-model:value="formAdd.book_name" />
        </a-form-item>
        <a-form-item label="图书链接" name="book_url">
          <a-input placeholder="图书链接" v-model:value="formAdd.book_url"  />
        </a-form-item>
        <a-form-item label="封面" name="cover_url">
          <Upload v-model:imageUrl="formAdd.cover_url"  />
        </a-form-item>
        <a-form-item label="简介" name="desc">
          <a-textarea v-model:value="formAdd.desc" placeholder="请输入简介，最多支持100字" :auto-size="{ minRows: 3 }" show-count :maxlength="100" />
        </a-form-item>
        <a-form-item label="书单类型" name="list_category">
          <a-select v-model:value="formAdd.list_category" style="width: 100%" >
            <a-select-option v-for="(item, index) in listCategoryList" :value="item" :key="index">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="内容类型" name="content_category">
          <a-select v-model:value="formAdd.content_category" style="width: 100%" >
            <a-select-option v-for="(item, index) in contentCategoryList" :value="item" :key="index">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <a-alert message="填写图书链接可支持直接抓取封面、简介，如抓取不成功还需补充完整。" type="info" show-icon />
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import Upload from './../components/Upload.vue'



export default defineComponent({
  components: {
    SearchOutlined,
    Upload,
  },
  data(){
    const validateCoverUrl = async (_rule, value) => {
      if (!this.formAdd.book_url) {
        return Promise.reject("请选择上传封面");
      } else {
        return Promise.resolve();
      }
    }
    const validateBookDesc = async (_rule, value) => {
      if (!this.formAdd.book_url) {
        return Promise.reject("请填写简介");
      } else {
        return Promise.resolve();
      }
    }
    return {
      uploadLoading: false,
      UserRoleMap,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        platform: '',
        book_name: '',
        time_range: '',
        status: '',
      },
      openDia: false,
      confirmLoading: false,
      currentData: null,
      fileList: [],
      loadingImage: false,
      imageUrl: '',
      formAdd: {
        platform: '',
        recommend_type: '',
        book_name: '',
        book_url: '',
        cover_url: '',
        desc: '',
        list_category: '',
        content_category: '',
      },
      rules: {
        platform: [{ required: true, message: '请选择平台', trigger: 'change' }],
        book_name: [{ required: true, message: '请填写图书名称', trigger: 'blur' }],
        recommend_type: [{ required: true, message: '请选择推荐类型', trigger: 'change' }],
        cover_url: [{ validator: validateCoverUrl, message: '请选择上传封面', trigger: 'change' }],
        desc: [{ validator: validateBookDesc, message: '请填写简介', trigger: 'blur' }],
        list_category: [{ required: true, message: '请选择书单类型', trigger: 'change' }],
        content_category: [{ required: true, message: '请选择内容类型', trigger: 'change' }],

      },
      platformList: [],
      recommendTypeList: ['爆款','力荐','最新'],
      listCategoryList: ['男频','女频','漫画'],
      contentCategoryList: ['玄幻','都市','历史','古言','现代','耽美'],
    }
  },
  mounted(){
    if (this.user_role !== 1) {
      return
    }
    this.getPlatforms()
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async getPlatforms () {
      const rsp = await request.get('/api/platforms')
      this.platformList = rsp.data.platforms
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        let timeRange = []
        for (let t of this.formSearch.time_range) {
          timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
        }
        const rsp = await request.get('/api/book-list', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
          time_range: timeRange.join(','),
        })
        this.loading = false
        this.dataSource = [].concat(rsp.data.list)
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    deleteBook(initial = {}) {
      let that = this;
      Modal.confirm({
        title: '确认删除？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            const rsp = await request.delete('/api/book-list/'+initial.id)
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    openBookDia(initial=null) {
      if(initial) {
        this.currentData = {...initial}
        this.formAdd = {...initial}
      } else {
        this.currentData = null
        this.formAdd = {}
      }
      this.currentData = initial ? {...initial} : null
      this.openDia = true
    },
    handleConfirm() {
      this.$refs.formRef.validate()
      .then(async () => {
        if (!this.currentData) {
          const rsp = await request.post('/api/book-list', { ...this.formAdd })
          this.resetFresh()
        } else {
          const params = {...this.formAdd}
          delete params['id']
          const rsp = await request.put('/api/book-list/'+this.formAdd.id, params)
          this.resetFresh()
        }
      })
      .catch(error => {
        console.log('error', error);
      });
    },
    resetFresh() {
      this.resetForm()
      this.openDia = false;
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
    onChangeUploadChangeKeyword(e) {
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/book-list/import`, files[0] ).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
          this.uploadLoading = false
        }).catch(()=>this.uploadLoading = false)
        this.uploadChangeDirectorInput.value = null
      }
    },
    async handleChangeStatus(value, item, index) {
      try {
        const rsp = await request.put(`/api/book-list/${item.id}`, { status: value })
        this.handleTableChange()
      } catch(err) {
        this.dataSource[index].status = status===0?1:0
      }
    },
    handleUploadBookList(e){
       if (e.key == "1") {
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/IeY4sLwBhhPD3dtKapGcKGVzn5f")
        return
      }
      this.uploadBookListInput.click()
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      uploadBookListInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 100
        },
        {
          title: '图书',
          dataIndex: 'book_name',
          key: 'book_name',
          width: 180
        },
        {
          title: '图书链接',
          dataIndex: 'book_url',
          key: 'book_url',
          width: 150
        },
        {
          title: '封面',
          dataIndex: 'cover_url',
          key: 'cover_url',
          slots: { customRender: 'cover_url' },
          width: 150
        },
        {
          title: '推荐类型',
          dataIndex: 'recommend_type',
          key: 'recommend_type',
          width: 150
        },
        {
          title: '简介',
          dataIndex: 'desc',
          key: 'desc',
          slots: { customRender: 'desc' },
          width: 180
        },
        {
          title: '书单类型',
          dataIndex: 'list_category',
          key: 'list_category',
          width: 150
        },
        {
          title: '内容类型',
          dataIndex: 'content_category',
          key: 'content_category',
          width: 150
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180
        },
        {
          title: '添加人',
          dataIndex: 'operator',
          key: 'operator',
          width: 150
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 150
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 150,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
<style>
.ant-modal.hotbook-model .ant-modal-body {
  max-height: 500px;
  overflow: auto;
}
</style>