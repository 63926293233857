<template>
  <a-modal v-model:visible="visible" title="关键词发布" :footer="null" >
   
      <a-form ref="formRef" :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
    
      <a-form-item   label="关键词">
          <a-input disabled v-model:value="formState.text" />
      </a-form-item>
      <a-form-item required label="发布平台">
          <a-select
          v-model:value="formState.publish_platform"
          :options="platformSuggestion"
          placeholder="平台"
          @change="onChangePublishPlatform"
        />
      </a-form-item>
      <a-form-item required label="视频类型">
          <a-select
          v-model:value="formState.artwork_type"
          :options="artworkSuggestion"
          placeholder="媒体类型"
        />
      </a-form-item>
      <a-form-item  required label="推广截图" v-if="formState.promotion_type=='直播'||formState.promotion_type=='私域'">
        <UploadMultiple v-model:imageUrl="formState.image_url"  />
      </a-form-item>
      <a-form-item  required label="发布链接" v-else>
          <a-input placeholder="http(s)://"  v-model:value="formState.publish_url"/>
      </a-form-item>
      <a-form-item  label="发布账号">
          <a-select
          v-model:value="account_name"
          placeholder="发布平台的账号"
          :options="accountSuggestion"
          @change="onChangeAccount"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
          <a-button type="primary" html-type="submit" >发布</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import UploadMultiple from '@/components/UploadMultiple.vue'
import  { toRefs} from 'vue';
import request from '@/utils/request'
import { message } from 'ant-design-vue';

const initialForm = {
      text: '',
      publish_platform: '',
      artwork_type: '',
      publish_url: '',
      account_id: '',
      id: '',// keyword_id
      blogger_id: 0,
      blogger_account_id: '',
      _publish_date: null,
      image_url: [],
    }

export default defineComponent({
  props: {
    record: Object
  },
  components: {
    UploadMultiple,
  },
  data(){
    return {
      formState: {
        ...initialForm,
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      platformSuggestion: [
        {
          value: "抖音",
        },
        {
          value: "快手",
        },
        {
          value: "小红书",
        },
        {
          value: "B站",
        },
        {
          value: "微博",
        },
        {
          value: "其他",
        },
      ],
      accountSuggestion: [],
      account_name: '',
      artworkSuggestion: [
        {
          value: "小说",
        },
        {
          value: "漫画",
        },
        {
          value: "壁纸头像",
        },
        {
          value: "动漫漫画",
        },
        {
          value: "影视",
        },
        {
          value: "其他",
        },
      ]
     
    }
  },
  watch:{
    record: function(newrecord, o){
      this.formState= {
        ...initialForm
      }
      console.log('newrecord', newrecord,this.formState);
      this.formState.text = newrecord.text
      this.formState.id = newrecord.id
      this.formState.blogger_id = newrecord.blogger_id
      this.formState.promotion_type = newrecord.promotion_type
      this.accountSuggestion = []

      
      this.account_name = ''
      // 编辑
      if (this.record.publish_id > 0) {
        this.formState = {
          ...this.record,
          publish_platform: this.record.published_platform,
          publish_url: this.record.published_url,
          image_url: this.record.images || []

        }
        this.account_name = this.record.blogger_account
        if (this.accountSuggestion.length == 0){
          this.searchAccounts({
            blogger_id: this.record.blogger_id
          })
        }}
    }
  },
  methods: {
    onChangePublishPlatform(){
      this.searchAccounts({
        blogger_id: this.record.blogger_id
      })
      this.formState.blogger_account_id = ''
      this.account_name = ''
    },
    async searchAccounts (query = {}) {
      this.loading = true
      const rsp = await request.get(`/api/bloggers/${query.blogger_id}/accounts`, {
        platform: this.formState.publish_platform,
        limit: 100
      })
      this.loading = false
      this.accountSuggestion = []
      for(let item of rsp.data.list) {
        this.accountSuggestion.push({
          id: item.id,
          value: `${item.account_name}_${item.platform}(${item.account_id})`
        })
      }
      
      return rsp.data
    },
    onChangeAccount(text, opt) {
      this.formState.blogger_account_id = opt.id
    },
    async onSubmit(){
      console.log(this.formState)
      
      // !this.formState.publish_url
      // || 
      if (!this.formState.publish_platform 
      || !this.formState.artwork_type) {
        return message.error("请填写必填信息")
      }
      if ((this.formState.promotion_type!='直播'&&this.formState.promotion_type!='私域') && !this.formState.publish_url) {
        return message.error("请填写发布链接")
      }
      if ((this.formState.promotion_type=='直播'||this.formState.promotion_type=='私域') && (this.formState.image_url && !this.formState.image_url.length)) {
        return message.error("请上传推广截图")
      }
      console.log('this.formState.image_url', this.formState.image_url);
      let payload = {
        ...this.formState,
        // publish_date: this.formState._publish_date.format('YYYY-MM-DD')
      }
      for (let k in payload) {
        if (typeof payload[k] == 'string') {
          payload[k] = payload[k].trim()
        }
        payload[k] = `${payload[k]}`
      }

      payload['images'] = this.formState.promotion_type!='公域视频' ? this.formState.image_url : []
      if (this.formState.publish_id > 0) {
        await request.put(`/api/keywords/-/published/${this.record.publish_id}`, payload)
      } else {
        await request.post(`/api/keywords/${this.record.id}/published`, payload)
      }
      
      this.$emit("ok")
    },
    onCancle () {
      this.$emit("cancel", false)
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    return { 
      visible
    };
  },
});
</script>