
import { Options, Vue } from 'vue-class-component';
import Navi from '../components/m/Navi.vue';
import {useStore} from 'vuex'
import {reactive} from 'vue'
import request from '../utils/request'
@Options({
  components: {
      Navi
  },
  props: {
    msg: String
  },
  data(){
    const store = useStore()
     return reactive({
      collapsed: false,
      user_id: store.state.user.user_id,
    })
  },
  computed:{
    username: () => {
      const store = useStore()
      return store.state.user.username
    }
  },
  methods: {
    handleLogout(){
        request.get(`/api/users/logout`).then(() => {
          location.href="/mobile/login"
        }).catch(() => {
          location.href="/mobile/login"
        })
      }
  },
  setup(){
   
    return {
      
    }
  }
})
export default class HelloWorld extends Vue {
  msg!: string
}
