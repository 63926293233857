
import { Options, Vue } from 'vue-class-component';
import Navi from '../components/Navi.vue';
import {useStore} from 'vuex'
import {reactive} from 'vue'
import request from '../utils/request'
import {UserRoleMap} from '@/utils/macro.js'
@Options({
  components: {
      Navi
  },
  props: {
    msg: String
  },
  data(){
    const store = useStore()
     return reactive({
      collapsed: false,
      user_id: store.state.user.user_id,
    })
  },
  computed:{
    username: () => {
      const store = useStore()
      return store.state.user.username
    },
    role: () => {
      const store = useStore()
      let roleNum = store.state.user.role
      let label = store.state.user.label
      console.log('roleNum', roleNum, );
      return roleNum==3&&label=='core'?'[核心团长]':roleNum==2&&label=='leader'?'[商务主管 Leader]':` [${UserRoleMap[roleNum]}]`
      // return UserRoleMap(roleNum)
    }
  },
  methods: {
    handleLogout(){
        request.get(`/api/users/logout`).then(() => {
          location.href="/"
        })
      }
  },
  setup(){
   
    return {
      UserRoleMap
    }
  }
})
export default class HelloWorld extends Vue {
  msg!: string
}
