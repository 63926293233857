<template>
  <a-card title="普通团长用户管理">
    <a-space  style="margin-bottom:10px" >
      <a-button  type='primary' @click="showUserEditor('new')" >新建用户</a-button>
    </a-space>
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    :scroll="{ x: true }"
    >
    </a-table>

  </a-card>

  <UserCreateDirector v-model:visible="userEditorVisible"  @cancel="userEditorVisible = false" @ok="handleUserEditorSuccess" :record="userEditorInitial"  />

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import UserCreateDirector from '@/components/UserCreateDirector.vue'
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal,message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment'



export default defineComponent({
  components: {
    UserCreateDirector,
  },
  data(){
    return {
      UserRoleMap,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        username: '',
        role: '0',
        status: '',
      },

      userEditorInitial: {
        username: 'test',
        password: '',
        blogger_name: '',
        bloggers: [],
        role: '3',
        remark: '',
        status: 0,
        id: 0,
        form_type: 'new'
      },
      
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
     
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/director_core/sub/directors', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        ...this.formSearch,
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    handleUserEditorSuccess: function() {
      this.userEditorVisible = false
      this.handleTableChange()
    },
    showUserEditor: function(type, initial = {}) {
      this.userEditorVisible = true
      this.userEditorInitial = reactive({
        form_type: type,
        // ...initial,
      })
    },
    showErrorModal(fails) {
      if(fails && fails.length > 0 ){
        let vnode = []
        for (let o of fails) {
          vnode.push(<div>{o}</div>)
        }
        Modal.error({
          title: "发生错误",
          content: () => <div >{vnode}</div>,
        });
      }
    },
  },
  setup() {
    const userEditorVisible = ref(false);


    return {
      moment,
      userEditorVisible,
      StatusMap: UserStatusMap,
      uploadDaifaInput: null,
      columns: [
        {
          title: '用户 ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username',
          width: 150,
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>