<template>
  <a-card title="秘籍管理">
    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;"   >
        <a-form-item label="标题">
          <a-input placeholder="标题" v-model:value="formSearch.title" />
        </a-form-item>

        <a-form-item label="秘籍分类">
          <a-select
            v-model:value="formSearch.category"
            @change="handleTableChange"
            style="width: 120px"
            :allowClear="true"
          >
            <a-select-option v-for="item in categoriesList" :value="item.category" :key="item.category">{{item.category}}</a-select-option>
          </a-select>
        </a-form-item>
       
        <!-- <a-form-item label="时间">
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item> -->

        <a-form-item>
          <a-button type="primary" @click="handleTableChange" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>

    </a-space>
    <a-row style="margin-bottom:10px"><a-button type="primary" @click="openCreateDia(null)">新增</a-button></a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="false"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="openCreateDia(record)" >编辑</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="deleteSecret(record)" >删除</a-button>
        </span>
      </template>
    </a-table>
  </a-card>


  <a-modal v-model:visible="openDia" :title="!currentData?'新增':'编辑'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" :label-col="{ style: { width: '100px' } }" >
        <a-form-item label="秘籍分类" name="category">
          <a-select v-model:value="formAdd.category" style="width: 100%">
            <a-select-option v-for="item in categoriesList" :value="item.category" :key="item.category">{{item.category}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="标题" name="title">
          <a-input placeholder="标题" v-model:value="formAdd.title" />
        </a-form-item>
        <a-form-item label="类型" name="type">
          <a-radio-group v-model:value="formAdd.type" :options="plainOptions" />
        </a-form-item>
        <a-form-item label="图片" name="image_url" v-if="formAdd.type==='图片'">
          <Upload v-model:imageUrl="formAdd.image_url"  />
        </a-form-item>
        <a-form-item label="视频" name="video_url" v-if="formAdd.type==='视频'">
          <UploadVideo v-model:videoUrl="formAdd.video_url"  />
        </a-form-item>
        <a-form-item label="链接" name="redirect_url" v-if="formAdd.type==='链接'">
          <a-input placeholder="链接" v-model:value="formAdd.redirect_url" />
        </a-form-item>
        
      </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import Upload from './../../components/Upload.vue'
import UploadVideo from './../../components/UploadVideo.vue'



export default defineComponent({
  components: {
    SearchOutlined,
    Upload,
    UploadVideo,
  },
  data(){
    return {
      UserRoleMap,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      formSearch: {
        title: '',
        time_range: '',
        category: '',
      },
      openDia: false,
      confirmLoading: false,
      currentData: null,
      fileList: [],
      loadingImage: false,
      imageUrl: '',
      formAdd: {
        title: '',
        redirect_url: '',
        image_url: '',
        category: '',
        video_url: '',
        video_cover_url: '',
        type: '图片',
      },
      rules: {
        category: [{ required: true, message: '请选择类型', trigger: 'change' }]
      },
      categoriesList: [{"category":"引导方式"},{"category":"爆款案例"},{"category":"素材"},{"category":"教程"}],
      plainOptions: ['图片', '视频', '链接']
    }
  },
  mounted(){
    if (this.user_role !== 1) {
      return
    }
    this.getCategories()
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async getCategories() {
      // const rsp = await request.get('/api/secret-book-categories')
      // console.log(rsp);
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      const rsp = await request.get('/api/secret-books', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        ...this.formSearch,
        time_range: timeRange.join(','),
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.secret_books)
      this.noticeMessage = `共 ${rsp.data.secret_books.length} 条`
      }catch(err){
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    deleteSecret(initial = {}) {
      console.log(initial);
      let that = this;
      Modal.confirm({
        title: '确认删除？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            const rsp = await request.delete('/api/secret-books/'+initial.id, {
              status: initial.status,
            })
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    openCreateDia(initial=null) {
      if(initial) {
        this.currentData = {...initial}
        this.formAdd = {...initial}
        if (initial.image_url) this.formAdd.type='图片'
        else if (initial.video_url) this.formAdd.type='视频'
        else if (initial.redirect_url) this.formAdd.type='链接'
      } else {
        this.currentData = null
        this.formAdd = {}
        this.formAdd.type='图片'
      }
      this.currentData = initial ? {...initial} : null
      this.openDia = true
    },
    handleConfirm() {
      this.$refs.formRef.validate()
      .then(async () => {
        const params = {
          title: this.formAdd.title,
          category: this.formAdd.category,
        }
        if (this.formAdd.type==='图片') {
          params['image_url'] = this.formAdd.image_url
        } else if (this.formAdd.type==='视频') {
          params['video_url'] = this.formAdd.video_url
        } else if (this.formAdd.type==='链接') {
          params['redirect_url'] = this.formAdd.redirect_url
        }
        console.log(params);
        if (!this.currentData) {
          const rsp = await request.post('/api/secret-books', params)
          this.resetFresh()
        } else {
          const rsp = await request.put('/api/secret-books/'+this.formAdd.id, params)
          this.resetFresh()
        }
      })
      .catch(error => {
        console.log('error', error);
      });
    },
    resetFresh() {
      this.resetForm()
      this.openDia = false;
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '类型',
          dataIndex: 'category',
          key: 'category',
        },
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: '发布时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '添加人',
          dataIndex: 'operator',
          key: 'operator',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>