<template>

  <a-card title="小程序推广统计">
   
    <a-space>
     <a-form :model="formSearch" :label-col=" { span: 6 }" :wrapper-col="{ span: 12}" style="margin: 10px auto;" >
        <a-form-item v-if="user_role != 4" label="博主" >
          <a-select
            v-model:value="formSearch.user_id"
            :options="bloggerSuggestion"
            @search="searchBloggers"
            @change="onChangeBloggers"
            mode="multiple"
            placeholder="博主名称"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>

        <a-form-item v-if="user_role < 3" label="商务">
            <a-select
            v-model:value="formSearch.director_id"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="商务名称"
           >
          </a-select>
        </a-form-item>
        <a-form-item label="推广任务" >
          <a-select
            v-model:value="formSearch.content_id"
            @search="searchContents"
            @change="onChangeContents"
            mode="multiple"
            placeholder="推广任务"
            style="width: 200px"
          >
            <a-select-option :value='item.content_id' v-for='item of contents' :key='item.content_id' >
              {{item.name}} ({{ item.platform }})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="小程序" >
          <a-select
            v-model:value="formSearch.applet_id"
            placeholder="小程序"
            style="width: 200px"
          >
            <a-select-option :value='item.applet_id' v-for='item of applets' :key='item.applet_id' >
              {{item.name}}
            </a-select-option>
          </a-select>
          {{ formSearch.applet_id }}
        </a-form-item>
         <a-form-item label="发布平台" >
          <a-select
            v-model:value="formSearch.platform"
            placeholder="发布平台"
            style="width: 200px"
          >
            <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
              {{i}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="统计日期" >
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      
        
      <a-form-item>
        <a-space>
          <a-button
            type="primary"
            @click="search()"
          >
          搜索
          </a-button>
          <a-button
            type="primary"
            @click="handleExport"
          >
          导出数据
          </a-button>
        </a-space>
      </a-form-item>
     
    </a-form>
    </a-space>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => `${record.keyword_id}+${record.stats_date}`"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="search"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >

       <template #created_at="{ record }" >
        <span >{{moment(record.created_at * 1000).format('YYYY-DD-MM HH:mm:ss')}}</span>
      </template>
    </a-table>
  </a-card>

</template>
<script >
import { defineComponent, ref, reactive, watch} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import { Modal } from 'ant-design-vue';
import {UserRoleMap, KeywordStatusMap, PlatformEnum} from '@/utils/macro'
import _ from 'lodash'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'

let _searchBloggerDebounce = null 
let _searchContentDebounce = null
let _searchDirectorDebounce = null

let pageTypeTitle = {
  "manage": "关键词列表",
  "apply": '关键词申请',
  'stats': '关键词数据'
}

export default defineComponent({
  components: {},
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      pageType: "manage",
      pageTitle: "关键词列表",

      loading: false,
      dataSource: [],
      applets: [],
      contents: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        content_id: [],
        user_id: [],
        applet_id: '',
        director_id: [],
        platform: '',
        time_range: [],
      },

      bloggerSuggestion: [],
      keywordsSuggestion: [],
      directorSuggestion: [],

    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  mounted(){
    if (this.user_role != 4) {
      this.searchBloggers()
    }
    this.searchApplets()
    this.searchContents()
    this.searchDirectors()
    this.search()
  },
  methods: {
    handleExport(e){
      let querys = []
      let queryParam = this.getQuery({
        limit: 9999999,
      })
      for (let k in queryParam) {
        querys.push(`${k}=${encodeURIComponent(queryParam[k])}`)
      }
      let q =  querys.join('&')
      window.open( `/api/stats/keyword_daily?export=csv&${q}`)
      return
    },
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch.director_id = [this.formSearch.director_id[0]]
        return false
      }
    },
    searchApplets(){
      request.get(`/api/admin/applets`, {}).then((rsp) => {
          this.applets = []
          for (let v of rsp.data.list) {
            this.applets.push({
              name: v.name,
              applet_id: v.applet_id
            })
          }
        })
    },
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              label: v.username,
              value: v.id
            })
          }
        })
      }, 200)
    },

    onChangeBloggers(item, options){
     if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch.user_id = [this.formSearch.user_id[0]]
        return
      }
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 300)
      
    },
     searchContents(name) {
       clearTimeout(_searchContentDebounce)
       _searchContentDebounce = setTimeout(() => {
          request.get(`/api/admin/applets/contents`, {
            name: name,
          }).then((rsp) => {
            this.contents = []
            for (let v of rsp.data.list) {
              this.contents.push(v)
            }
          })
        }, 300)
    },
    onChangeContents(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch.content_id = [this.formSearch.content_id[0]]
        return
      }
    },
    getQuery(param){
      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      let ret =  {
        ...param,
        status: + this.keywordStatus,
        ...this.formSearch,
        time_range: timeRange.join(','),
      }
      if(this.formSearch.content_id && this.formSearch.content_id.length > 0) {
        ret['content_id'] = this.formSearch.content_id[0]
      }
      if(this.formSearch.user_id && this.formSearch.user_id.length > 0) {
        ret['user_id'] = this.formSearch.user_id[0]
      }
      if(this.formSearch.director_id && this.formSearch.director_id.length > 0) {
        ret['director_id'] = this.formSearch.director_id[0]
      }
      return ret
      
    },
    async search (pagi = {pageSize: 10, current: 1}) {
      this.loading = true

      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      const rsp = await request.get('/api/admin/applets/promotions', this.getQuery({
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
      }))
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };

    return {
      PlatformEnum: [
        "抖音", "快手"
      ],
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
      {
          title: '博主',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: '推广名称',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: '小程序',
          dataIndex: 'applet_name',
          key: 'applet_name',
        },
       
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
        },
         
         {
          title: '推广内容',
          dataIndex: 'content_name',
          key: 'content_name',
        },
        {
          title: '付费收益',
          dataIndex: 'profit',
          key: 'profit',
        },
        {
          title: '充值金额',
          dataIndex: 'recharge_amount',
          key: 'recharge_amount',
        },
        {
          title: '充值次数',
          dataIndex: 'recharge_count',
          key: 'recharge_count',
        },
        {
          title: '充值用户数',
          dataIndex: 'recharge_uv',
          key: 'recharge_uv',
        },
        {
          title: '商务',
          dataIndex: 'director_name',
          key: 'director_name',
        },
         {
          title: '日期',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
        }
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>