<template>
  <a-card title="付款明细">
    <div>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" :label-col="{ style: { width: '80px' } }">
        <a-row :gutter="24" style="width:100%">
          <a-col :span="6">
            <a-form-item label="ID" >
              <a-input placeholder="付款明细ID" v-model:value="formSearch.payorder_id" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="收入明细ID" >
              <a-input placeholder="收入明细ID" v-model:value="formSearch.payment_id" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="付款批次ID" >
              <a-input placeholder="付款批次ID" v-model:value="formSearch.batch_id" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="用户ID" >
              <a-input placeholder="收款人的用户ID" v-model:value="formSearch.user_id" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row  style="width:100%;margin-top:10px">
          <a-col :span="6">
            <a-form-item label="付款名称" >
              <a-input placeholder="付款名称" v-model:value="formSearch.title" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="产品">
              <a-select
                  v-model:value="formSearch.platform"
                  :options="platformsSuggestion"
                  placeholder="产品"
                  style="width:150px"
                >
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="税源地" >
              <a-input placeholder="税源地" v-model:value="formSearch.tax_area" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="上传日期" >
              <a-range-picker v-model:value="formSearch.date_range" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-button
                type="primary"
                @click="searchQuery"
                shape="circle"
              >
              <template #icon><SearchOutlined /></template>
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="handleExport">
        <template #icon>
          <DownloadOutlined />
        </template>
        导出全部
      </a-button>
    </a-row>
      <a-tabs type="card" v-model:activeKey="formSearch.payment_status" @change="handleChangeTab">
        <a-tab-pane key="" :tab="'全部'"></a-tab-pane>
        <a-tab-pane :tab="`${key}(${statusCounts[value] || 0})`" v-for="(key,value) in PaymentTransactionStatusMap" :key="value"></a-tab-pane>
      </a-tabs>

    </div>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #expandedRowRender="{ record,index }">
        <div v-if="index>0">
          <div>税源地：{{ record.tax_area }}</div>
          <div>银行名：{{ record.bank_name }}</div>
          <div>账号：{{ record.payee_account }}</div>
          <div>姓名：{{ record.id_name }}</div>
          <div>身份证：{{ record.id_card }}</div>
          <div>手机号：{{ record.mobile }}</div>
          <div>收入明细ID：{{ record.payment_id }}</div>
          <div>开始日期：{{ record.start_date }}</div>
          <div>结束日期：{{ record.end_date }}</div>

        </div>
      </template>
      <template #audit_status="{ record }" >
        <span>{{ PaymentApplyAuditStatusMap[record.audit_status] }}</span>
      </template>
      <template #payment_status="{ record,index }" >
        <a-tag v-if="index>0" :color="PaymentTransactionStatusColorMap[record.payment_status]">{{ PaymentTransactionStatusMap[record.payment_status] }}</a-tag>
      </template>
      <!-- payment_at -->
      <template #payment_at="{ record }" >
        <span >{{record.payment_at?moment(record.payment_at*1000).format('YYYY-MM-DD HH:mm:ss'):''}}</span>
      </template>
      <template #created_at="{ record }" >
        <span >{{record.created_at?moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss'):''}}</span>
      </template>
      <template #action="{ record,index }" >
        <a-button type="primary" v-if="index>0" size="small" @click="onCheckChannels(record)">查询渠道信息</a-button>
      </template>
    </a-table>

    <CreatePaymentModel v-model:visible="paymentModelShow"/>

    <a-modal v-model:visible="channelsOpen" width="600px" title="渠道信息详情"  @cancel="channelsOpen=false" @ok="channelsOpen=false">
      <div :loading="loadingDetails">
        <!-- <div>{{ JsonBigint.stringify(channelDetails, null, 2) }}</div> -->
        <!-- <div><pre style="white-space: pre-wrap;">{{ formatDetails(channelDetails) }}</pre></div> -->
        <div><pre style="white-space: pre-wrap;">{{ channelDetails }}</pre></div>
        <!-- <div>{{ channelDetails }}</div> -->
      </div>
      <template #footer>
        <a-button key="submit" type="primary" @click="channelsOpen=false">确认</a-button>
      </template>
    </a-modal>

  </a-card>

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined,DownloadOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap, PaymentApplyAuditStatusMap, PaymentTransactionStatusMap, PaymentTransactionStatusColorMap, PlatformSug } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import CreatePaymentModel from './components/CreatePaymentModel.vue'
// import JsonBigint from 'json-bigint';
// import JSONBeautify from 'json-beautify';

const renderContent = ({ text, index }) => {
  const obj = {
    children: text,
    props: {},
  };
  if (index === 0) {
    obj.props.colSpan = 0;
  }
  return obj;
};

export default defineComponent({
  components: {
    SearchOutlined,CreatePaymentModel
  },
  data(){
    return {
      UserRoleMap,
      PaymentApplyAuditStatusMap,
      PaymentTransactionStatusMap,
      PaymentTransactionStatusColorMap,
      platformsSuggestion: [].concat(PlatformSug),
      loading: false,
      paymentModelShow: false,
      uploadLoading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 50,
        // 'show-size-changer': true,
        pageSizeOptions: ['10', '50', '100', '200']
      },
      noticeMessage: '',
      formSearch: {
        user_id: '',
        date_range: '',
        payment_status: '',
      },
      channelsOpen: false,
      channelDetails: {},
      loadingDetails: false,
      statusCounts: {},
      totalAmount: {},
    }
  },
  mounted(){
    if (this.$route.query.batch_id) {
      this.formSearch.batch_id = this.$route.query.batch_id
    }
    if (this.$route.query.payment_id) {
      this.formSearch.payment_id = this.$route.query.payment_id
    }
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    // formattedJson() {
    //   // 使用 json-format
    //   return JsonBigint(this.channelDetails, null, 2);

    //   // 或者使用 json-beautify
    //   // return JSONBeautify(this.jsonData, null, 2);
    // }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 50, current: 1}) {
      this.loading = true
      try {
        let date_range = ''
        if (this.formSearch.date_range && this.formSearch.date_range.length) {
          date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        }
        const rsp = await request.get('/api/payments/orders', {
          offset: (pagi.current - 1) * pagi.pageSize - 1,
          limit: pagi.pageSize,
          ...this.formSearch,
          date_range,
        })
        this.loading = false
        this.dataSource = rsp.data.list? [{id: '总计',...rsp.data.total_amounts,amount:rsp.data.total_amounts.payorder_amount}].concat(rsp.data.list) : []
        this.statusCounts = rsp.data.status_counts
        this.totalAmount = rsp.data.total_amount
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize+1
      } catch(err) {
        this.loading = false
      }
    },
    // formatDetails(json) {
    //   // return JsonBigint(json, null, 2);
    //   console.log(JSONBeautify(json, null, 2));
    //   return JSONBeautify(json, null, 2);
    // },
    handleExport() {
      let date_range = ''
      if (this.formSearch.date_range && this.formSearch.date_range.length) {
        date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
      }
      let params={
        ...this.formSearch,
        date_range,
      }
      let urlParams = ''
      for(let key in params) {
        console.log(params, key);
        urlParams += '&'+key+'='+params[key]
      }
      window.open(`/api/payments/orders?is_export=1${urlParams}`)
      return
      // window.open(`/api/payments/orders?isExport=1`)
      // return
    },
    handleChangeTab() {
      this.handleTableChange()
    },
    searchQuery() {
      this.handleTableChange()
    },
    async onCheckChannels(record) {
      console.log(record);
      this.channelsOpen = true
      // channelDetails
      this.loadingDetails = true
      this.channelDetails = ''
      try {
        const rsp = await request.get(`/api/payments/orders/${record.id}/payinfo`)
        console.log('onCheckChannels',rsp.data);
        this.channelDetails = rsp.data
        this.loadingDetails = false
      } catch(err) {
        this.loadingDetails = false
      }
    }
  },
  setup() {
    

    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '付款ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '收入ID',
          dataIndex: 'payment_id',
          key: 'payment_id',
          width: 100,
        },
        {
          title: '产品',
          dataIndex: 'platform',
          key: 'platform',
          width: 130,
        },
        {
          title: '博主名',
          dataIndex: 'username',
          key: 'username',
          width: 120,
        },
        {
          title: '收款账号',
          dataIndex: 'payee_account',
          key: 'payee_account',
          width: 120,
        },
        {
          title: '收款户名',
          dataIndex: 'id_name',
          key: 'id_name',
          width: 120,
        },
        {
          title: '身份证号',
          dataIndex: 'id_card',
          key: 'id_card',
          width: 120,
        },
        {
          title: '联系电话',
          dataIndex: 'mobile',
          key: 'mobile',
          width: 120,
        },
        {
          title: '预付金额',
          dataIndex: 'amount',
          key: 'amount',
          width: 120,
        },
        {
          title: '结算金额',
          dataIndex: 'paid_amount',
          key: 'paid_amount',
          width: 120,
        },
        {
          title: '结算服务费',
          dataIndex: 'paid_commission',
          key: 'paid_commission',
          width: 120,
        },
        {
          title: '实际付款金额',
          dataIndex: 'paid_total',
          key: 'paid_total',
          width: 120,
        },
        {
          title: '平台',
          dataIndex: 'payment_platform',
          key: 'payment_platform',
          width: 120,
        },
        {
          title: '税源地',
          dataIndex: 'tax_area',
          key: 'tax_area',
          width: 120,
        },
        {
          title: '付款状态',
          dataIndex: 'payment_status',
          key: 'payment_status',
          slots: { customRender: 'payment_status' },
          width: 120,
        },
        {
          title: '失败原因',
          dataIndex: 'remark',
          key: 'remark',
          width: 120,
        },
        // {
        //   title: '收入明细ID',
        //   dataIndex: 'payment_id',
        //   key: 'payment_id',
        //   width: 120,
        // },
        // {
        //   title: '开始日期',
        //   dataIndex: 'start_date',
        //   key: 'start_date',
        //   width: 120,
        // },
        // {
        //   title: '结束日期',
        //   dataIndex: 'end_date',
        //   key: 'end_date',
        //   width: 120,
        // },
        // {
        //   title: '金额',
        //   dataIndex: 'amount',
        //   key: 'amount',
        //   width: 120,
        // },
        // {
        //   title: '付款平台',
        //   dataIndex: 'payment_platform',
        //   key: 'payment_platform',
        //   width: 120,
        // },
        // {
        //   title: '申请人',
        //   dataIndex: 'applicant',
        //   key: 'applicant',
        //   width: 120,
        // },
        {
          title: '付款完成时间',
          dataIndex: 'payment_at',
          key: 'payment_at',
          slots: { customRender: 'payment_at' },
          width: 170,
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'created_at',
        //   key: 'created_at',
        //   slots: { customRender: 'created_at' },
        //   width: 170,
        // },
        // {
        //   title: '付款名称',
        //   dataIndex: 'title',
        //   key: 'title',
        //   width: 120,
        // },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 120,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped lang="less">
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.count-num-table {
  margin-bottom: 10px;
  td {
    border: 1px solid #ddd;
    min-width:120px;
    text-align: center;
  }
}
</style>