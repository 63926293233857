<template>
  <a-card title="UC会员码管理">
    <a-tabs v-model:activeKey="activeKey" type="card" @change="handleTableChange({pageSize: 10, current: 1})">
      <a-tab-pane :key="0" tab="待开通" />
      <a-tab-pane :key="1" tab="已开通" />
    </a-tabs>
    <a-space   >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="账号">
          <a-select
            v-model:value="formSearch._blogger_ids"
            :options="bloggerSuggestion"
            @search="searchBloggers"
            @change="onChangeBloggers"
            mode="multiple"
            placeholder="博主名称"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange({pageSize: 10, current: 1})"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="handleExport" v-show="activeKey===0">导出</a-button>
      <a-button type="primary" @click="handleImport" v-show="activeKey===1">导入</a-button>
      <!-- <a v-show="activeKey===1" style="margin-left:10px;padding-top:5px" href="https://wwbkbm5zi2g.feishu.cn/sheets/RHhds1M02hrcXutCZY9cMYgpnif" target="_blank">会员码上传模版</a> -->
      <a-spin v-if="uploadLoading" />
      <input ref='uploadInput' style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeFile"/>
    </a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #mobile="{ record }">
        <span>{{ record.mobile.slice(0, 3) + '***' + record.mobile.slice(-4) }}</span>
      </template>
      <template #status="{ record }">
        <span>{{ record.status===1?'已开通':'待开通' }}</span>
      </template>
      <template #code="{ record }">
        <span>{{ record.code.slice(0, 5) + '***' }}</span>
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #activated_at="{ record }" >
        <span >{{record.activated_at===0?'-':moment(record.activated_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
    </a-table>
  </a-card>

</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';


let _searchBloggerDebounce = null 

export default defineComponent({
  components: {
    SearchOutlined
  },
  data(){
    return {
      bloggerSuggestion: [],
      UserRoleMap,
      activeKey: 0,
      loading: false,
      uploadLoading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        username: '',
      },
      codeTypeList: [
        {type: 1, name: 'UC会员'},
        {type: 2, name: '夸克1个月'},
        {type: 3, name: '夸克3个月'},
        {type: 4, name: '夸克12个月'},
        {type: 5, name: '迅雷1个月'},
        {type: 6, name: '迅雷3个月'},
      ]
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      console.log(this.formSearch);
      const rsp = await request.get('/api/vip_code/records', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        code_type: 1,
        status: this.activeKey,
        user_id: this.formSearch.blogger_ids
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    formatCodeType(type) {
      return this.codeTypeList.find(v=>v.type===type).name
    },
    handleExport() {
      window.open(`/api/vip_code/records?is_export=1&code_type=1&status=0`)
      return
    },
    handleImport() {
      this.$refs.uploadInput.click()
    },
    async onChangeFile(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/vip_code/activate`, files[0] ).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
          this.uploadLoading = false
        })
      }
    },
    onChangeBloggers(item, options){
    if (options.length > 1) {
      message.error("只能选择一项")
      this.formSearch._blogger_ids = [this.formSearch._blogger_ids[0]]
      return false
    }
     let ids = []
     for(let v of options) {
       ids.push(v.id)
     }
     this.formSearch.blogger_ids = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id,
                name: v.username,
              })
            }
          })
        }, 600)
      
    },
  },
  setup() {
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '提交人账号',
          dataIndex: 'username',
          key: 'username',
          width: 180,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          slots: { customRender: 'mobile' },
          width: 130,
        },
        // {
        //   title: '会员码',
        //   dataIndex: 'code',
        //   key: 'code',
        //   slots: { customRender: 'code' },
        //   width: 130,
        // },
        {
          title: '提交时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 120,
        },
        {
          title: '开通时间',
          dataIndex: 'activated_at',
          key: 'activated_at',
          slots: { customRender: 'activated_at' },
          width: 180,
        },
        // {
        //   title: '操作',
        //   key: 'action',
        //   fixed: 'right',
        //   width: 200,
        //   slots: { customRender: 'action' },
        // },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>