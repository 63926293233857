<template>
  <a-card title="裂变活动">
    <a-tabs v-model:activeKey="activeKey" type="card" @change="searchQuery">
      <a-tab-pane :key="0" tab="邀请" />
      <a-tab-pane :key="1" tab="拉新" />
    </a-tabs>
    <a-space   >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="邀请人">
          <a-select
            v-model:value="formSearch._user_id"
            :options="bloggerSuggestion"
            @search="searchBloggers"
            @change="onChangeBloggers"
            mode="multiple"
            placeholder="邀请博主"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>
        <a-form-item label="受邀博主">
          <a-select
            v-model:value="formSearch._invited_user_id"
            :options="bloggerSuggestionInvited"
            @search="searchBloggersInvited"
            @change="onChangeInvitedUser"
            mode="multiple"
            placeholder="受邀博主"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>
        <a-form-item label="日期" >
          <a-range-picker
            v-model:value="formSearch.invite_date"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="searchQuery"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-row style="margin-bottom:10px" v-if="activeKey==1">
      <a-button type="primary" @click="handleExport">导出</a-button>
    </a-row>
    <template v-if="activeKey==0">
      <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

      <a-table 
      class="ant-table-striped"
      :row-key="record => record.id"
      :data-source="dataSource" 
      :columns="columns" 
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :scroll="{ x: 800 }"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #is_active="{ record }">
          <span>{{ record.is_active?'已激活':'未激活' }}</span>
        </template>
      </a-table>
    </template>
    <template v-if="activeKey==1">
      <a-alert :message="noticeMessageStats" type="info" show-icon style="text-align: left;"  />
      <a-table 
      class="ant-table-striped"
      :row-key="record => record.id"
      :data-source="dataSourceStats" 
      :columns="columnsStats" 
      :pagination="paginationStats"
      :loading="loading"
      @change="handleTableStatsChange"
      :scroll="{ x: 800 }"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #invited_at="{ record }">
          <span>{{ moment(record.invited_at*1000).format('YYYY-MM-DD') }}</span>
        </template>
      </a-table>
    </template>
  </a-card>

</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';


let _searchBloggerDebounce = null 

export default defineComponent({
  components: {
    SearchOutlined
  },
  data(){
    return {
      bloggerSuggestion: [],
      bloggerSuggestionInvited: [],
      UserRoleMap,
      activeKey: 0,
      loading: false,
      uploadLoading: false,
      dataSource: [],
      dataSourceStats: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      paginationStats: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      noticeMessageStats: '',
      formSearch: {
        invited_user_id: '',
        user_id: '',
        invite_date: '',
      },
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        let invite_date = ''
        if (this.formSearch.invite_date && this.formSearch.invite_date.length) {
          invite_date = moment(this.formSearch.invite_date[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.invite_date[1]).format('YYYY-MM-DD')
        }
        const rsp = await request.get('/api/invitation/bloggers', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
          invite_date
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    async handleTableStatsChange(pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/invitation/blogger_stats', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch
        })
        this.loading = false
        this.dataSourceStats = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessageStats = `共 ${rsp.data.pagination.total} 条`
        this.paginationStats.total = rsp.data.pagination.total
        this.paginationStats.current = pagi.current
        this.paginationStats.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    handleExport() {
      window.open(`/api/invitation/blogger_stats?is_export=1`)
      return
    },
    onChangeBloggers(item, options){
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.user_id = ids.join(',')
    },
    onChangeInvitedUser(item, options){
     let ids = []
     for(let v of options) {
       ids.push(v.id)
     }
     this.formSearch.invited_user_id = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id,
                name: v.username,
              })
            }
          })
        }, 600)
      
    },
    searchBloggersInvited(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/invitation/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestionInvited = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestionInvited.push({
                  value,
                id: v.id,
                name: v.username,
              })
            }
          })
        }, 600)
      
    },
    searchQuery() {
      if (this.activeKey==1) {
        this.handleTableStatsChange()
      } else {
        this.handleTableChange()
      }
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };

    return {
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '邀请人',
          dataIndex: 'invitation_username',
          key: 'invitation_username',
          width: 180,
        },
        {
          title: '受邀博主',
          dataIndex: 'invited_username',
          key: 'invited_username',
          width: 130,
        },
        {
          title: '受邀博主状态',
          dataIndex: 'is_active',
          key: 'is_active',
          slots: { customRender: 'is_active' },
          width: 120,
        },
        {
          title: '邀请时间',
          dataIndex: 'invited_date',
          key: 'invited_date',
          width: 180,
        },
        {
          title: '累计拉新',
          dataIndex: 'sum_stats',
          key: 'sum_stats',
          width: 180,
        },
      ],
      columnsStats: [
        {
          title: '邀请人',
          dataIndex: 'invitation_username',
          key: 'invitation_username',
          width: 180,
        },
        {
          title: '受邀博主',
          dataIndex: 'invited_username',
          key: 'invited_username',
          width: 130,
        },
        {
          title: '邀请时间',
          dataIndex: 'invited_at',
          key: 'invited_at',
          slots: { customRender: 'invited_at' },
          width: 180,
        },
        {
          title: '拉新日期',
          dataIndex: 'stats_date',
          key: 'stats_date',
          width: 180,
        },
        {
          title: '拉新量',
          dataIndex: 'stats_count',
          key: 'stats_count',
          width: 180,
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>