<template>
  <a-card title="主管汇总数据">


    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="商务主管 Leader" v-if="user_role==1">
          <a-select
            v-model:value="formSearch._manager_leader_ids"
            :options="managerLeaderSuggestion"
            @search="searchManagerLeaders"
            @change="onChangeManagerLeaders"
            mode="multiple"
            placeholder="商务主管 Leader"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="主管名称">
          <a-select
            v-model:value="formSearch._director_manager_ids"
            :options="directorManagerSuggestion"
            @search="searchDirectorManagers"
            @change="onChangeDirectorManagers"
            mode="multiple"
            placeholder="主管名称"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="openDetailDia(record)" >博主新增汇总</a-button>
        </span>
      </template>
    </a-table>


    <BloggerAddDia v-model:visible="bloggerAddVisible"  @cancel="bloggerAddVisible = false" :record="bloggerAddInitial" />


  </a-card>

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons-vue';
import BloggerAddDia from './components/BloggerAddDia.vue'

let _searchDirectorDebounce = null
let _searchmanagerLeaderDebounce = null 

export default defineComponent({
  components: {
    SearchOutlined,
    BloggerAddDia,
  },
  data(){
    return {
      bloggerAddVisible: false,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      bloggerAddInitial: {},
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      directorManagerSuggestion: [],
      formSearch: {},
      managerLeaderSuggestion: [],
    }
  },
  mounted(){
    if (this.user_role!=1) {
      this.handleTableChange()
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      if (this.user_role==1&&!this.formSearch.manager_leader_id) {
        return  message.error("请选择商务主管 Leader")
      }
      this.loading = true
      try {
        const rsp = await request.get('/api/manager_leader/sub/summary', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    resetFresh() {
      this.handleTableChange()
    },
    openDetailDia(record) {
      console.log(record);
      this.bloggerAddVisible = true
      this.bloggerAddInitial = {...record,manager_leader_id:this.formSearch.manager_leader_id}
    },
    onChangeDirectorManagers(item, options){
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_manager_ids = ids.join(',')
    },
    searchDirectorManagers(name) {
      if (this.user_role==1&&!this.formSearch.manager_leader_id) {
        return  message.error("请选择商务主管 Leader")
      }
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/manager_leader/bound/managers`, {
          username: name,
          manager_leader_id: this.formSearch.manager_leader_id
        }).then((rsp) => {
          this.directorManagerSuggestion = []
          console.log('rsp', rsp);
          for (let v of rsp.data.list) {
            this.directorManagerSuggestion.push({
              value: v.manager_name,
              id: v.manager_id
            })
          }
        })

      }, 200)
    },
    onChangeManagerLeaders(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._manager_leader_ids = [this.formSearch._manager_leader_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.manager_leader_id = ids.join(',')
    },
    searchManagerLeaders(name) {
      clearTimeout(_searchmanagerLeaderDebounce)
      _searchmanagerLeaderDebounce =  setTimeout(() => {
        request.get(`/api/manager_leader/suggestion`, {
          username: name
        }).then((rsp) => {
          this.managerLeaderSuggestion = []
          for (let v of rsp.data.list) {
            this.managerLeaderSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        {
          title: '商务主管名称',
          dataIndex: 'manager_name',
          key: 'manager_name',
          width: 200,
        },
        {
          title: '核心商务数量',
          dataIndex: 'director_core_count',
          key: 'director_core_count',
          width: 120,
        },
        {
          title: '普通商务数量',
          dataIndex: 'director_common_count',
          key: 'director_common_count',
          width: 120,
        },
        {
          title: '博主数量',
          dataIndex: 'blogger_count',
          key: 'blogger_count',
          width: 180,
        },
        {
          title: '拉新数量',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>