<template>
  <a-card title="税源地配置">

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="false"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :show-summary="true"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #action="{ record }" >
        <a-button type="primary" size="small" @click="onEdit(record)">编辑</a-button> 
      </template>
      <template #is_activated="{ record }">
        <a-tag :color="record.is_activated?'success':'default'">{{ record.is_activated?'已启用':'未启用' }}</a-tag>
      </template>
    </a-table>

    <EditPaymentPlatform v-model:visible="editModelShow" :record="recordData" :platforms="taxAreaList" @cancel="handleRefresh" @ok="handleRefresh"/>

  </a-card>

</template>
<script>
import request from '@/utils/request'
import {  UserRoleOperator, UserStatusMap } from '@/utils/macro'
import { useStore } from 'vuex'
import moment from 'moment';
import EditPaymentPlatform from './components/EditPaymentPlatform.vue'

export default {
  components: {
    EditPaymentPlatform
  },
  data(){
    return {
      loading: false,
      editModelShow: false,
      dataSource: [],
      noticeMessage: '',
      recordData: {},
      taxAreaList: []
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
  },
  methods: {
    moment,
    async handleTableChange () {
      this.loading = true
      try {
        const rsp = await request.get('/api/payments/platforms', {
        })
        this.loading = false
        this.dataSource = rsp.data.platforms? [].concat(rsp.data.platforms) : []
        this.taxAreaList = rsp.data.tax_areas
      } catch(err) {
        this.loading = false
      }
    },
    onEdit(record) {
      this.editModelShow = true
      this.recordData = {...record}
    },
    handleRefresh() {
      this.editModelShow = false
      this.handleTableChange()
    },
  },
  setup() {
    return {
      UserRoleOperator,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '关键词产品',
          dataIndex: 'platform',
          key: 'platform',
        },
        {
          title: '付款税源地',
          dataIndex: 'tax_area',
          key: 'tax_area',
        },
        {
          title: '付款平台',
          dataIndex: 'payment_platform',
          key: 'payment_platform',
        },
        {
          title: '启用状态',
          dataIndex: 'is_activated',
          key: 'is_activated',
          slots: { customRender: 'is_activated' },
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 120,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
:deep .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 1px solid #f0f0f0;
}
:deep .ant-table-thead > tr > th {
  border-right: 1px solid #f0f0f0;
}
</style>
<style>
.hover-link:hover span{
  text-decoration: underline;
}
th.custom-header {
  text-align: center !important;
}
</style>