<template>
  <a-card title="付款批次">
    <div>
      <a-row>
        
        <a-col :span="24">
          <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" :label-col="{ style: { width: '80px' } }">
            <a-row :gutter="24" style="width:100%">
              <a-col :span="6">
                <a-form-item label="付款批次ID" >
                  <a-input placeholder="付款批次ID" v-model:value="formSearch.batch_id" @keyup.enter="searchQuery" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="付款名称" >
                  <a-input placeholder="付款名称" v-model:value="formSearch.title" @keyup.enter="searchQuery" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="税源地" >
                  <a-input placeholder="税源地" v-model:value="formSearch.tax_area" @keyup.enter="searchQuery" />
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item label="付款状态" >
                  <a-select
                    v-model:value="formSearch.payment_status"
                    placeholder="付款状态"
                    style="width:150px"
                    @change="searchQuery"
                    allowClear
                  >
                  <a-select-option :value='i' v-for='i of Object.keys(PaymentTransactionStatusMap)' :key='i' >
                    {{PaymentTransactionStatusMap[i]}}
                  </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row  style="width:100%;margin-top:10px">
              <a-col :span="7">
                <a-form-item label="产品">
                  <a-select
                      v-model:value="formSearch.platform"
                      :options="platformsSuggestion"
                      placeholder="产品"
                      style="width:150px"
                      @change="searchQuery"
                    >
                    </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="7">
                <a-form-item label="上传人 ID" >
                  <a-input placeholder="上传人 ID" @keyup.enter="searchQuery" v-model:value="formSearch.operator_id" />
                </a-form-item>
              </a-col>
              <a-col :span="7">
                <a-form-item label="上传日期" >
                  <a-range-picker v-model:value="formSearch.date_range" @change="searchQuery" />
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-button
                    type="primary"
                    @click="searchQuery"
                    shape="circle"
                  >
                  <template #icon><SearchOutlined /></template>
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-col>


    <a-row style="margin-bottom:10px;width:100%">
      <a-dropdown-button type="primary" style="margin-right:10px" v-if="[UserRoleOperator].indexOf(user_role) > -1">
        新建
        <template #overlay>
          <a-menu @click="handleUpdate">
            <a-menu-item key="1">上传文件</a-menu-item>
            <a-menu-item key="2">下载模板</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
      <a-button type="primary" @click="handleExport" style="margin-right:10px">
        <template #icon>
          <DownloadOutlined />
        </template>
        导出全部
      </a-button>

      <a-button type="primary" v-if="user_role==UserRoleOperator" @click="onAuditOperatorBatch">批量审核（运营）</a-button> 
      <a-button type="primary" v-if="user_role==UserRoleCaiwu" @click="onAuditOperatorBatch">批量审核（财务）</a-button> 
      

      <div style="position:absolute;right:24px">
        <a-radio-group v-model:value="amomuntType" button-style="solid">
          <a-radio-button :value="1">收入金额</a-radio-button>
          <a-radio-button :value="2">手续费</a-radio-button>
          <a-radio-button :value="3">总金额</a-radio-button>
        </a-radio-group>
      </div>
    </a-row>
    
        <a-col :span="24">
          <a-tabs type="card" v-model:activeKey="formSearch.payment_status" @change="handleChangeTab">
            <a-tab-pane key="" :tab="'全部'"></a-tab-pane>
            <a-tab-pane :tab="`${key}(${statusCounts[value] || 0})`" v-for="(key,value) in PaymentTransactionStatusMap" :key="value"></a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>

    </div>


    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :show-summary="true"
    :row-selection="rowSelection"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #payment_sum="{ record }" >
        <a-tooltip placement="top">
          <template #title>
            <div><span style="width:70px;display:inline-block;text-align:justify">实际金额：</span>{{ record.payment_sum }}</div>
            <div><span style="width:70px;display:inline-block;text-align:justify">手续费：</span>{{ record.payment_commission }}</div>
          </template>
          <div style="text-align: center;" v-if="amomuntType==3"><span style="cursor:pointer">{{ record.payment_sum_total }}</span></div>
        </a-tooltip>
        <div style="text-align: center;" v-if="amomuntType==1">{{ record.payment_sum }}</div>
        <div style="text-align: center;" v-if="amomuntType==2">{{ record.payment_commission }}</div>
      </template>
      <template #payment_method="{ record}" >
        <a-tag v-if="record.payment_method == '银行卡'" color="green">银行卡</a-tag>
        <a-tag v-else-if="record.payment_method == '支付宝'" color="#108ee9">支付宝</a-tag>
      </template>
      <template #payorder_sum="{ record }" >
        <a-tooltip placement="top">
          <template #title>
            <div><span style="width:70px;display:inline-block;text-align:justify">实际金额：</span>{{ record.payorder_sum }}</div>
            <div><span style="width:70px;display:inline-block;text-align:justify">手续费：</span>{{ record.payorder_commission }}</div>
          </template>
          <div style="text-align: center;" v-if="amomuntType==3"><span style="cursor:pointer">{{ record.payorder_sum_total }}</span></div>
        </a-tooltip>
        <div style="text-align: center;" v-if="amomuntType==1">{{ record.payorder_sum }}</div>
        <div style="text-align: center;" v-if="amomuntType==2">{{ record.payorder_commission }}</div>
      </template>
      <template #paid_sum="{ record }" >
        <a-tooltip placement="top">
          <template #title>
            <div><span style="width:70px;display:inline-block;text-align:justify">实际金额：</span>{{ record.paid_sum }}</div>
            <div><span style="width:70px;display:inline-block;text-align:justify">手续费：</span>{{ record.paid_commission }}</div>
          </template>
          <div style="text-align: center;" v-if="amomuntType==3"><span style="cursor:pointer">{{ record.paid_sum_total }}</span></div>
        </a-tooltip>
        <div style="text-align: center;" v-if="amomuntType==1">{{ record.paid_sum }}</div>
        <div style="text-align: center;" v-if="amomuntType==2">{{ record.paid_commission }}</div>
      </template>
      <template #remark="{ record }" >
        <div><pre style="white-space: pre-wrap;">{{ record.remark }}</pre></div>
      </template>
      <template #audit_status="{ record,index }" >
        <a-tag v-if="index>0" :color="PaymentApplyAuditStatusColorMap[record.audit_status]">{{ record.audit_status==1?record.operator==''&&record.auditor==''?'运营审核中':'财务审核中':PaymentApplyAuditStatusMap[record.audit_status] }}</a-tag>
      </template>
      <template #payment_status="{ record,index }" >
        <a-tag v-if="index>0" :color="PaymentTransactionStatusColorMap[record.payment_status]">{{ PaymentTransactionStatusMap[record.payment_status] }}</a-tag>
      </template>
      <template #payment_count="{ record }">
        <router-link :to="{name:'IncomesDetail',query:{batch_id:record.id}}" target="_blank" :class="record.payment_count!== record.payorder_count? 'new-hover-link' : 'hover-link'">{{ record.payment_count }}</router-link>
      </template>
      <template #payorder_count="{ record }">
        <router-link :to="{name:'PaymentDetail',query:{batch_id:record.id}}" target="_blank" class="hover-link">{{ record.payorder_count }}</router-link>
      </template>
      <template #paid_at="{ record }" >
        <span >{{record.paid_at?moment(record.paid_at*1000).format('YYYY-MM-DD HH:mm:ss'):''}}</span>
      </template>
      <template #created_at="{ record }" >
        <span >{{record.created_at?moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss'):''}}</span>
      </template>
      <template #action="{ record,index }" >
        <template v-if="index>0">
          <!-- 当付款批次是 失败、部分成功的时候，且 retry_count = 0 的时候，可以展示 重新付款的按钮~ -->
          <a-button type="primary" size="small" v-if="(record.payment_status==6||record.payment_status==7)&&user_role==UserRoleOperator&&!notSamePerson(record.applicant)&&record.retry_count==0" @click="onRetryPayment(record)">重新发起付款</a-button>
          <!-- <a-button type="primary" size="small" @click="onRetryPayment(record)">重新发起付款</a-button> -->
          

          <!-- 付款状态0已创建付款单 运营审核人和财务审核人都为空 角色为运营并且不是上传人 2: '付款单已创建', -->
          <a-button type="primary" size="small" v-if="record.payment_status==2&&record.operator==''&&record.finance_officer==''&&user_role==UserRoleOperator&&notSamePerson(record.applicant)" @click="onAuditOperator(record, 1)">运营审核</a-button>
          <!-- 付款状态0 运营审核人不为空，角色为财务  -->
          <a-button type="primary" size="small" v-if="record.payment_status==2&&record.operator!==''&&record.finance_officer==''&&payment_audit" @click="onAuditOperator(record, 2)">财务审核</a-button>
          <!-- 财务1已审核，财务2可提交付款 -->
          <!-- 财务账号：杨炎炎 41801 只开启财务审核功能，辛苦把可以提交付款的功能关一下， 辛苦小姐姐关一下前端提交付款的按钮(此账号没有提交付款权限) -->
          <!-- 财务账号：王绘园 41802 把这个账号下的财务审核按钮关一下，这个账号只用来提交付款就行 -->
          <template v-if="record.payment_status!=2&&notSamePerson(record.finance_officer)&&payment_submit">
              <!-- 付款状态5付款暂停4付款中 -> 可以终止付款  -->
              <a-button type="primary" danger size="small" v-if="record.payment_status==5&&record.payment_status==4" @click="onStopPayment(record)">终止付款</a-button>
              <!-- 付款状态3付款成功6付款失败4付款中7付款部分成功12已经拒绝的付款单 -> 不等于这几个状态可以提交付款  -->
              <a-button type="primary" size="small"  v-if="record.payment_status!=3&&record.payment_status!=4&&record.payment_status!=6&&record.payment_status!=7&&record.payment_status!=12" @click="onPayment(record)">提交付款</a-button>
          </template>
        </template>
      </template>

    </a-table>

    <CreatePaymentBatchesModel v-model:visible="paymentModelShow"  @cancel="handleRefresh" @ok="handleRefresh"/>
    <AuditBatchesModel v-model:visible="auditModelShow" :record="recordData" @cancel="handleRefreshPage" @ok="handleRefreshPage"/>
    <AuditBatchesModelMore v-model:visible="auditModelMoreShow" :record="auditMutipleData" @cancel="handleRefreshPage" @ok="handleRefreshPage" @clearSelection="selectedRowKeys=[]" />

  </a-card>

</template>
<script>
import { SearchOutlined,DownloadOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  payment_finance_audit,payment_finance_submit,UserRoleOperator, UserRoleCaiwu, UserRoleMap, UserStatusMap, PaymentApplyAuditStatusMap, PaymentApplyAuditStatusColorMap, PaymentTransactionStatusMap, PaymentTransactionStatusColorMap, PlatformSug } from '@/utils/macro'
import { Modal,message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import CreatePaymentBatchesModel from './components/CreatePaymentBatchesModel.vue'
import AuditBatchesModel from './components/AuditBatchesModel.vue'
import AuditBatchesModelMore from './components/AuditBatchesModelMore.vue'
import { createVNode } from 'vue';


export default {
  components: {
    SearchOutlined,DownloadOutlined,CreatePaymentBatchesModel,AuditBatchesModel,AuditBatchesModelMore
  },
  data(){
    return {
      UserRoleMap,
      PaymentApplyAuditStatusMap,
      PaymentApplyAuditStatusColorMap,
      PaymentTransactionStatusMap,
      PaymentTransactionStatusColorMap,
      platformsSuggestion: [].concat(PlatformSug),
      loading: false,
      paymentModelShow: false,
      auditModelShow: false,
      uploadLoading: false,
      auditModelMoreShow: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 50,
        // 'show-size-changer': true,
        pageSizeOptions: ['10', '50', '100', '200']
      },
      noticeMessage: '',
      formSearch: {
        operator_id: '',
        date_range: '',
        audit_status: '',
        payment_status: '',
      },
      recordData: {},
      statusCounts: {},
      amomuntType: 3,
      selectedRowKeys: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
        }
      },
      auditMutipleData: [],
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    username: () => {
      const store = useStore()
      return store.state.user.username
    },
    user_id: () => {
      const store = useStore()
      return store.state.user.user_id
    },
    payment_audit: () => {
      const store = useStore()
      return store.state.user.perms && store.state.user.perms.includes(payment_finance_audit)
    },
    payment_submit: () => {
      const store = useStore()
      return store.state.user.perms && store.state.user.perms.includes(payment_finance_submit)
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 50, current: 1}) {
      this.loading = true
      try {
        let date_range = ''
        if (this.formSearch.date_range && this.formSearch.date_range.length) {
          date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        }
        const rsp = await request.get('/api/payments/batches', {
          offset: (pagi.current - 1) * pagi.pageSize - 1,
          limit: pagi.pageSize,
          ...this.formSearch,
          date_range,
        })
        this.loading = false
        let totalAmounts = rsp.data.total_amounts
        let obj = {
            ... totalAmounts,
            payment_sum: totalAmounts.payment_amount,
            payment_sum_total: totalAmounts.payment_total,
            payorder_sum: totalAmounts.payorder_amount,
            payorder_sum_total: totalAmounts.payorder_total,
            paid_sum: totalAmounts.paid_amount,
            paid_sum_total: totalAmounts.paid_total,
          }
        this.dataSource = rsp.data.list? [{id: '总计',...obj}].concat(rsp.data.list) : []
        this.statusCounts = rsp.data.status_counts
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize+1
      } catch(err) {
        this.loading = false
      }
    },
    handleExport() {
      let date_range = ''
      if (this.formSearch.date_range && this.formSearch.date_range.length) {
        date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
      }
      let params={
        ...this.formSearch,
        date_range,
      }
      let urlParams = ''
      for(let key in params) {
        console.log(params, key);
        urlParams += '&'+key+'='+params[key]
      }
      window.open(`/api/payments/batches?is_export=1${urlParams}`)
      return
    },
    handleChangeTab() {
      this.handleTableChange()
    },
    searchQuery() {
      this.handleTableChange()
    },
    onAuditOperator(record, userType) {
      this.auditModelShow = true
      this.recordData = {...record, userType}
    },
    onAudit(record,index) {
      console.log(record,index);
      const that = this
      Modal.confirm({
        title: `提示`,
        icon: createVNode(ExclamationCircleOutlined),
        content: `是否确认提交 ${record.id} 的付款审核?`,
        onOk() {
          return new Promise((resolve, reject) => {
            request.post(`/api/payments/applications/${record.id}/audit`).then((rsp) => {
              that.handleTableChange()
              resolve();
            }).catch(()=>reject())
          }).then(() => console.log('ok'))
            .catch(() => console.log('cancel'));
        },
      });
    },
    onPayment(record) {
      const that = this
      Modal.confirm({
        title: `提示`,
        icon: createVNode(ExclamationCircleOutlined),
        content: `是否确认提交 ${record.id} 的付款?`,
        onOk() {
          return new Promise((resolve, reject) => {
            request.post(`/api/payments/batches/${record.id}/payment`).then((rsp) => {
              that.handleTableChange(that.pagination)
              resolve();
            }).catch(()=>reject())
          }).then(() => console.log('ok'))
            .catch(() => console.log('cancel'));
        },
      });
    },
    onStopPayment(record) {
      const that = this
      Modal.confirm({
        title: `提示`,
        icon: createVNode(ExclamationCircleOutlined),
        content: `是否确认终止 ${record.title} 的付款?`,
        onOk() {
          return new Promise((resolve, reject) => {
            request.post(`/api/payments/${record.id}/stop`).then((rsp) => {
              that.handleTableChange(that.pagination)
              resolve();
            }).catch(()=>reject())
          }).then(() => console.log('ok'))
            .catch(() => console.log('cancel'));
        },
        onCancel() {
          console.log();
        },
      });
    },
    onRetryPayment(record) {
      const that = this
      Modal.confirm({
        title: `提示`,
        icon: createVNode(ExclamationCircleOutlined),
        content: `是否确认重新发起“ ${record.title} ”的付款?`,
        onOk() {
          return new Promise((resolve, reject) => {
            request.post(`/api/payments/batches/${record.id}/resubmit`).then((rsp) => {
              that.handleTableChange(that.pagination)
              resolve();
            }).catch(()=>reject())
          }).then(() => console.log('ok'))
            .catch(() => console.log('cancel'));
        },
        onCancel() {
          console.log();
        },
      });
    },
    handleRefresh() {
      this.paymentModelShow = false
      this.handleTableChange()
    },
    handleRefreshPage() {
      this.auditModelShow = false
      this.auditModelMoreShow = false
      this.handleTableChange(this.pagination)
    },
    handleUpdate(e) {
      if (e.key == "1") { // 上传文件
        this.paymentModelShow = true
      } else if (e.key=='2') { // 下载模板
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/BcI3s4SiGhrDt8tlCKMc6o3anMh")
      }
    },
    notSamePerson(person) {
      if (person) {
        let name = person.split(' ')[1]
        return this.username !== name
      } 
      return true
    },
    onAuditOperatorBatch() {
      let list = this.selectedRowKeys.filter(v=>v!=='总计')
      if(!list.length) {
        return message.error('请选择')
      }
      this.auditMutipleData = list
      this.auditModelMoreShow = true
      // return
      // const that = this
      // Modal.confirm({
      //   title: `提示`,
      //   icon: createVNode(ExclamationCircleOutlined),
      //   content: `是否确认通过对选中的 ${list.length} 条数据的审核?`,
      //   onOk() {
      //     that.loading = true
      //     that.fetchAllData(list).then(res=>{
      //       message.error('已完成审核')
      //       that.handleTableChange(that.pagination)
      //       that.loading = false
      //     })
      //     .catch(error => {
      //         console.error("请求出错:", error);
      //         that.loading = false
      //     });
      //   },
      //   onCancel() {console.error()},
      // });
    },
    // async fetchData(item) {
    //     return new Promise((resolve, reject) => {
    //       request.post(`/api/payments/batches/${item}/audit/accept`).then(() => {resolve()}).catch(()=>reject())
    //     });
    // },
    // async fetchAllData(items) {
    //   const promises = items.map(item => this.fetchData(item));
    //   const results = await Promise.all(promises);
    //   return results;
    // },
  },
  setup() {
    return {
      UserRoleOperator,
      UserRoleCaiwu,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '付款名称',
          dataIndex: 'title',
          key: 'title',
          width: 180,
        },
        {
          title: '收款方式',
          dataIndex: 'payment_method',
          key: 'payment_method',
          slots: { customRender: 'payment_method' },
          width: 100,
        },
        // {
        //   title: '审核状态',
        //   dataIndex: 'audit_status',
        //   key: 'audit_status',
        //   slots: { customRender: 'audit_status' },
        //   width: 100,
        // },
        {
          title: '收入明细数量',
          dataIndex: 'payment_count',
          key: 'payment_count',
          slots: { customRender: 'payment_count' },
          width: 120,
        },
        {
          title: '付款明细数量',
          dataIndex: 'payorder_count',
          key: 'payorder_count',
          slots: { customRender: 'payorder_count' },
          width: 120,
        },
        {
          title: '结算总额',
          dataIndex: 'payment_sum',
          key: 'payment_sum',
          slots: { title: 'paymentTitle', customRender: 'payment_sum' },
          className: 'custom-header',
          width: 150,
          // children: [
          //   {
          //     title: '实际金额',
          //     dataIndex: 'payment_sum',
          //     key: 'payment_sum',
          //     className: 'column-money',
          //     width: 120,
          //   },
          //   {
          //     title: '手续费',
          //     dataIndex: 'payment_commission',
          //     key: 'payment_commission',
          //     className: 'column-money',
          //     width: 120,
          //   },
          //   {
          //     title: '付款总额',
          //     dataIndex: 'payment_sum_total',
          //     key: 'payment_sum_total',
          //     className: 'column-total',
          //     width: 120,
          //   },
          // ],
        },
        {
          title: '拆分总额',
          dataIndex: 'payorder_sum',
          key: 'payorder_sum',
          className: 'custom-header',
          slots: { title: 'payorderTitle', customRender: 'payorder_sum' },
          width: 150,
          // children: [
          //   {
          //     title: '实际金额',
          //     dataIndex: 'payorder_sum',
          //     key: 'payorder_sum',
          //     className: 'column-money',
          //     width: 120,
          //   },
          //   {
          //     title: '手续费',
          //     dataIndex: 'payorder_commission',
          //     key: 'payorder_commission',
          //     className: 'column-money',
          //     width: 120,
          //   },
          //   {
          //     title: '付款总额',
          //     dataIndex: 'payorder_sum_total',
          //     key: 'payorder_sum_total',
          //     className: 'column-total',
          //     width: 120,
          //   },
          // ],
        },
        {
          title: '已付款金额',
          dataIndex: 'paid_sum',
          key: 'paid_sum',
          className: 'custom-header',
          slots: { title: 'paidTitle', customRender: 'paid_sum' },
          width: 150,
          // children: [
          //   {
          //     title: '实际金额',
          //     dataIndex: 'paid_sum',
          //     key: 'paid_sum',
          //     className: 'column-money',
          //     width: 120,
          //   },
          //   {
          //     title: '手续费',
          //     dataIndex: 'paid_commission',
          //     key: 'paid_commission',
          //     className: 'column-money',
          //     width: 120,
          //   },
          //   {
          //     title: '付款总额',
          //     dataIndex: 'paid_sum_total',
          //     key: 'paid_sum_total',
          //     className: 'column-total',
          //     width: 120,
          //   },
          // ],
        },
        {
          title: '付款状态',
          dataIndex: 'payment_status',
          key: 'payment_status',
          slots: { customRender: 'payment_status' },
          width: 120,
        },
        {
          title: '产品',
          dataIndex: 'platform',
          key: 'platform',
          width: 130,
        },
        {
          title: '平台',
          dataIndex: 'payment_platform',
          key: 'payment_platform',
          width: 100,
        },
        {
          title: '付款税源地',
          dataIndex: 'tax_area',
          key: 'tax_area',
          width: 120,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          slots: { customRender: 'remark' },
          width: 120,
        },
        {
          title: '上传人',
          dataIndex: 'applicant',
          key: 'applicant',
          width: 120,
        },
        {
          title: '运营审核人',
          dataIndex: 'operator',
          key: 'operator',
          width: 120,
        },
        {
          title: '财务审核人',
          dataIndex: 'finance_officer',
          key: 'finance_officer',
          width: 120,
        },
        {
          title: '发起付款时间',
          dataIndex: 'paid_at',
          key: 'paid_at',
          slots: { customRender: 'paid_at' },
          width: 170,
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 170,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 150,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
:deep .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 1px solid #f0f0f0;
}
:deep .ant-table-thead > tr > th {
  border-right: 1px solid #f0f0f0;
}
</style>
<style>
.new-hover-link {
  color:red;
  font-weight: 700;
}
.hover-link:hover span{
  text-decoration: underline;
}
th.custom-header {
  text-align: center !important;
}
</style>