<template>
  <a-modal v-model:visible="visible" title="资源预览" >
    <div v-if="record.category==='text'">
      {{ record.text }}
    </div>
    <a-image
      v-if="record.category==='image'"
      :src="record.raw_url"
    />
    <div v-if="record.category==='video'">
      <video
        :src="record.raw_url"
        ref="video"
        style="width:100%;"
        controls
        preload="metadata"
        webkit-playsinline="true"
        playsinline="true"
        x-webkit-airplay="allow"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="false"
        x5-video-orientation="landscape"
        >
        抱歉，您的浏览器不支持内嵌视频!
      </video>
    </div>
      
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';

export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {}
  },
  methods: {
    moment,
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    
    return { 
      dataSource,
      visible,
    };
  },
});
</script>