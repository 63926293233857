<template>
  <a-card title="弹窗管理">
   
    <a-row style="margin-bottom:10px"><a-button type="primary" @click="openBannerDia(null)">新增</a-button></a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #image_url="{ record }" >
        <a-image
          :width="80"
          :src="record.image_url"
        />
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #redirect_url="{ record }" >
        <span v-if="record.redirect_url">/pages/activities/index?url={{record.redirect_url}}</span>
      </template>
      <template #popup_times="{ record }" >
        <span >{{record.popup_times==0?'每次':`每天${record.popup_times}次`}}</span>
      </template>
      <template #status="{ record, index }" >
        <a-switch v-model:checked="record.status" :checkedValue="1" :unCheckedValue="0" @change="handleChangeStatus($event, record, index)" />
      </template>
      <template #target_roles="{ record }" >
        <span v-if="record.target_roles">
          {{ filterTargetRole(record.target_roles) }}
        </span>
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="openBannerDia(record)" >编辑</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="deleteBanner(record)" >删除</a-button>
        </span>
      </template>
    </a-table>
  </a-card>


  <a-modal v-model:visible="openDia" :title="!currentData?'新增':'编辑'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" >
        <a-form-item label="标题" name="title">
          <a-input placeholder="标题" v-model:value="formAdd.title" />
        </a-form-item>
        <a-form-item label="内容图片" name="image_url">
          <Upload v-model:imageUrl="formAdd.image_url"  />
        </a-form-item>
        <a-form-item label="跳转链接">
          <!-- <a-input placeholder="跳转链接" v-model:value="formAdd.redirect_url" /> -->
          <a-input v-model:value="formAdd.redirect_url" addon-before="/pages/activities/index?url=" placeholder="请输入活动id" />
        </a-form-item>
        <a-form-item label="按钮文案">
          <a-input placeholder="按钮文案" v-model:value="formAdd.btn_text" />
        </a-form-item>
        <a-form-item label="弹窗频次">
          <!-- <a-input placeholder="弹窗频次" v-model:value="formAdd.popup_times" /> -->
          <a-input-number id="inputNumber" v-model:value="formAdd.popup_times" :min="0" :max="100" />
          （默认为0，表示每次都会弹窗）
        </a-form-item>
        <a-form-item label="接收角色" name="target_roles">
          <a-checkbox-group v-model:value="formAdd.target_roles" :options="UserRoleList" >
            <template #label="{label}">
              <span style="color: red">{{ label }}</span>
            </template>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="是否开启">
          <a-switch v-model:checked="formAdd.status" :checkedValue="1" :unCheckedValue="0" />
        </a-form-item>
        
      </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import Upload from './../../components/Upload.vue'


let UserRoleList = Object.keys(UserRoleMap).map(v=> {
  return {
    label: UserRoleMap[v],
    value: v
  }
})
export default defineComponent({
  components: {
    Upload,
  },
  data(){
    return {
      UserRoleList,
      UserRoleMap,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      openDia: false,
      confirmLoading: false,
      currentData: null,
      fileList: [],
      loadingImage: false,
      imageUrl: '',
      formAdd: {
        title: '',
        redirect_url: '',
        image_url: '',
        target_roles: []
      },
      rules: {
        // title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        image_url: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        target_roles: [{ type: 'array', required: true, message: '请选择接收角色', trigger: 'change' }],
      },
    }
  },
  mounted(){
    if (this.user_role !== 1) {
      return
    }
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    filterTargetRole(role) {
      if (!role) return
      return role.split(',').map(v=>{
        return UserRoleMap[v]
      }).join(',')
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        
        const rsp = await request.get('/api/dialogs', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
        })
        this.loading = false
        this.dataSource = [].concat(rsp.data.list || [])
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    deleteBanner(initial = {}) {
      console.log(initial);
      let that = this;
      Modal.confirm({
        title: '确认删除？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            const rsp = await request.delete('/api/dialogs/'+initial.id, {
              status: initial.status,
            })
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    openBannerDia(initial=null) {
      if(initial) {
        this.currentData = {...initial}
        this.formAdd = {...initial}
        this.formAdd.target_roles = initial.target_roles ? initial.target_roles.split(',') : []
      } else {
        this.currentData = null
        this.formAdd = {}
        this.formAdd.target_roles = ['0']
        this.formAdd.popup_times = 0
      }
      this.currentData = initial ? {...initial} : null
      this.openDia = true
    },
    handleConfirm() {
      this.$refs.formRef.validate()
      .then(async () => {
        if (this.formAdd.target_roles.length) {
          this.formAdd.target_roles = this.formAdd.target_roles.join(',')
        }
        // this.formAdd.popup_times = 0
        if (!this.currentData) {
          const rsp = await request.post('/api/dialogs', {
            ...this.formAdd,
          })
          this.resetFresh()
        } else {
          let params = {
            ...this.formAdd
          }
          delete params.id
          const rsp = await request.put('/api/dialogs/'+this.formAdd.id, params)
          this.resetFresh()
        }
      })
      .catch(error => {
        console.log('error', error);
      });
    },
    resetFresh() {
      this.resetForm()
      this.openDia = false;
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
    async handleChangeStatus(value, item, index) {
      try {
        const rsp = await request.put(`/api/dialogs/${item.id}/status`, { status: value })
        this.handleTableChange()
      } catch(err) {
        this.dataSource[index].status = value===0?1:0
      }
    }
  },
  setup() {
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          width: 120,
        },
        {
          title: '内容图片',
          dataIndex: 'image_url',
          key: 'image_url',
          slots: { customRender: 'image_url' },
          width: 120,
        },
        {
          title: '按钮文案',
          dataIndex: 'btn_text',
          key: 'btn_text',
          width: 120,
        },
        {
          title: '跳转链接',
          dataIndex: 'redirect_url',
          key: 'redirect_url',
          slots: { customRender: 'redirect_url' },
          width: 120,
        },
        {
          title: '接收角色',
          dataIndex: 'target_roles',
          key: 'target_roles',
          slots: { customRender: 'target_roles' },
          width: 200,
        },
        {
          title: '频次',
          dataIndex: 'popup_times',
          key: 'popup_times',
          slots: { customRender: 'popup_times' },
          width: 120,
        },
        {
          title: '开启/关闭',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 120,
        },
        {
          title: '发布时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '操作人',
          dataIndex: 'operator',
          key: 'operator',
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>