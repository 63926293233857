<template>

  <a-card title="博主维度投放数据">
   
    <a-space>
     <a-form :model="formSearch" :label-col=" { span: 4 }" :wrapper-col="{ span: 14}" style="margin: 10px auto;" >
        <a-form-item v-if="user_role != 4" label="博主" >
            <a-select
              v-model:value="formSearch._blogger_ids"
              :options="bloggerSuggestion"
              @search="searchBloggers"
              @change="onChangeBloggers"
              mode="multiple"
              placeholder="博主名称"
              style="width: 200px"
            >
            </a-select>
        </a-form-item>
        <a-form-item  label="商务">
            <a-select
            v-model:value="formSearch._director_ids"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="商务名称"
          />
        </a-form-item>
       <a-form-item label="平台" >
          <a-select
            v-model:value="formSearch.platform"
            placeholder="平台"
            style="width: 200px"
          >
            <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
              {{i}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="统计日期" >
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      
        
      <a-form-item>
        <a-space  align="center" style="margin: 10px;">
        <a-button
          type="primary"
          @click="handleTableChange()"
        >
         搜索
        </a-button>
        <a-button
            type="primary"
            @click="handelExport"
          >
          导出数据
          </a-button>
        </a-space>
       
      </a-form-item>
     
     
    </a-form>
    </a-space>
    
    <!-- <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  /> -->

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => `${record.blogger_name}`"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
     
    </a-table>
  </a-card>

</template>
<script >
import { defineComponent, ref, reactive, watch} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import {UserRoleMap, KeywordStatusMap, PlatformEnum} from '@/utils/macro'
import _ from 'lodash'


import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'

let _searchBloggerDebounce = null 
let _searchBooksDebounce = null
let _searchDirectorDebounce = null

let pageTypeTitle = {
  "manage": "关键词列表",
  "apply": '关键词申请',
  'stats': '关键词数据'
}

export default defineComponent({
  components: {

  },
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      pageType: "manage",
      pageTitle: "关键词列表",

      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        query: '',
        _blogger_ids: [],
        _book_ids: [],
        _director_ids: [],
        blogger_ids: '',
        book_ids: '',
        director_ids: '',
        platform: '',
        time_range: [],
      },

      bloggerSuggestion: [],
      booksSuggestion: [],
      directorSuggestion: [],

    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{
    keywordStatus: function (v, ov){
      this.handleTableChange()
    },
    $route: {
       handler(route,oldval){
        if (route.query.type) {
          this.pageType = route.query.type
        }
        this.pageTitle = pageTypeTitle[this.pageType]

      },
      // 深度观察监听
      deep: true
    },
    'formSearch.time_range': function (v, ov){
        this.handleTableChange()
    },
  },
  mounted(){
    const route = useRoute()
    if (route.query.type) {
      this.pageType = route.query.type
      this.pageTitle = pageTypeTitle[this.pageType]
    }

    this.searchBloggers()
    // this.searchDirectors()
    // this.handleTableChange()
  },
  methods: {
    onChangeDirectors(item, options){
      // if (options.length > 1) {
      //   message.error("只能选择一项")
      //   this.formSearch._director_ids = [this.formSearch._director_ids[0]]
      //   return false
      // }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_ids = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },

    onChangeBloggers(item, options){
     if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._blogger_ids = [this.formSearch._blogger_ids[0]]
        return
      }
     let ids = []
     for(let v of options) {
       ids.push(v.id)
     }
     this.formSearch.blogger_ids = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
        request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
    searchParam(pagi = {pageSize: 10, current: 1}){
       let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      return {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        blogger_id: this.formSearch.blogger_ids,
        director_id: this.formSearch.director_ids,
        platform: this.formSearch.platform,
        time_range: timeRange.join(','),
        ...pagi
      }
    },
     genQuery (param){
      let q = this.searchParam(param)
      let querys = []
      for (let k in q) {
        querys.push(`${k}=${encodeURIComponent(q[k])}`)
      }
      return querys.join('&')
    },
    handelExport(){
      window.open(`/api/stats/stats_blogger?` + this.genQuery({
        export: 1
      }))
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}, filters, sorter) {
      this.loading = true

      let sort = sorter?.order ? `${sorter.field} ${sorter.order.slice(0, -3)}` : ''
     
      const rsp = await request.get('/api/stats/stats_blogger', this.searchParam({...pagi, order_by: sort}))
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
    const timeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!timeRange.value || timeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(timeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        timeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      timeRange.value = val;
    };

    
    return {
      PlatformEnum,
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        
        {
          title: '博主名称',
          dataIndex: 'blogger_name',
          key: 'blogger_name',
        },
         {
          title: '拉新数',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
          slots: { customRender: 'stats_sum' },
          sorter: true,
        },
        {
          title: '费用收益',
          dataIndex: 'profit_sum',
          key: 'profit_sum',
        },
        {
          title: '其他数据',
          dataIndex: 'extra_data_sum',
          key: 'extra_data_sum',
        },
        {
          title: '群组拉新',
          dataIndex: 'group_growth_sum',
          key: 'group_growth_sum',
        },
        {
          title: '群组付费收益',
          dataIndex: 'group_fee_profit_sum',
          key: 'group_fee_profit_sum',
        },
        {
          title: '群组其他',
          dataIndex: 'group_other_sum',
          key: 'group_other_sum',
        },
         {
          title: '发布数',
          dataIndex: 'published_count',
          key: 'published_count',
          slots: { customRender: 'published_count' },
          sorter: true,
        },
        //  {
        //   title: '统计日期',
        //   dataIndex: 'stats_date',
        //   key: 'stats_date',
        // },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>