<template>

  <a-card title="平台拉新投放数据（汇总）">
   
    <a-space>
     <a-form :model="formSearch" :label-col=" { span: 4 }" :wrapper-col="{ span: 14}" style="margin: 10px auto;" >
       
       <a-form-item label="平台" >
          <a-select
            v-model:value="formSearch.platform"
            placeholder="平台"
            style="width: 200px"
          >
            <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
              {{i}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item  label="商务">
            <a-select
            v-model:value="formSearch._director_ids"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="商务名称"
          />
        </a-form-item>
        <a-form-item  label="商务主管">
            <a-select
            v-model:value="formSearch._director_manager_ids"
            :options="directorManagerSuggestion"
            @search="searchDirectorManagers"
            @change="onChangeDirectorManagers"
            mode="multiple"
            placeholder="商务名称"
          />
        </a-form-item>

        <a-form-item label="统计日期" >
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      
        
      <a-form-item>
        <a-button
          type="primary"
          @click="handleTableChange()"
        >
         搜索
        </a-button>
      </a-form-item>
     
    </a-form>
    </a-space>
    

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => record.director_name"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    ></a-table>
  </a-card>
</template>
<script >
import { defineComponent, ref} from 'vue'
import { message } from 'ant-design-vue';
import _ from 'lodash'
import { useStore } from 'vuex'
import moment from 'moment'

import request from '@/utils/request'
import {UserRoleMap, KeywordStatusMap, PlatformEnum} from '@/utils/macro'

let _searchDirectorDebounce = null

export default defineComponent({
  components: {
  },
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      pageType: "manage",
      pageTitle: "关键词列表",

      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        query: '',
        _blogger_ids: [],
        _book_ids: [],
        _director_ids: [],
        director_manager_ids: [],
        blogger_ids: '',
        book_ids: '',
        director_ids: '',
        platform: '',
        time_range: [],
      },

      bloggerSuggestion: [],
      booksSuggestion: [],
      directorSuggestion: [],
      directorManagerSuggestion: [],

      searchDirectorName: '',
      searchDirectorsDebouceFuncInstance: null,
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{
    'formSearch.time_range': function (v, ov){
        this.handleTableChange()
    },
  },
  mounted(){
    // this.handleTableChange()
  },
  methods: {
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_ids = [this.formSearch._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_ids = ids.join(',')
    },
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
    onChangeDirectorManagers(item, options){
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_manager_ids = ids.join(',')
    },
    
    searchDirectorManagers(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/director_managers`, {
          username: name
        }).then((rsp) => {
          this.directorManagerSuggestion = []
          for (let v of rsp.data.list) {
            this.directorManagerSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },

    async handleTableChange (pagi = {pageSize: 10, current: 1}, filters, sorter) {
      this.loading = true

      let sort = sorter?.order ? `${sorter.field} ${sorter.order.slice(0, -3)}` : ''

      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      const rsp = await request.get('/api/stats/stats_newer_platform_sum', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        director_ids: this.formSearch.director_ids,
        director_manager_ids: this.formSearch.director_manager_ids,
        platform: this.formSearch.platform,
        time_range: timeRange.join(','),
        order_by: sort
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
    const timeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!timeRange.value || timeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(timeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        timeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      timeRange.value = val;
    };


    return {
      PlatformEnum,
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
        },
         
         {
          title: '拉新数',
          dataIndex: 'sum_stats',
          key: 'sum_stats',
          sorter: true,
        }
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>