<template>
  <div>
    <a-row :span="24">
      <a-col :span="12">
        <a-upload 
          v-model:file-list="fileList"
          ref="UploadRef"
          :customRequest="handleUpload"
          :multiple="true"
          @change="handleChange"
          :beforeUpload="handleBeforeUpload"
          >
          <a-button><upload-outlined></upload-outlined>上传资源</a-button>
        </a-upload>
      </a-col>
      <a-col>
        <a-upload 
          v-model:file-list="fileListDirectory"
          ref="UploadFolderRef"
          style="margin-top:10px;"
          @change="handleChange"
          :customRequest="handleUpload"
          :beforeUpload="handleBeforeUpload"
          :multiple="true" directory>
          <a-button><upload-outlined></upload-outlined>上传资源文件夹</a-button>
        </a-upload>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap } from '@/utils/macro'
import { Modal, message, Upload } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import OSS from 'ali-oss'

export default defineComponent({
  components: {
    UploadOutlined,
  },
  data() {
    return {
      fileList: [],
      fileListDirectory: [],
    }
  },
  beforeUnmount() {
    this.fileList=[]
    this.fileListDirectory=[]
  },
  methods: {
    async handleUpload({file, onSuccess, onError,onProgress}) {
      if (!file) {
          message.error("请选择文件")
          return
      }
      this.loadingImage = true
      
      const sts = await request.post(`/api/aliyun/sts`, {})
      if (sts.error) {
        message.error(`存储服务授权失败:` + sts.error.message)
        return
      }
      const client =  new OSS({
          accessKeyId: sts.data.AccessKeyId,
          accessKeySecret: sts.data.AccessKeySecret,
          stsToken: sts.data.SecurityToken,
          // bucket: 'applet-promotion',
          bucket: 'keyword-resource',
          region: 'oss-cn-beijing'
      });
      let foldername = file.webkitRelativePath ? file.webkitRelativePath.split('/').slice(file.webkitRelativePath.split('/').length - 2).join('-'):''
      let newName = `${file.webkitRelativePath?foldername:file.name}`
      // newName = newName.replace('/', '-')
      const titleObj = await request.get(`/api/resources/meta?filename=${newName}`)
      const {filename,object,title} = titleObj.data

      const name = object
      const result = await client.multipartUpload(name, file, {
        progress(process) {
          let percent = Math.round((process*100))
          onProgress({percent},file)
        }
      });

      var url = "https://keyword-resource.oss-cn-beijing.aliyuncs.com/" // 待定
      // if (result && result.name) {
      //     url = url + result.name
      // }
      onSuccess()
      const params = {
        title: title,
        filename: `${filename}`,
        object: object,
        raw_url: url + object ,
        category: file.type.split('/')[0],
      }
      this.createResource(params, file.uid)
      this.loadingImage = false
    },
    async createResource(params, uid) {
      const rsp = await request.post('/api/resources', params)
      const index = this.fileList.findIndex(v=>v.uid==uid);
      if (index>-1) this.fileList[index]['sourceId'] = rsp.data.id
      const folderIndex = this.fileListDirectory.findIndex(v=>v.uid==uid);
      if (folderIndex>-1) this.fileListDirectory[folderIndex]['sourceId'] = rsp.data.id

      this.$emit('refresh')
    },
    handleBeforeUpload(file, fileList) {
      const maxSizeInBytes = 1024 * 1024 * 10;
      let totalSize = 0
      totalSize = fileList.reduce((accumulator, item) => {
        return accumulator + item.size
      }, 0)
      if (totalSize > maxSizeInBytes) {
        // 文件大小超过最大限制
        message.error('最大可上传100M')
        const index = fileList.indexOf(file);
        if (index !== -1) {
          fileList.splice(index, 1);
        }
        return false && Upload.LIST_IGNORE
      } else {
        // 文件大小合法，可以进行上传或其他操作
        return true
      }
      
    },
    handleChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        let ids = fileList.map(v=>v.sourceId)
      }
    }
  }
})
</script>
<style lang='scss' scoped>

</style>