import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

import BasicProfile from '../views/BasicProfile.vue'
import UserManage from '../views/UserManage.vue'
import BloggerManage from '../views/BloggerManage.vue'
import Account from '../views/Account.vue'
import UserAudit from '../views/UserAudit.vue'
import Platforms from '../views/Platforms.vue'
import HotBookLists from '../views/HotBookLists.vue'

import UserManageDirectorCore from '../views/UserManageDirectorCore.vue'

import BookManage from '../views/BookManage.vue'
import KeywordManage from '../views/KeywordManage.vue'
import KeywordBloggerStats from '../views/KeywordBloggerStats.vue'
import StatsKeyword from '../views/StatsKeyword.vue'
import StatsKeywordSum from '../views/StatsKeywordSum.vue'
import StatsDataBlogger from '../views/StatsDataBlogger.vue'
import StatsDataDirector from '../views/StatsDataDirector.vue'
import StatsDataDirectorManager from '../views/StatsDataDirectorManager.vue'
import StatsDataDirectorDaily from '../views/StatsDataDirectorDaily.vue'
import StatsNewerPlatform from '../views/StatsNewerPlatform.vue'
import StatsNewerPlatformSum from '../views/StatsNewerPlatformSum.vue'

import Dashboard from '../views/Dashboard.vue'
import AuditRecord from '../views/AuditRecord.vue'
import StatsDataUpload from '../views/StatsDataUpload.vue'

import MobileHome from '../views/m/Home.vue'
import MobileLogin from '../views/m/Login.vue'
import MobileProfile from '../views/m/Profile.vue'
import MobileKeyword from '../views/m/Keyword.vue'
import MobileKeywordEditor from '../views/m/KeywordEditor.vue'
import MobileStatsKeyword from '../views/m/StatsKeyword.vue'
import MobileUserManage from '../views/m/UserManage.vue'

import Notification from '../views/Notification.vue'
import OpNotification from '../views/OpNotification.vue'

import StatsAppletPromotion from '../views/StatsAppletPromotion.vue'

import Banner from '../views/operate/Banner.vue'
import Popups from '../views/operate/Popups.vue'
import Activity from '../views/operate/Activity.vue'
import Secret from '../views/operate/Secret.vue'
import Member from '../views/operate/Member.vue'
import UCMember from '../views/operate/UCMember.vue'
import Report from '../views/operate/Report.vue'
import Resource from '../views/operate/Resource.vue'
import Invitation from '../views/operate/Invitation.vue'

import PlatformIndex from './../views/config/Index.vue'
import PlatformIndexConfig from './../views/config/IndexConfig.vue'

import ResourcesManage from '../views/resources/Manage.vue'
import ResourcesPkgs from '../views/resources/Pkgs.vue'
import ResourcesLinks from '../views/resources/Links.vue'


import OrganizeLeader from '../views/organize/Leader.vue'
import OrganizeLeaderManager from '../views/organize/LeaderManager.vue'
import OrganizeDirector from '../views/organize/Director.vue'
import OrganizeDirectorSet from '../views/organize/DirectorSet.vue'
import OrganizeDirectorConfigure from '../views/organize/DirectorConfigure.vue'
import OrganizeLeaderSet from '../views/organize/LeaderSet.vue'
import OrganizeBlogger from '../views/organize/Blogger.vue'
import OrganizeStats from '../views/organize/Stats.vue'
import OrganizeConfigure from '../views/organize/Configure.vue'

import PaymentManage from '../views/payment/PaymentManage.vue'
import PaymentDetail from '../views/payment/PaymentDetail.vue'
import IncomesDetail from '../views/payment/IncomesDetail.vue'
import PaymentBatches from '../views/payment/PaymentBatches.vue'
import PaymentPlatforms from '../views/payment/PaymentPlatforms.vue'

import BankCards from '../views/payment/BankCards.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/applet',
    name: 'Applet',
    component: Home,
    children:[
      {
        path: 'stats',
        name: 'AppletStats',
        component: StatsAppletPromotion,
        meta: {
          key: 'applet'
        }
      }
    ]
  },
  {
    path: '/keywords',
    name: 'Keywords',
    component: Home,
    children: [
      {
        path: 'login',
        name: 'kLogin',
        component:Login
      },
      {
        path: 'profile',
        name: 'BasicProfile',
        component:BasicProfile,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'users',
        name: 'UserManage',
        component:UserManage,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'users_director_core',
        name: 'UserManageDirectorCore',
        component:UserManageDirectorCore,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'bloggers',
        name: 'BloggerManage',
        component:BloggerManage,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'accounts',
        name: 'Account',
        component:Account,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'bank_cards',
        name: 'BankCards',
        component:BankCards,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'books',
        name: 'BookManage',
        component:BookManage,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'resource',
        name: 'Resource',
        component: Resource,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'audit',
        name: 'UserAudit',
        component: UserAudit,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'platforms',
        name: 'Platforms',
        component: Platforms,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'hotBookLists',
        name: 'HotBookLists',
        component: HotBookLists,
        meta: {
          key: 'basic'
        }
      },
      {
        path: 'manage',
        name: 'KeywordManage',
        component:KeywordManage,
        meta: {
          key: 'keywords'
        }
      },
      {
        path: 'apply',
        name: 'KeywordApply',
        component:KeywordManage,
        meta: {
          key: 'keywords'
        }
      },
      {
        path: 'manage_audit',
        name: 'KeywordManageAudit',
        component:KeywordManage
      },
      {
        path: 'manage_keyword_stats',
        name: 'KeywordManageStats',
        component:KeywordManage
      },
      {
        path: 'manage_blogger_stats',
        name: 'KeywordBloggerStats',
        component:KeywordBloggerStats
      },
      {
        path: 'stats_keyword',
        name: 'StatsKeyword',
        component: StatsKeyword,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_keyword_sum',
        name: 'StatsKeywordSum',
        component: StatsKeywordSum,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_blogger',
        name: 'StatsDataBlogger',
        component: StatsDataBlogger,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_director_daily',
        name: 'StatsDataDirectorDaily',
        component: StatsDataDirectorDaily,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_director',
        name: 'StatsDataDirector',
        component: StatsDataDirector,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_director_manager',
        name: 'StatsDataDirectorManager',
        component: StatsDataDirectorManager,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_newer_platform',
        name: 'StatsNewerPlatform',
        component: StatsNewerPlatform,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_newer_platform_sum',
        name: 'StatsNewerPlatformSum',
        component: StatsNewerPlatformSum,
        meta: {
          key: 'stats'
        }
      },

      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'stats_data_upload',
        name: 'StatsDataUpload',
        component: StatsDataUpload,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'audit_record',
        name: 'AuditRecord',
        component: AuditRecord,
        meta: {
          key: 'stats'
        }
      },
      {
        path: 'notification',
        name: 'Notification',
        component: Notification,
        meta: {
          key: 'notification'
        }
      },
      {
        path: 'op_notification',
        name: 'OpNotification',
        component: OpNotification,
        meta: {
          key: 'notification'
        }
      },
      {
        path: 'banner',
        name: 'Banner',
        component: Banner,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'popups',
        name: 'Popups',
        component: Popups,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'activity',
        name: 'Activity',
        component: Activity,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'secret',
        name: 'Secret',
        component: Secret,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'member',
        name: 'Member',
        component: Member,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'ucmember',
        name: 'UCMember',
        component: UCMember,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'report',
        name: 'Report',
        component: Report,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'invitation',
        name: 'Invitation',
        component: Invitation,
        meta: {
          key: 'operate'
        }
      },
      {
        path: 'platform_index',
        name: 'PlatformIndex',
        component: PlatformIndex
      },
      {
        path: 'platform_config',
        name: 'PlatformIndexConfig',
        component: PlatformIndexConfig,
      },
      {
        path: 'director_core/director_set',
        name: 'OrganizeDirectorSet',
        component: OrganizeDirectorSet,
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'director_core/bound',
        name: 'OrganizeDirectorConfigure',
        component: OrganizeDirectorConfigure,
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'director_core/director',
        name: 'OrganizeDirector',
        component: OrganizeDirector,
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'director_core/stats',
        name: 'OrganizeStats',
        component: OrganizeStats,
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'director_core/blogger',
        name: 'OrganizeBlogger',
        component: OrganizeBlogger,
        meta: {
          key: 'relatives-director'
        }
      }, {
        path: 'manager_leader/leader_set',
        name: 'OrganizeLeaderSet',
        component: OrganizeLeaderSet,
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'manager_leader/bound',
        name: 'OrganizeConfigure',
        component: OrganizeConfigure,
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'manager_leader/summary',
        name: 'OrganizeLeader',
        component: OrganizeLeader,
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'manager_leader/director',
        name: 'OrganizeLeaderManager',
        component: OrganizeLeaderManager,
        meta: {
          key: 'relatives-leader'
        }
      }, {
        path: 'payment_platforms',
        name: 'PaymentPlatforms',
        component: PaymentPlatforms,
        meta: {
          key: 'payment'
        }
      }, {
        path: 'payment_manage',
        name: 'PaymentManage',
        component: PaymentManage,
        meta: {
          key: 'payment'
        }
      }, {
        path: 'incomes_detail',
        name: 'IncomesDetail',
        component: IncomesDetail,
        meta: {
          key: 'payment'
        }
      }, {
        path: 'payment_batches',
        name: 'PaymentBatches',
        component: PaymentBatches,
        meta: {
          key: 'payment'
        }
      }, {
        path: 'payment_detail',
        name: 'PaymentDetail',
        component: PaymentDetail,
        meta: {
          key: 'payment'
        }
      }
    ]
  },
  {
    path: '/mobile/login',
    name: 'MobileLogin',
    component: MobileLogin
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: MobileHome,
    children: [
    
      {
        path: 'profile',
        name: 'MobileProfile',
        component:MobileProfile
      },
      {
        path: 'keyword',
        name: 'MobileKeyword',
        component:MobileKeyword
      },
      {
        path: 'apply',
        name: 'MobileKeywordEditor',
        component:MobileKeywordEditor
      },
      {
        path: 'stats_keyword',
        name: 'MobileStatsKeyword',
        component:MobileStatsKeyword
      },
      {
        path: 'users',
        name: 'MobileUserManage',
        component:MobileUserManage
      },
    ]
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Home,
    children:[
      {
        path: 'manage',
        name: 'ResourcesManage',
        component: ResourcesManage,
        meta: {
          key: 'resources'
        }
      }, {
        path: 'pkgs',
        name: 'ResourcesPkgs',
        component: ResourcesPkgs,
        meta: {
          key: 'resources'
        }
      }, {
        path: 'links',
        name: 'ResourcesLinks',
        component: ResourcesLinks,
        meta: {
          key: 'resources'
        }
      }
    ]
  },
  // {
  //   path: '/organize',
  //   name: 'Organize',
  //   component: Home,
  //   children:[
  //     {
  //       path: 'director',
  //       name: 'OrganizeDirector',
  //       component: OrganizeDirector
  //     }, {
  //       path: 'blogger',
  //       name: 'OrganizeBlogger',
  //       component: OrganizeBlogger
  //     }, {
  //       path: 'stats',
  //       name: 'OrganizeStats',
  //       component: OrganizeStats
  //     }, {
  //       path: 'leader',
  //       name: 'OrganizeLeader',
  //       component: OrganizeLeader
  //     }, {
  //       path: 'leader_manager',
  //       name: 'OrganizeLeaderManager',
  //       component: OrganizeLeaderManager
  //     }, {
  //       path: 'configure',
  //       name: 'OrganizeConfigure',
  //       component: OrganizeConfigure
  //     }
  //   ]
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
