<template>
  <a-card title="资源管理">
    <a-space>
      <a-form layout="inline" :model="formSearch" @keyup.enter="handleTableChange" style="margin: 10px auto;"   >
        <a-form-item label="资源名称">
          <a-input placeholder="资源名称" v-model:value="formSearch.title" />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="handleTableChange" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>

    </a-space>
    <a-row style="margin-bottom:10px"><a-button type="primary" @click="openUploadDia">上传资源</a-button></a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #resourceTitle="{ record }" >
        <pre style="white-space: pre-wrap;">{{record.title}}</pre>
      </template>
      <template #status="{ record }" >
        <span >{{formatStatus(record.status)}}</span>
      </template>
      <template #category="{ record }" >
        <span >{{formatCategory(record.category)}}</span>
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="deleteBanner(record)" >删除</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="previewModel(record)" >预览</a-button>
        </span>
      </template>
    </a-table>
  </a-card>


  <a-modal v-model:visible="openDia" title="上传资源" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" >
        <a-form-item label="平台" name="platform">
          <a-select v-model:value="formAdd.platform"  :allowClear="true">
            <a-select-option v-for="(item, index) in platformList" :value="item.name" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="上传资源" v-show="formAdd.platform">
          <a-row :span="24">
            <a-col :span="12">
              <a-upload 
                v-model:file-list="fileList"
                ref="UploadRef"
                :customRequest="handleUpload"
                :multiple="true"
                >
                <a-button>
                  <upload-outlined></upload-outlined>
                  上传资源
                </a-button>
              </a-upload>
            </a-col>
            <a-col>
              <a-upload 
                v-model:file-list="fileListDirectory"
                ref="UploadFolderRef"
                style="margin-top:10px;"
                :customRequest="handleUpload"
                :multiple="true" directory>
                <a-button>
                  <upload-outlined></upload-outlined>
                  上传资源文件夹
                </a-button>
              </a-upload>
            </a-col>
          </a-row>
          

          

        </a-form-item>
      </a-form>
  </a-modal>

  <a-modal v-model:visible="previewOpen" title="预览" @ok="previewOpen=false">
      <a-image
        v-if="previewData.category==='image'"
        :src="previewData.media_url"
      />
      <div v-if="previewData.category==='video'">
        <video
          :src="previewData.media_url"
          ref="video"
          style="width:100%;"
          controls
          preload="metadata"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="false"
          x5-video-orientation="landscape"
          >
          抱歉，您的浏览器不支持内嵌视频!
        </video>
      </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap } from '@/utils/macro'
import { Modal, message, Upload } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import OSS from 'ali-oss'


export default defineComponent({
  components: {
    SearchOutlined,
    UploadOutlined,
  },
  data(){
    return {
      UserRoleMap,
      platformList: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      loading: false,
      dataSource: [],
      noticeMessage: '',
      formSearch: {
        title: '',
      },
      openDia: false,
      confirmLoading: false,
      currentData: null,
      fileList: [],
      fileListDirectory: [],
      loadingImage: false,
      imageUrl: '',
      formAdd: {
        platform: '',
      },
      rules: {
        platform: [{ required: true, message: '请选择平台', trigger: 'change' }],
      },
      previewData: {},
      previewOpen: false,
    }
  },
  mounted(){
    if (this.user_role !== 1) {
      return
    }
    this.handleTableChange()
    this.getPlatforms()
  },
  filter: {
// 1 已上传
// 2 转码中
// 3 转码失败
// 4 转码成功
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    formatCategory(category) {
      let res = category
      switch(res){
        case 'video':
          res = '视频';
          break;
        case 'image':
          res = '图片';
          break;
        case 'text':
          res = '文本';
          break;
      }
      return res
    },
    formatStatus(status) {
      let res = ''
      switch(status){
        case 1:
          res = '已上传';
          break;
        case 2:
          res = '转码中';
          break;
        case 3:
          res = '转码失败';
          break;
        case 4:
          res = '转码成功';
          break;
      }
      return res
    },
    async getPlatforms () {
      const rsp = await request.get('/api/platforms')
      this.platformList = rsp.data.platforms
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/keyword_resources', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
        })
        this.loading = false
        this.dataSource = [].concat(rsp.data.list)
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    async handleUpload({file, onSuccess, onError,onProgress}) {
      if (!file) {
          message.error("请选择文件")
          return
      }
      this.loadingImage = true
      
      const sts = await request.post(`/api/aliyun/sts`, {})
      if (sts.error) {
        message.error(`存储服务授权失败:` + sts.error.message)
        return
      }
      const client =  new OSS({
          accessKeyId: sts.data.AccessKeyId,
          accessKeySecret: sts.data.AccessKeySecret,
          stsToken: sts.data.SecurityToken,
          // bucket: 'applet-promotion',
          bucket: 'keyword-resource',
          region: 'oss-cn-beijing'
      });
      let newName = `${this.formAdd.platform}-${file.webkitRelativePath?file.webkitRelativePath:file.name}`
      newName = newName.replace('/', '-')
      const titleObj = await request.get(`/api/keyword_resources/-/title?filename=${newName}`)
      const {filename,object,title} = titleObj.data

      const name = object
      const result = await client.multipartUpload(name, file, {
        progress(process) {
          let percent = Math.round((process*100))
          onProgress({percent},file)
        }
      });

      console.log(111, result);
      var url = "https://keyword-resource.oss-cn-beijing.aliyuncs.com/" // 待定
      // if (result && result.name) {
      //     url = url + result.name
      // }
      onSuccess()
      const params = {
        title: title,
        filename: `${filename}`,
        raw_url: url + object ,
        category: file.type.split('/')[0],
      }
      this.createResource(params)
      this.loadingImage = false
    },
    async createResource(params) {
      await request.post('/api/keyword_resources', params)
      this.handleTableChange()
    },
    deleteBanner(initial = {}) {
      let that = this;
      Modal.confirm({
        title: initial.keywords?`该文件已关联关键词，确认删除？`:'确认删除？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            let params = {}
            if (initial.keywords) params['force'] = 1
            else params = {}
            const rsp = await request.delete('/api/keyword_resources/'+initial.id, params)
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    previewModel(initial = {}) {
      this.previewData = {...initial}
      this.previewOpen = true
    },
    openUploadDia() {
      this.openDia = true
    },
    handleConfirm() {
      this.resetForm()
    },
    resetForm() {
      this.fileList = []
      this.fileListDirectory = []
      this.openDia = false;
      this.handleTableChange()
      this.$refs.formRef.resetFields();
    },
  },
  setup() {
    
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '资源名称',
          dataIndex: 'title',
          key: 'title',
          slots: { customRender: 'resourceTitle' },
          width: 150,
        },
        {
          title: '类型',
          dataIndex: 'category',
          key: 'category',
          slots: { customRender: 'category' },
          width: 120,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 120,
        },
        {
          title: '关键词',
          dataIndex: 'keywords',
          key: 'keywords',
          width: 150,
        },
        {
          title: '上传时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '上传人',
          dataIndex: 'operator',
          key: 'operator',
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>