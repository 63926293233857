<template>
  <a-card title="团长汇总数据">

    <a-space v-if="user_role==1">
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="核心团长">
          <a-select
            v-model:value="formSearch._director_core_ids"
            :options="directorCoreSuggestion"
            @search="searchDirectorCores"
            @change="onChangeDirectorCores"
            mode="multiple"
            placeholder="核心团长"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
            <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="false"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #status="{ record }">
        <span>
          <a-tag v-if="record.status == 1" color="green"  >{{ StatusMap[record.status] }}</a-tag>
          <a-tag  v-else >{{ StatusMap[record.status] }}</a-tag>
        </span>
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
    </a-table>
  </a-card>

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons-vue';

let _searchDirectorCoreDebounce = null 

let UserRoleList = Object.keys(UserRoleMap).map(v=> {
  return {
    label: UserRoleMap[v],
    value: v
  }
})
export default defineComponent({
  components: {
    SearchOutlined,
  },
  data(){
    return {
      UserRoleList,
      UserRoleMap,
      directorCoreSuggestion: [],
      loading: false,
      dataSource: [],
      noticeMessage: '',
      confirmLoading: false,
      currentData: null,
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      formSearch: {},
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/director_core/sub/summary/director', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          director_core_id: this.formSearch.director_core_id,
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data?.pagination?.total || 0} 条`
        this.pagination.total = rsp.data?.pagination?.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    resetFresh() {
      this.handleTableChange()
    },
    onChangeDirectorCores(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_core_ids = [this.formSearch._director_core_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_core_id = ids.join(',')
    },
    searchDirectorCores(name) {
      clearTimeout(_searchDirectorCoreDebounce)
      _searchDirectorCoreDebounce =  setTimeout(() => {
        request.get(`/api/director_core/suggestion`, {
          username: name
        }).then((rsp) => {
          this.directorCoreSuggestion = []
          for (let v of rsp.data.list) {
            this.directorCoreSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '商务（团长）名',
          dataIndex: 'director_name',
          key: 'director_name',
        },
        {
          title: '账号状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: '博主数',
          dataIndex: 'blogger_count',
          key: 'blogger_count',
        },
        {
          title: '注册时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>