<template>
  <a-card title="平台产品列表">
    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;"   >
        <a-form-item label="平台类型">
          <a-input placeholder="平台类型" v-model:value="formSearch.category" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleTableChange" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>

    </a-space>
    <a-row style="margin-bottom:10px"><a-button type="primary" @click="openPlatformDia(null)">新增</a-button></a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="false"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #name="{ record }" >
        <img :src="`https://jyyx-keyword.oss-cn-beijing.aliyuncs.com/image/platform/${record.name}.png`" style="height:30px;" alt="">
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #status="{ record, index }" >
        <a-switch v-model:checked="record.status" :checkedValue="1" :unCheckedValue="0" @change="handleChangeStatus($event, record, index)" />
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="openPlatformDia(record)" >编辑</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="$router.push({name: 'PlatformIndexConfig', query: {platform: record.platform, id: record.id}})" >关键词配置</a-button>
          <!-- <a-button type="link" style="padding:0" @click="checkPlatform(record)" >查看</a-button> -->
        </span>
      </template>
    </a-table>
  </a-card>


  <a-modal width="700px" v-model:visible="openDia" class="platform-model" :title="!currentData?'新增':'编辑'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" :label-col="{ style: { width: '100px' } }">
        <h4>平台基础信息</h4>
        <a-form-item label="平台类型" name="category">
          <a-select v-model:value="formAdd.category" style="width: 100%" >
            <a-select-option v-for="item in platformType" :value="item" :key="item">{{item}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="平台名称" name="name">
          <a-input placeholder="平台名称" v-model:value="formAdd.name" />
        </a-form-item>
        <!-- <a-form-item label="icon" name="icon">
          <Upload v-model:imageUrl="formAdd.icon"  />
        </a-form-item> -->
        <div class="divisions-items" v-for="(items, index) in formAdd.divisions" :key="index">
          <a-form-item label="结算类型" style="margin-bottom:0">
            <a-select v-model:value="items.method" style="width: 150px" >
              <a-select-option
                v-for="item in divisionsType"
                :value="item"
                :key="item"
                >{{item}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="单价" style="margin-bottom:0">
            <!-- <a-input placeholder="单价" v-model:value="items.price" /> -->
            <a-input-number placeholder="单价" v-model:value="items.price" />
          </a-form-item>
          <PlusCircleOutlined style="font-size:24px;margin-left:10px;cursor:pointer" @click="formAdd.divisions.push({})" />
          <MinusCircleOutlined style="font-size:24px;margin-left:10px;cursor:pointer" v-show="formAdd.divisions.length > 1" @click="formAdd.divisions.splice(index, 1)" />
        </div>
        <h4 style="margin-top:16px">平台详情</h4>
        <div class="platform-items" v-for="(items, index) in formAdd.sections" :key="index">
          <MinusCircleOutlined class="item-delete" v-show="formAdd.sections.length > 1" @click="formAdd.sections.splice(index, 1)" />
          <a-form-item label="标题" style="width:100%">
            <a-input placeholder="标题" v-model:value="items.title" show-count :maxlength="10" />
          </a-form-item>
          <a-form-item label="内容详情" style="width:100%">
            <a-textarea placeholder="请输入标题下的内容详情，最多支持500字" :auto-size="{ minRows: 3 }" show-count :maxlength="500" v-model:value="items.content" />
          </a-form-item>
        </div>
        <a-button @click="formAdd.sections.push({})" style="border: 1px dashed grey; background: rgb(242, 242, 242); width: 100%;margin-bottom:10px">+添加</a-button>
        <!-- <h4>申请注意事项</h4>
        <a-form-item>
          <a-textarea placeholder="填写申请该平台关键词需要注意的事项" v-model:value="formAdd.redirect_url" :auto-size="{ minRows: 3 }" show-count :maxlength="50" />
        </a-form-item> -->
      </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
// import Upload from './../components/Upload.vue'



export default defineComponent({
  components: {
    SearchOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    // Upload,
  },
  data(){
    return {
      UserRoleMap,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      formSearch: {
        category: '',
      },
      openDia: false,
      confirmLoading: false,
      currentData: null,
      fileList: [],
      loadingImage: false,
      imageUrl: '',
      initFormData: {
        category: '',
        name: '',
        // icon: '',
        divisions: [{method: '', price: ''}],
        sections: [{title: '', content: ''}]
      },
      formAdd: {},
      rules: {
        // category: [{ required: true, message: '请选择类型', trigger: 'change' }],
        name: [{ required: true, message: '请填写名称', trigger: 'blur' }],
        // icon: [{ required: true, message: '请上传图标', trigger: 'change' }],
      },
      platformType: ['小说','网盘','短剧','购物', 'AI'],
      divisionsType: ['拉新量结算', '小说拉新', '漫画拉新', '付费量结算', '拉活量结算', '转存量结算', '网盘会员CPS分成(%)', 'CPS分成(%)', '卸载重装结算', '订单量结算'],
    }
  },
  mounted(){
    if (this.user_role !== 1) {
      return
    }
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/platforms', {
          ...this.formSearch,
        })
        this.loading = false
        this.dataSource = [].concat(rsp.data.platforms)
        this.noticeMessage = `共 ${rsp.data.platforms.length} 条`
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    checkPlatform(initial) {
      console.log(initial);
    },
    openPlatformDia(initial=null) {
      if(initial) {
        this.currentData = {...initial}
        this.formAdd = {...initial}
      } else {
        this.currentData = null
        this.formAdd = {...this.initFormData}
      }
      this.currentData = initial ? {...initial} : null

      // let list = ['夸克','夸克影视综','夸克搜索动漫']
      // let addNewType = '拉活'

      // if (this.currentData && list.includes(this.currentData.platform) && !this.divisionsType.includes(addNewType)) {
      //   this.divisionsType.push(addNewType)
      // }
      // if (this.currentData && !list.includes(this.currentData.platform) && this.divisionsType.includes(addNewType)) {
      //   let index = this.divisionsType.findIndex(v=>v==addNewType)
      //   this.divisionsType.splice(index, 1)
      // }

      this.openDia = true

    },
    handleConfirm() {
      this.$refs.formRef.validate()
      .then(async () => {
        this.formAdd.divisions.forEach(v => {
          if (!v.method) {
            message.error('结算方式不能为空');
            throw Error()
          } else if (!v.price) {
            message.error('单价不能为空');
            throw Error()
          }
        });
        this.formAdd.sections.forEach(v => {
          if (!v.title) {
            message.error('详情标题不能为空');
            throw Error()
          } else if (!v.content) {
            message.error('详情内容不能为空');
            throw Error()
          }
        });
        if (!this.currentData) {
          const rsp = await request.post('/api/platforms', {
            ...this.formAdd,
          })
          this.resetFresh()
        } else {
          const rsp = await request.put('/api/platforms/'+this.formAdd.id, {
            ...this.formAdd,
          })
          this.resetFresh()
        }
      })
      .catch(error => {
        console.log('error', error);
      });
    },
    resetFresh() {
      this.resetForm()
      this.openDia = false;
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
    async handleChangeStatus(value, item, index) {
      try {
        const rsp = await request.put(`/api/platforms/${item.id}/status`, { status: value })
        this.handleTableChange()
      } catch(err) {
        this.dataSource[index].status = status===0?1:0
      }
    }
  },
  setup() {
    
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '图标',
          dataIndex: 'name',
          key: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: '类型',
          dataIndex: 'category',
          key: 'category',
        },
        {
          title: '平台',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 120,
        },
        {
          title: '创建人',
          dataIndex: 'operator',
          key: 'operator',
        },
        {
          title: '开关',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 180,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.platform-items {
  position: relative;
  padding-right: 16px;
  padding-top: 16px;
  margin-bottom: 10px;
  border: 1px dashed grey;
  box-sizing: border-box;
}
.platform-items .item-delete {
  position: absolute;
  right: 3px;
  top: 3px;
  color: #409eff;
  cursor: pointer;
  z-index: 10;
}
.divisions-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
</style>
<style>
.ant-modal.platform-model .ant-modal-body {
  max-height: 500px;
  overflow: auto;
}
</style>