<template>
  <a-modal v-model:visible="visible" title="博主明细" @cancel="handleCancel"  :footer="null" width="60%">

    
    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="博主名称">
          <a-select
              v-model:value="formSearch._blogger_ids"
              :options="bloggerSuggestion"
              @search="searchBloggers"
              @change="onChangeBloggers"
              mode="multiple"
              placeholder="博主名称"
              style="width: 200px"
            >
            </a-select>
        </a-form-item>
        <a-form-item label="拉新时间" >
          <a-range-picker v-model:value="formSearch.date_range" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>


    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
    </a-table>
      

  
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import request from '@/utils/request'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons-vue';

let _searchBloggerDebounce = null 

export default defineComponent({
  components: {SearchOutlined},
  props: {
    record: Object
  },
  data(){
    return {
      initial: {},
      platformVisible: false,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {},
      bloggerSuggestion: [],
    }
  },
  watch:{
    record(newVal, oldVal) {
      if (newVal) {
        this.formSearch.date_range = [newVal.stats_date,newVal.stats_date]
        this.handleTableChange()
      }
    },
  },
  methods: {
    moment,
    handleCancel() {
      this.formSearch = {}
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      console.log(123123123,this.record);
      this.loading = true
      try {
        let date_range = ''
        if (this.formSearch.date_range && this.formSearch.date_range.length) {
          date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        }
        const rsp = await request.get('/api/director_core/sub/detail/blogger', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          // ...this.record,
          platform: this.record.platform,
          director_id: this.record.director_id,
          manager_leader_id: this.record.manager_leader_id,
          ...this.formSearch,
          date_range,
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    openBloggerDetailDia(row) {
      this.initial = {...row}
      this.platformVisible = true
    },
    onChangeBloggers(item, options){
     let ids = []
     for(let v of options) {
       ids.push(v.id)
     }
     this.formSearch.blogger_ids = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    console.log('record', visible, record);
    return { 
      visible,
      columns: [
        {
          title: '主管名称',
          dataIndex: 'manager_name',
          key: 'manager_name',
          width: 100
        },
        {
          title: '商务名称',
          dataIndex: 'director_name',
          key: 'director_name',
          width: 100
        },
        {
          title: '博主名称',
          dataIndex: 'blogger_name',
          key: 'blogger_name',
          width: 100
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 100
        },
        {
          title: '拉新数量',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
          width: 100
        },
        {
          title: '拉新时间',
          dataIndex: 'stats_date',
          key: 'stats_date',
          width: 100
        },
      ],
    };
  },
});
</script>