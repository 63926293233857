<template>
  <a-modal v-model:visible="visible" title="上传资源"  @ok="handleCancel" @cancel="handleCancel">
    <UploadFile ref="UploadFileRef" @refresh="refresh" />
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import UploadFile from './UploadFile.vue'
import { Modal, message, Upload } from 'ant-design-vue';
import request from '@/utils/request'

export default defineComponent({
  components: {
    UploadFile,
  },
  props: {
    record: Object
  },
  data(){
    return {}
  },
  methods: {
    moment,
    handleCancel() {
      this.$emit("ok", false)
      this.$refs.UploadFileRef.fileList=[]
      this.$refs.UploadFileRef.fileListDirectory=[]
    },
    handleRefresh() {
      this.$emit('refresh')
    }
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    
    return { 
      dataSource,
      visible,
    };
  },
});
</script>