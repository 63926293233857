<template>
  <a-modal v-model:visible="visible" title="用户设置" :footer="null" >
   
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item required  label="用户名称">
          <a-input v-model:value="formState.username" />
      </a-form-item>
      <a-form-item :required="formState.form_type == 'new'" label="登录密码">
          <a-input v-model:value="formState.password" />
      </a-form-item>
      <a-form-item required label="角色">
          <a-select
            v-model:value="formState.role"
            >
            <a-select-option :value='"3"'  :key='3' >
              商务
            </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item  required label="用户状态">
          <a-select
           style="width: 130px;"
            v-model:value="formState.status"
          >
          <a-select-option value='0' key='0' >
            待审核
          </a-select-option>
          </a-select>
         
      </a-form-item>
      
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
          <a-button type="primary" @click="onSubmit">确认</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import {UserRoleMap, UserStatusMap} from '@/utils/macro'
import request from '@/utils/request'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'
import md5 from 'js-md5'

let _searchDirectorDebounce
const initialForm = {
  form_type: 'new',
  username: '',
  password: '',
  role: 3,
  status: 0,
}
let UserRoleList = Object.keys(UserRoleMap)
UserRoleList.shift()
export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      UserRoleList,
      UserRoleMap,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
     
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    user_label: () => {
      const store = useStore()
      return store.state.user.label
    }
  },
  watch:{
    record: function(record, v2) {
      for (let item in initialForm) {
        this.formState[item] = '' + initialForm[item];
      }
      this.formState['password'] = ''
    },
    'formState.role': function(newVal) {
      if (this.record.form_type == 'new') {
        this.formState.permission = newVal==1
      }
    }
  },
  methods: {
    async onSubmit(){

      let userId = ''
      if (!this.formState.username || 
      !this.formState.password ||
      !this.formState.role) {
        return message.error("请填写必填信息")
      }
      let param = {
        ...this.formState,
        role: +this.formState.role,
        status: + this.formState.status,
        password: md5(this.formState.password)
      }
      
      let res = await request.post('/api/director_core/sub/directors',param )
      console.log('res',res);
      userId = res.data.user_id

      this.$emit("ok")
    },
    onCancle: function () {
      this.$emit("cancel", false)
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    let formState = reactive({
      ...initialForm
    })
   
    return {
      UserStatusMap,
      visible,
      formState
    };
  },
});
</script>