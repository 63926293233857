<template>
  <a-card title="用户管理">
    <a-space   >
      <a-form  v-if="user_role == 1" layout="inline" :model="formSearch" style="margin: 10px auto;" @keyup.enter="handleTableChange" >
        <a-form-item label="用户 ID">
          <a-input placeholder="用户 ID" v-model:value="formSearch.user_id" />
        </a-form-item>
        <a-form-item label="用户名">
          <a-input placeholder="用户名" v-model:value="formSearch.username" />
        </a-form-item>
        <a-form-item  label="角色">
          <a-select
           style="width: 130px;"
            v-model:value="formSearch.role"
          >
          <a-select-option :value='i' v-for='i of Object.keys(UserRoleMap)' :key='i' >
            {{UserRoleMap[i]}}
          </a-select-option>
          </a-select>
         
      </a-form-item>
      <a-form-item  label="状态">
          <a-select
           style="width: 130px;"
            v-model:value="formSearch.status"
          >
          <a-select-option :value='i' v-for='i of Object.keys(StatusMap)' :key='i' >
            {{StatusMap[i]}}
          </a-select-option>
          </a-select>
         
      </a-form-item>
      
       <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>        
      <a-button  type='primary' @click="showUserEditor('new')" >新建用户</a-button>
      <a-tooltip placement="top" title="目前只支持批量导入代发角色账号">
        <!-- <a-button type='primary'>批量导入</a-button>  -->
        <a-dropdown-button type="primary" >
          批量导入
          <template #overlay>
            <a-menu @click="handleUploadDaifa">
              <a-menu-item key="1">
                下载模板
              </a-menu-item>
              <a-menu-item key="2">
                导入账号
              </a-menu-item>
            </a-menu>
          </template>

        </a-dropdown-button>
      </a-tooltip>

    </a-space>
    <input ref='uploadDaifaInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadDaifa"/>
  
    <a-alert v-if="user_role == 1" :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-table 
    v-if="user_role == 1"
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    :scroll="{ x: true }"
    >
      <template #role="{ record }">
        <a>{{ UserRoleMap[record.role] }}</a>
      </template>
      <template #created_at="{ record }">
        <span >{{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
       <template #status="{ record }">
        <span>
          <a-tag v-if="record.status == 1" color="green"  >{{ StatusMap[record.status] }}</a-tag>
          <a-tag  v-else >{{ StatusMap[record.status] }}</a-tag>
        </span>
      </template>
       <template #perms="{ record }">
        <span v-if="record.role==7">-</span>
        <template v-else>
          <!-- <a-switch v-model:checked="record.permissi on" :checkedValue="1" :unCheckedValue="0" @change="handleChangePermission($event, record, index)" /> -->
          <a-checkbox v-model:checked="record.perms" @change="handleChangePermission($event, record, index)">代发资源权限</a-checkbox>
        </template>
      </template>
      <template #action="{ record }" >
        <span  v-if="user_role == 1" >
          <a @click="showUserEditor('edit', record)" >编辑</a>
          <a-divider type="vertical" />
          <a v-if="record.status == 3" @click="handleEnable(record, 1)">启用</a>
          <a v-else @click="handleEnable(record, 3)">冻结</a>
          <a-divider type="vertical" />
          <a @click="unBindUser(record)" >解绑微信</a>
          <a-divider type="vertical" />
          <a @click="unBindMobile(record)" >解绑手机号</a>
        </span>
      </template>
    </a-table>
  </a-card>

  <UserEditor v-model:visible="userEditorVisible"  @cancel="userEditorVisible = false" @ok="handleUserEditorSuccess" :record="userEditorInitial"  />

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import UserEditor from '@/components/UserEditor.vue'
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal,message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment'



export default defineComponent({
  components: {
    UserEditor,
    SearchOutlined
  },
  data(){
    return {
      UserRoleMap,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        username: '',
        role: '0',
        status: '',
      },

      userEditorInitial: {
        username: 'test',
        password: '',
        blogger_name: '',
        bloggers: [],
        role: '1',
        remark: '',
        status: 0,
        id: 0,
        form_type: 'new'
      },
      
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
     
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      if (this.user_role != 1) return
      this.loading = true
      const rsp = await request.get('/api/users', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        ...this.formSearch,
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    handleEnable: async function(record, status){
      let text = status == 1 ? `确认要启用` : `确认要关闭`
      Modal.confirm({
        content: () => `${text}「${record.username}」吗？`,
        onOk: () => {
          request.put(`/api/users/${record.id}`, {
            status: status
          }).then(() => {
            this.handleTableChange(this.pagination)
          })
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
     
    },
    handleUserEditorSuccess: function() {
      this.userEditorVisible = false
      this.handleTableChange()
    },
    showUserEditor: function(type, initial = {}) {
      this.userEditorVisible = true
      this.userEditorInitial = reactive({
        form_type: type,
        ...initial,
      })
    },
    unBindUser(initial = {}) {
      console.log(initial);
      let that = this;
      Modal.confirm({
        title: '确认解绑 '+initial.username+' 的微信？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            const rsp = await request.post(`/api/users/${initial.id}/unbindwx`)
            that.handleTableChange({...that.pagination})
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    unBindMobile(initial = {}) {
      console.log(initial);
      let that = this;
      Modal.confirm({
        title: '确认解绑 '+initial.username+' 手机号？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            const rsp = await request.post(`/api/users/${initial.id}/unbindmobile`)
            that.handleTableChange({...that.pagination})
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    async handleChangePermission(e, item, index) {
      console.log(e.target.checked, item.id, index);
      let value = e.target.checked
      try {
        if (value) {
          await request.post(`/api/users/perms/grant`, { user_id: item.id, permission:'CREATE_RESOURCE_PACKAGE' })
        } else {
          await request.post(`/api/users/perms/revoke`, { user_id: item.id, permission:'CREATE_RESOURCE_PACKAGE' })
        }
        this.handleTableChange()
      } catch(err) {
        this.dataSource[index].permission = value?'':'CREATE_RESOURCE_PACKAGE'
      }
    },
    handleUploadDaifa(e){
      if (e.key == "1") {
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/BnRLsO1lxhT4TytB6bqciAoun0e")
        return
      }
      this.uploadDaifaInput.click()
    },
    async onChangeUploadDaifa(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/users/import`, files[0]).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadDaifaInput.value = null
      }

    },
    showErrorModal(fails) {
      if(fails && fails.length > 0 ){
        let vnode = []
        for (let o of fails) {
          vnode.push(<div>{o}</div>)
        }
        Modal.error({
          title: "发生错误",
          content: () => <div >{vnode}</div>,
        });
      }
    },
  },
  setup() {
    const userEditorVisible = ref(false);


    return {
      moment,
      userEditorVisible,
      StatusMap: UserStatusMap,
      uploadDaifaInput: null,
      columns: [
        {
          title: '用户 ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username',
          width: 150,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: {customRender: 'status'},
          width: 100,
        },
        {
          title: '角色',
          dataIndex: 'role',
          key: 'role',
          slots: { customRender: 'role' },
          width: 100,
        },
        {
          title: '绑定平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 150,
        },
          {
          title: '博主平台',
          dataIndex: 'blogger_platform',
          key: 'blogger_platform',
          width: 100,
        },
        {
          title: '绑定博主',
          dataIndex: 'blogger_name',
          key: 'blogger_name',
          width: 150,
        },
        {
          title: '绑定商务主管',
          dataIndex: 'manager_name',
          key: 'manager_name',
          width: 150,
        },
         {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: 200,
        },
         {
          title: '权限',
          dataIndex: 'perms',
          key: 'perms',
          slots: { customRender: 'perms' },
          width: 170,
        },
        {
          title: '注册时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 170,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 270,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>