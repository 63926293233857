<template>
  <a-card title="用户管理">
    <a-tabs v-model:activeKey="activeKey" type="card" @change="handleTableChange({pageSize: 10, current: 1})">
      <a-tab-pane :key="0" tab="待审核" />
      <a-tab-pane :key="1" tab="已审核" />
    </a-tabs>
    <a-space   >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;"   >
        <a-form-item label="账号名称">
          <a-input placeholder="账号名称" v-model:value="formSearch.username" />
        </a-form-item>
       
        <!-- <a-form-item  label="审核结果">
          <a-select
            style="width: 130px;"
            v-model:value="formSearch.result"
            :allowClear="true"
          >
            <a-select-option :value='1' :key='1' >通过</a-select-option>
            <a-select-option :value='2' :key='2' >拒绝</a-select-option>
          </a-select>
        </a-form-item> -->

        <!-- <a-form-item label="申请时间">
          <a-range-picker v-model:value="formSearch.timeRange" />
        </a-form-item> -->

        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
    <!-- audit_reason -->
      <template #role="{ record }">
        <a>{{ UserRoleMap[record.role] }}</a>
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #audit_time="{ record }" >
        <span >{{moment(record.audit_time).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" :disabled="activeKey===1" @click="auditResult(1, record)" >通过</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" :disabled="activeKey===1" @click="auditResult(2, record)" >拒绝</a-button>
        </span>
      </template>
    </a-table>
  </a-card>

  <a-modal v-model:visible="openRefuseDia" title="拒绝原因" @ok="handleConfirmRefuse">
    <a-form
    :model="formState"
    :rules="rules"
    ref="formRef"
  >
    <a-form-item
      label=""
      name="result"
    >
      <a-textarea
        v-model:value="formState.result"
        placeholder="请填写拒绝原因，最多50个字符。"
        show-count :maxlength="50"
        :auto-size="{ minRows: 3 }"
      />
    </a-form-item>
  </a-form>
  </a-modal>

</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';



export default defineComponent({
  components: {
    SearchOutlined
  },
  data(){
    return {
      UserRoleMap,
      activeKey: 0,
      openRefuseDia: false,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        username: '',
        role: '0',
        // result: ''
      },
      formState: {
        result: ''
      },
      rules: {
        result: [{ required: true, message: '请填写拒绝原因', trigger: 'blur' }]
      },
      currentData: {}
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      console.log(this.formSearch);
      const rsp = await request.get('/api/users', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        status: this.activeKey,
        ...this.formSearch,
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    auditResult(type, initial = {}) {
      console.log(type, initial);
      if (type===1) {
        this.postAudit(initial.id, {status: type})
      } else if (type===2) {
        this.openRefuseDia = true
        this.currentData = initial
      }
    },
    async postAudit(userId, params) {
      const rsp = await request.post(`/api/users/${userId}/audit`, params)
      console.log('rsp', rsp);
      if (rsp) {
        this.handleTableChange()
        this.openRefuseDia = false
      }
    },
    handleConfirmRefuse() {
      console.log(123);
      this.$refs.formRef.validate()
      .then(() => {
        this.postAudit(this.currentData.id, {status: 2, remark: this.formState.result})
      })
      .catch(error => {
        console.log('error', error);
      });
    }
  },
  setup() {
    const userEditorVisible = ref(false);


    return {
      userEditorVisible,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '用户 ID',
          dataIndex: 'id',
          key: 'id',
          width: 120,
        },
        {
          title: '账号名称',
          dataIndex: 'username',
          key: 'username',
          width: 120,
        },
        {
          title: '角色',
          dataIndex: 'role',
          key: 'role',
          slots: { customRender: 'role' },
          width: 120,
        },
        {
          title: '主做业务',
          dataIndex: 'business_platform',
          key: 'business_platform',
          width: 120,
        },
        {
          title: '商务主管',
          dataIndex: 'director_name',
          key: 'director_name',
          width: 120,
        },
        {
          title: '手里资源',
          dataIndex: 'business_resource',
          key: 'business_resource',
          width: 120,
        },
        {
          title: '申请时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '审核时间',
          dataIndex: 'audit_time',
          key: 'audit_time',
          slots: { customRender: 'audit_time' },
          width: 180,
        },
        {
          title: '审核结果',
          dataIndex: 'audit_reason',
          key: 'audit_reason',
          width: 120,
        },
        {
          title: '理由',
          dataIndex: 'audit_reason',
          key: 'audit_reason',
          width: 120,
        },
        {
          title: '操作人',
          dataIndex: 'operator',
          key: 'operator',
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>