<template>
  <a-modal v-model:visible="visible" title="资源管理" :footer="null" @cancel="onCancle" @ok="onCancle" :destroyOnClose="true">
   
      <div>
      <div style="max-height:500px;overflow-y:auto;padding-right:15px;">
        <div v-for="(item,index) in sourceList" :key="index" style="margin-bottom:10px;">
          <div style="overflow:hidden" v-if="item.category=='text'">
            <a-textarea
              v-model:value="item.text"
              :placeholder="item.title"
              :auto-size="{ minRows: 2, maxRows: 5 }"
            />
            <a-button type="primary" style="margin-top:10px;float:right;" @click="saveText(item.text)" v-if="showOperateBtn">保存修改</a-button>
          </div>
          <div style="overflow:hidden" v-else>
            <a-image
              v-if="item.category==='image'"
              :src="item.media_url"
            />
            <div v-if="item.category==='video'">
              <video
                :src="item.media_url"
                ref="video"
                style="width:100%;"
                controls
                preload="metadata"
                webkit-playsinline="true"
                playsinline="true"
                x-webkit-airplay="allow"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="false"
                x5-video-orientation="landscape"
                >
                抱歉，您的浏览器不支持内嵌视频!
              </video>
            </div>
            <a-button type="primary" style="margin-top:10px;float:right;" @click="deleteResource(item)" v-if="showOperateBtn">删除</a-button>
          </div>
        </div>
      </div>
        <div style="margin-top:20px" v-if="showOperateBtn">
          <a-select
            v-model:value="checkedResource"
            mode="multiple"
            label-in-value
            placeholder="请选择资源"
            style="width: 60%"
            :filter-option="false"
            :field-names="{ label: 'title', value: 'media_url' }"
            :not-found-content="fetching ? undefined : null"
            :options="allResources"
            @search="filterResource"
          >
            <template v-if="fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
          </a-select>
          <a-button type="primary" style="margin-left:10px" @click="addResource">添加</a-button>
        </div>
      </div>
   
  </a-modal>
 
</template>

<script >
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive,ref, watch, createVNode} from 'vue';
import  { toRefs} from 'vue';
import {useStore} from 'vuex'
import request from '@/utils/request'
import { message, Modal } from 'ant-design-vue';
import {
  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
} from '@/utils/macro'


export default defineComponent({
  props: ['record'],
  data(){
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      sourceList: [],
      text: '',
      checkedResource: [],
      allResources: [],
      fetching: false,
      lastFetchId: 0
    }
  },
  watch: {
    record: function(v1, v2) {
      console.log('watch');
      if (v1) {
        this.getResourceList()
        this.filterResource()
      }
    }
  },
  computed:{
    showOperateBtn: () => {
      const store = useStore()
      const user_role = store.state.user.role
      return [UserRoleOperator, UserRoleAuditor].includes(user_role)
    }
  },
  mounted(){
    console.log("monted")
  },
  methods: {
    async filterResource(value) {
      console.log('filterResource', value);
      this.allResources = [];
      this.fetching = true;
      const res = await request.get(`/api/keyword_resources`, {title: value})
      this.allResources = res.data.list.map(v=>{
        v['label']=v.title
        v['value']=v.id
        return v
      })
      this.fetching = false
    },
    addResource() {
      console.log(this.checkedResource);
      if (this.checkedResource.length) {
        this.checkedResource.forEach(async v=> {
          await request.post(`/api/keywords/${this.record.id}/resources/${v.value}/bind`)
          this.getResourceList()
        })
      }
    },
    async getResourceList() {
      let res = await request.get(`/api/keywords/${this.record.id}/resources`, {})
      console.log('resources',res);
      this.sourceList = res.data.resources
    },
    async onSubmit(){
      
      this.checkedResource = []
      this.$emit("ok")
      this.$emit("cancel", false)
    },
    onCancle: function () {
      
      this.checkedResource = []
      this.$emit("cancel", false)
    },
    async saveText(text) {
      console.log(111, text, this.record);
      let res = await request.put(`/api/keywords/${this.record.id}/promotion_text`, {promotion_text: text})
      // let res = await request.put(`/api/keywords/${this.record.id}`, {promotion_text: text})
      console.log('resources',res);
    },
    async deleteResource(record) {
      // let res = await request.delete(`/api/keyword_resources/rel/${record.id}`)
      let res = await request.post(`/api/keywords/${this.record.id}/resources/${record.id}/unbind`)
      console.log('delete',res);
      this.getResourceList()
    },
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    return { 
      visible,
    };
  },
});
</script>