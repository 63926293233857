<template>
  <a-modal v-model:visible="visible" title="发布账号设置" :footer="null" >
      <a-form :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item required v-if="userRole != UserRoleBlogger" label="博主" >
          <a-select
            :disabled="formState.form_type == 'edit'"
            v-model:value="formState._blogger_ids"
            :options="bloggerSuggestion"
            @search="searchBloggers"
            @change="onChangeBloggers"
            mode="multiple"
            placeholder="博主名称"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>
        <a-form-item required  label="账号名称">
            <a-input placeholder="平台账号昵称" v-model:value="formState.account_name" />
        </a-form-item>
    
        <a-form-item required label="发布平台">
          <a-select
            placeholder="请选择"
          v-model:value="formState.platform"
          >
          <a-select-option :value='i' v-for='i of bloggerPlatforms' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item required label="主页地址">
          <a-input type="url" placeholder="能够访问到个人主页的地址" v-model:value="formState.home_url" />
        </a-form-item>
        <a-form-item :required="isRequireID"  :label="`账号 ID`">
          <a-input  placeholder="账号 ID（如果修改过请自主同步更新）" v-model:value="formState.account_id" />
        </a-form-item>
       
       
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
          <a-button html-type="submit" type="primary" >确认</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import {UserRoleMap, PlatformEnum} from '@/utils/macro'
import request from '@/utils/request'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'
import {
  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
} from '@/utils/macro'

let _searchBloggerDebounce
const initialForm = {
  form_type: 'new',

  platform: '', // 运营专属
  account_id: '',
  account_name: '',
  blogger_category: '',
  home_url : '',

  _blogger_ids: [],

  
}
let UserRoleList = Object.keys(UserRoleMap)
UserRoleList.shift()
export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      formState: {
        ...initialForm
      },
      UserRoleList,
      UserRoleMap,
      PlatformEnum,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      bloggerSuggestion:[],
     
    }
  },
  computed:{
    isRequireID: function (){
      return ['抖音','快手', 'B站', '小红书', '微博'].indexOf(this.formState.platform) > -1
    },
  },
  mounted(){
    this.searchBloggers()
  },
  watch:{
    record: function(record, v2) {
      console.log(record, v2)
      this.formState = {
        ...initialForm
      }

      for (let item in this.formState) {
        if (record[item]) {
          this.formState[item] =  record[item];
        }
      }
      if (record['user_id']) {
        this.searchBloggers(record['username'])
        this.formState._blogger_ids = [record['user_id']]
      }
      if (record.form_type == 'edit') {
        this.bloggerSuggestion = [
          {
            value: record.username,
            id: record.user_id,
          }
        ]
        this.formState._blogger_ids = [record.username]
      }

    }
  },
  methods: {
    onChangeBloggers(item, options){
      if(options.length > 1) {
          message.error("只能选择一项")
          this.formState._blogger_ids = [this.formState._blogger_ids[0]]
          return false
      }
      for(let v of options) {
        this.formState.user_id = v.id
        return
      }
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
          request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
    async onSubmit(){
      if (!this.formState.account_name ||
        !this.formState.platform ||
        !this.formState.home_url) {
        return message.error("请填写必填信息")
      }
      let param = {
        ...this.formState
      }
      if (this.userRole == UserRoleBlogger) {
        param.user_id = +this.loginUser.user_id
      }
      if (this.formState.form_type == 'edit') {
        await request.put(`/api/bloggers/${this.record.user_id}/accounts/${this.record.id}`, param)
      } else {
        await request.post(`/api/bloggers/${param.user_id}/accounts`,param )
      }
      this.$emit("ok")
    },
    onCancle: function () {
      this.$emit("cancel", false)
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    const store = useStore()
    return {
      loginUser: store.state.user,
      userRole: store.state.user.role,
      UserRoleOperator,
      UserRoleDirectorManager,
      UserRoleDirector,
      UserRoleBlogger,
      UserRoleAuditor,
      bloggerPlatforms: [
        '抖音',
        '快手',
        'B站',
        '微博',
        '小红书',
        '其他'
      ],
      visible,
    };
  },
});
</script>