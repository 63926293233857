<template>
  <a-modal v-model:visible="visible" width="700px" 
    :title="title"  
    @ok="handleOk" @cancel="handleCancel">
    <a-form :model="form" style="margin: 10px auto;"  :rules="rules" ref="formRef" >
    <template v-if="record.form_type!='only_source'">
      <!-- <a-form-item label="平台" v-show="showPlatform" name="platform">
        <a-select v-model:value="form.platform" @change="handleTableChange" placeholder="平台" :allowClear="true" style="width: 100%">
          <a-select-option v-for="(item, index) in platformList" :value="item.name" :key="index">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item> -->
      <a-form-item label="关键词" name="keyword_id" v-show="showKeyword">
        <a-select
          v-model:value="form.keyword_id"
          :options="keywordsSuggestion"
          @search="searchKeywords"
          :disabled="record.form_type=='edit'"
          show-search
          :filter-option="false"
          :not-found-content="null"
          placeholder="选择关键词"
          style="width: 100%"
          @change="handleSelectKeyword"
        />
      </a-form-item>
      <a-form-item label="资源包名称" name="pkg_title">
        <a-input placeholder="资源包名称" v-model:value="form.pkg_title" />
      </a-form-item>
      <a-form-item label="定向投放人">
        <a-select
          v-model:value="form.targets"
          :options="usersSuggestion"
          @search="searchUsers"
          mode="multiple"
          placeholder="选择定向投放人"
          :filter-option="false"
          :not-found-content="null"
          style="width: 100%;"
        />
      </a-form-item>
    </template>
      <a-radio-group v-model:value="resourceType" style="margin-bottom:10px">
        <a-radio-button value="1">关联资源</a-radio-button>
        <a-radio-button value="2">新增资源文件</a-radio-button>
        <a-radio-button value="3">创建文本资源</a-radio-button>
        <a-radio-button value="4">批量创建文本资源</a-radio-button>
      </a-radio-group>
      <div v-show="resourceType=='1'">
        <a-alert message="选择资源管理中已上传的资源" closable banner :show-icon="false" type="info" />
        <a-select
          v-model:value="checkedResource"
          :options="resourcesSuggestion"
          @search="searchResources"
          mode="multiple"
          placeholder="选择资源"
          :filter-option="false"
          :not-found-content="null"
          style="width: 100%;margin-top:10px"
        />
      </div>
      <div v-show="resourceType=='2'">
        <UploadFile ref="UploadFileRef" />
      </div>
      <div v-show="resourceType=='3'">
        <CreateText ref="createTextRef" @textIds="getTextIds" />
      </div>
      <div v-show="resourceType=='4'">
        <UploadBatchText ref="UploadBatchTextRef" :pkgId="this.pkgId" @batchTextIds="getBatchTextIds" />
      </div>
    </a-form>
      
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import { Modal, message, Upload } from 'ant-design-vue';
import request from '@/utils/request'
import moment from 'moment';
import UploadFile from './UploadFile'
import UploadText from './UploadText'
import CreateText from './CreateText'
import UploadBatchText from './UploadBatchText'

let _searchKeywordsDebounce = null
let _searchResourceDebounce = null
let _searchUsersDebounce = null

export default defineComponent({
  components: {
    UploadFile,
    CreateText,
    UploadBatchText,
  },
  props: {
    record: Object,
    showPlatform: {
      type: Boolean,
      default: true
    },
    showKeyword: {
      type: Boolean,
      default: true
    },
  },
  data(){
    return {
      resourceType: '1',
      platformList: [],
      form: {},
      rules: {
        keyword_id: [{required: true,message:'请选择关键字',trigger:['blur','change']}],
        pkg_title: [{required: true,message:'请输入资源包名称',trigger:'blur'}],
        // platform: [{required: true,message:'请选择平台',trigger:['blur','change']}],
      },
      userList: [],
      keywordList: [],
      keywordsSuggestion: [],
      checkedResource: [],  
      resourcesSuggestion: [],
      usersSuggestion: [],
      textIds: [],
      promiseTextList: [],
      pkgId: '',
      batchTextIds: [],
    }
  },
  computed: {
    title() {
      return this.record.form_type=='edit'?'编辑资源包':this.record.form_type=='only_source'?'新增资源':'创建资源包'
    }
  },
  watch:{
    record: function(record, v2) {
      if (record.form_type == 'only_source') {
        this.form = {...record}
        this.pkgId = this.form.id
      } else if (record.form_type == 'new') {
        this.form = {}
        if (record.platform) {
          this.form.platform = record.platform
        }
        if (record.id) {
          this.form.keyword_id = record.id
        }
      } else {
        this.form = {...record}
        this.form.keyword_id = record.keyword
        this.form.pkg_title = record.title
        this.form.targets = []
        if (record.target_users && record.target_users.length) {
          let target = JSON.parse(record.targets)
          this.form.targets = target
          this.usersSuggestion = record.target_users.map(v=> {
            return {
              id: v.user_id,
              value: v.user_id,
              label: v.username,
            }
          })
        }
        this.pkgId = this.form.id
      }
    },
  },
  created() {
    this.getPlatforms()
    this.searchKeywords()
    this.searchResources()
    this.searchUsers()
  },
  methods: {
    moment,
    searchUsers(text) {
      clearTimeout(_searchUsersDebounce)
      _searchUsersDebounce = setTimeout(() => {
        request.get(`/api/users`, {
          username: text,
          role: 6
        }).then((rsp) => {
          this.usersSuggestion = []
          for (let v of rsp.data.list) {
            this.usersSuggestion.push({
              id: v.id,
              value: v.id,
              label: v.username
            })
          }
        })
      }, 600)
    },
    searchResources(text) {
      clearTimeout(_searchResourceDebounce)
      _searchResourceDebounce = setTimeout(() => {
        request.get(`/api/resources`, {
          title: text
        }).then((rsp) => {
          this.resourcesSuggestion = []
          for (let v of rsp.data.list) {
            let type = v.category=='video'?'视频':v.category=='image'?'图片':v.category=='text'?'文本':''
            this.resourcesSuggestion.push({
              id: v.id,
              value: v.id,
              label: type + '-' + v.title,
            })
          }
        })
      }, 600)
    },
    searchKeywords(text) {
      clearTimeout(_searchKeywordsDebounce)
      _searchKeywordsDebounce = setTimeout(() => {
        request.get(`/api/keywords`, {
          text: text
        }).then((rsp) => {
          this.keywordsSuggestion = []
          for (let v of rsp.data.list) {
            this.keywordsSuggestion.push({
              id: v.id,
              value: v.id,
              label: v.text,
              platform: v.platform
            })
          }
        })
      }, 600)
    },
    handleSelectKeyword(val, opt) {
      this.form.platform = opt.platform
    },
    async getPlatforms () {
      const rsp = await request.get('/api/platforms')
      this.platformList = rsp.data.platforms
    },
    getTextIds(textId) {
      this.textIds.push(textId)
    },
    getBatchTextIds(textId) {
      this.batchTextIds.push(...textId)
    },
    async bindNewResource() {
      let fileList = this.$refs.UploadFileRef.fileList.map(v=>v.sourceId)
      let fileFolderList = this.$refs.UploadFileRef.fileListDirectory.map(v=>v.sourceId)

      const resource_ids = [...this.checkedResource, ...fileList, ...fileFolderList, ...this.textIds, ...this.batchTextIds]
        
      if (resource_ids.length < 1) {
        return message.error('请选择或新增需要关联的资源')
      }
      await request.post(`/api/resource_pkgs/${this.record.id}/resources/bind`, { resource_ids })
      this.$emit("ok", false)
    },
    async addBatchTextResource(params) {
      let rsp = await request.post('/api/resources', params)
      this.textIds.push(rsp.data.id)
      return rsp
    },
    async handleOk() {
      let val = this.$refs.createTextRef.dynamicValidateForm.sights
      val.forEach(e => {
        if (!e.name) {
          message.error('创建文本处有未填入的名称，请输入')
          throw Error()
        } else if (!e.text) {
          message.error('创建文本处有未填入的文本，请输入')
          throw Error()
        }
      });
      val.forEach(async e => {
        this.promiseTextList.push(this.addBatchTextResource({
          title: e.name,
          text: e.text,
          category: 'text'
        }))
      })
      
      if (this.record.form_type == 'only_source') {
        await Promise.all(this.promiseTextList)
        await this.bindNewResource()
        return
      }
      this.$refs.formRef.validate().then(async () => {
        let resourcePkgsId = ''
        const params = {
          keyword_id: this.form.keyword_id,
          title: this.form.pkg_title,
          targets: this.form.targets,
          platform: this.form.platform
        }
        if (this.record.form_type == 'new') {
          const rsp = await request.post('/api/resource_pkgs', params)
          resourcePkgsId = rsp.data.id
        } else {
          resourcePkgsId = this.record.id
          await request.put(`/api/resource_pkgs/${resourcePkgsId}`, params)
        }
        await Promise.all(this.promiseTextList)
        let fileList = this.$refs.UploadFileRef.fileList.map(v=>v.sourceId)
        let fileFolderList = this.$refs.UploadFileRef.fileListDirectory.map(v=>v.sourceId)

        const resource_ids = [...this.checkedResource, ...fileList, ...fileFolderList, ...this.textIds, ...this.batchTextIds]
        if (resource_ids.length) {
          await request.post(`/api/resource_pkgs/${resourcePkgsId}/resources/bind`, { resource_ids })
        }
        this.reset()
        this.$emit("ok", false)
      })
      
    },
    handleCancel() {
      this.reset()
      this.$emit("cancel", false)
    },
    reset() {
      this.checkedResource = []
      this.resourceType = '1'
      this.$refs.UploadFileRef.fileList=[]
      this.$refs.UploadFileRef.fileListDirectory=[]
      this.$refs.UploadBatchTextRef.fileList=[]
      this.$refs.UploadBatchTextRef.fileListDirectory=[]
      this.$refs.createTextRef.dynamicValidateForm={sights:[]}
    },
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    return { 
      dataSource,
      visible,
    };
  },
});
</script>