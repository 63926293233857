<template>
  <a-card title="博主明细数据">
    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="核心团长" v-if="user_role==1">
          <a-select
            v-model:value="formSearch._director_core_ids"
            :options="directorCoreSuggestion"
            @search="searchDirectorCores"
            @change="onChangeDirectorCores"
            mode="multiple"
            placeholder="核心团长"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="商务名称">
          <a-select
            v-model:value="formSearch._director_ids"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="商务名称"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="平台">
          <a-select
              v-model:value="formSearch.platform"
              :options="platformsSuggestion"
              placeholder="平台"
              style="width: 200px"
            >
            </a-select>
        </a-form-item>
        <a-form-item label="拉新时间" >
          <a-range-picker v-model:value="formSearch.date_range" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="searchQuery"
            shape="circle"
          >
            <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="openPlatformDetailDia(record)" >平台明细</a-button>
        </span>
      </template>
    </a-table>

    <BloggerPlatformDia v-model:visible="platformVisible"  @cancel="platformVisible = false" :record="platformInitial" />
  
  </a-card>

</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap, PlatformSug } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import BloggerPlatformDia from './components/BloggerPlatformDia.vue'


let _searchDirectorDebounce = null 
let _searchDirectorCoreDebounce = null 

export default defineComponent({
  components: {
    SearchOutlined,
    BloggerPlatformDia,
  },
  data(){
    return {
      platformVisible: false,
      platformInitial: {},
      directorSuggestion: [],
      directorCoreSuggestion: [],
      UserRoleMap,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        director_id: '',
        date_range: [moment().month(-1).format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')],
        platform: '',
      },
      platformsSuggestion: [].concat(PlatformSug),
    }
  },
  mounted(){
    if (this.user_role!=1) {
      this.handleTableChange()
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      if (this.user_role==1&&!this.formSearch.director_core_id) {
        return  message.error("请选择核心团长")
      }
      this.loading = true
      try {
        let date_range = ''
        if (this.formSearch.date_range && this.formSearch.date_range.length) {
          date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        }
        const rsp = await request.get('/api/director_core/sub/detail/platform', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
          date_range
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_ids = [this.formSearch._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_id = ids.join(',')
    },
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
    searchQuery() {
      this.handleTableChange()
    },
    openPlatformDetailDia(row) {
      this.platformInitial = {...row,director_core_id:this.formSearch.director_core_id}
      this.platformVisible = true
    },
    onChangeDirectorCores(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_core_ids = [this.formSearch._director_core_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_core_id = ids.join(',')
    },
    searchDirectorCores(name) {
      clearTimeout(_searchDirectorCoreDebounce)
      _searchDirectorCoreDebounce =  setTimeout(() => {
        request.get(`/api/director_core/suggestion`, {
          username: name
        }).then((rsp) => {
          this.directorCoreSuggestion = []
          for (let v of rsp.data.list) {
            this.directorCoreSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
  },
  setup() {
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '商务名称',
          dataIndex: 'director_name',
          key: 'director_name',
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
        },
        {
          title: '拉新数量',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
        },
        {
          title: '拉新时间',
          dataIndex: 'stats_date',
          key: 'stats_date',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>