<template>
  <a-modal v-model:visible="visible" title="编辑">
    <a-form :model="form" style="margin: 10px auto;" ref="formRef" >
      <a-form-item label="关键词产品" name="reason">
        {{ record.platform }}
      </a-form-item>
      <a-form-item label="付款平台" name="reason">
        {{ record.payment_platform }}
      </a-form-item>
      <a-form-item label="付款税源地" name="reason">
        <a-select
          v-model:value="form.tax_area"
          placeholder="付款税源地"
          style="width:100%"
          allowClear
        >
        <a-select-option :value='i.tax_area' v-for='i of platforms' :key='i' >
          {{i.label_name}}<span v-if="i.payment_platform">（{{i.payment_platform}}）</span>
        </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
    </template>
      
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import request from '@/utils/request'
import { Modal, message, Upload } from 'ant-design-vue';

export default defineComponent({
  props: {
    record: Object,
    platforms: Array,
  },
  data(){
    return {
      loading: false,
      form: {tax_area:''},
    }
  },
  watch:{
    record: function(record, v2) {
      if (record) {
        this.form['tax_area'] = record.tax_area
      } 
    },
  },
  methods: {
    moment,
    handleOk() {
      this.$refs.formRef.validate().then((res) => {
        if (res) {
          console.log(res);
          this.loading = true
          this.handleAccept()
        } else {
          return
        }
      })
    },
    handleAccept() {
      request.put(`/api/payments/platforms/${this.record.id}`,{tax_area:this.form.tax_area}).then((rsp) => {
        this.handleCancel()
        this.loading = false
      }).catch(()=>this.loading = false)
    },
    handleCancel() {
      this.form.tax_area=''
      this.$emit("cancel", false)
    },
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
      console.log(111, newProps.record);
      // this.form.tax_area = newProps.record.tax_area
    });
    return { 
      dataSource,
      visible,
    };
  },
});
</script>