<template>

  <a-card title="数据上传">
   
    <a-space>
      
      <a-button type="primary" :disabled="uploadLoading" @click="() => {uploadStatsDataInput.click() }">
        上传平台每日拉新汇总数据
      </a-button>
      <a href="https://docs.qq.com/sheet/DVGNWVkJxbWRvS3p5?tab=BB08J2" target="__blank" >数据分析表模板</a>
      <input ref='uploadStatsDataInput' style="visibility: hidden;" type= "file" @change="handleUpload"/>

    </a-space>
    

  </a-card>

</template>
<script >
import { defineComponent, ref, reactive, watch} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import { Modal } from 'ant-design-vue';
import {UserRoleMap, KeywordStatusMap, PlatformSug} from '@/utils/macro'
import _ from 'lodash'

import { useRoute } from 'vue-router';
import { useStore } from 'vuex'
import moment from 'moment'

export default defineComponent({
  components: {},
  data(){
    return {
      uploadLoading: false,
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{},
  methods: {
    async handleUpload(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/stats/data/growth`, files[0]).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.uploadLoading = false
        })
        this.uploadStatsDataInput.value = null
      }

    },
  },
  setup(props, context) {
    return {
      uploadStatsDataInput: null,
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>