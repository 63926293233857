<template>
  <a-config-provider :locale="locale">
    <Agreement v-if="userID > 0 && !isAgreement" v-model:visible="agreementVisible" @ok="onAgreement" />
    <router-view v-if="loaded" />
  </a-config-provider>
</template>
<script >
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { defineComponent, reactive } from 'vue';
import { message } from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router'
import request from './utils/request'
import { useStore } from 'vuex'
import {ref} from '@vue/cli-plugin-vuex'
import Agreement from './components/Agreement.vue'

export default defineComponent({
  data(){
    return {
      loaded: false,
      userData: {},
      agreementVisible: true,
      locale: zhCN,
    }
  },
  components:{
    Agreement
  },
  computed:{
    userID : () => {
      const store = useStore()
      return store.state.user.user_id
    },
    isAgreement: () => {
      const store = useStore()
      return store.state.user.is_agreement
    }
  },
  methods:{
    onAgreement: function(){
      location.reload()
    }
  },
  mounted(){
    let router = useRouter()
    let route = useRoute()
    const store = useStore()
    request.get(
      '/api/users/auth'
    ).then((res) => {
      store.commit("setLogin", res.data)
      this.loaded = true
      
    }).catch((res) => {
      this.loaded = true
      console.log("未登录", res)
      router.push({
        path: "/"
      })
    })
  },
  setup() {
    return {
      
    };
  },
});
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.notice-icon {
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
}
</style>
