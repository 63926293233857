<template>
  <a-modal v-model:visible="visible" title="创建文本" width="60%" @ok="handleOk" @cancel="handleCancel">
    <CreateText ref="createTextRef" />
  </a-modal>
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import CreateText from './CreateText.vue'
import { Modal, message, Upload } from 'ant-design-vue';
import request from '@/utils/request'

export default defineComponent({
  components: {
    CreateText,
  },
  props: {
    record: Object
  },
  data(){
    return {
      dynamicValidateForm: {},
      form: {reason: ''},
      rules: {reason: [{required: true,message:'请输入无效原因',trigger:'blur'}]}
    }
  },
  methods: {
    moment,
    handleOk() {
      let val = this.$refs.createTextRef.dynamicValidateForm.sights
      val.forEach(e => {
        if (!e.name) {
          message.error('有未填入的名称，请输入')
          throw Error()
        } else if (!e.text) {
          message.error('有未填入的文本，请输入')
          throw Error()
        }
      });
      val.forEach(async (e,i,arr) => {
        let rsp = await request.post('/api/resources', {
          title: e.name,
          text: e.text,
          category: 'text'
        })
        if (i==arr.length-1) {
          this.$emit("ok", false)
        }
      })

    },
    handleCancel() {
      this.$emit("cancel", false)
    },
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    return { 
      dataSource,
      visible,
    };
  },
});
</script>