<template>
  <a-modal v-model:visible="visible" title="创建付款批次">
    <a-form :model="form" style="margin: 10px auto;" ref="formRef" :rules="rules">
      <a-form-item label="付款名称" name="title">
        <a-input placeholder="付款名称" v-model:value="form.title" />
      </a-form-item>
      <a-form-item label="上传文件" name="file">
        <a-upload 
          v-model:file-list="form.file"
          ref="UploadRef"
          :max-count="1"
          :before-upload="beforeUpload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
          <a-button><upload-outlined></upload-outlined>上传文件</a-button>
        </a-upload>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
    </template>
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import OSS from 'ali-oss'
import { Modal, message, Upload } from 'ant-design-vue';
import request from '@/utils/request'

export default defineComponent({
  components: {
    UploadOutlined,
  },
  props: {
    record: Object
  },
  data(){
    return {
      loading: false,
      fileList: '',
      file: null,
      form: {title: '',file: ''},
      rules: {title: [{required: true,message:'请输入付款名称',trigger:'blur'}]}
    }
  },
  methods: {
    moment,
    handleOk() {
      try {
        this.$refs.formRef.validate().then((res) => {
          if (res) {
            console.log(res);
            this.handleUpload()
          } else {
            return
          }
        })
      } catch(err) {
        console.log(err);
      }
    },
    handleCancel() {
      this.form.title = ''
      this.form.file = []
      this.file = null
      this.$emit("cancel", false)
    },
    beforeUpload(file) {
      this.form.file = [file]
      this.file = file
    },
    async handleUpload() {
      if (!this.file) {
        return  message.error("请选择文件")
      }
      this.loading = true
      const formData = new FormData();
      formData.append('title', this.form.title);
      formData.append('file', this.file);
      request.uploadFile(`/api/payments/batches`, formData).then((res) => {
        this.handleCancel()
        this.loading = false
      }).catch(()=>this.loading = false)
    },
    async createResource(params, uid) {
      this.$emit('refresh')
    },
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      console.log('visible', newProps.visible);
    });
    return { 
      visible,
    };
  },
});
</script>