<template>
  <a-card>
    <template #title>
      <div>
        <LeftOutlined @click="$router.back()" style="margin-right:10px" />{{ '配置平台关键字' }}
      </div>
    </template>
    <a-row style="margin-bottom:20px;"><a-button type="primary" @click="addConfigItem">新增字段</a-button></a-row>
    <a-space style="width: 100%;display: block;">
      <a-form :model="formAdd" style="margin: 10px auto;" ref="formAddConfigRef">
        <a-form-item label="平台">
          {{ $route.query.platform }}
        </a-form-item>
        <a-form-item label="干预方式">
          <a-radio-group v-model:value="hasIntervene">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
          <template v-if="hasIntervene">
            <a-button type="primary" @click="addConfigItem('ganyu')">添加</a-button>
            <a-select style="width:200px;margin-left:10px">
              <a-select-option v-for="(item) in interveneList" :value="item" :key="item">{{item}}</a-select-option>
            </a-select>
            <span style="margin-left:10px">排序到第<a-input-number  style="width:100px;" placeholder="排序" v-model:value="interveneSort" />位</span>
          </template>
        </a-form-item>
        <a-form-item label="关键词提示">
          <a-textarea placeholder="产品关键词提示" v-model:value="platformDesc" />
        </a-form-item>
        <a-form-item label="资源下载提示">
          <a-form-item label="文本">
            <a-textarea placeholder="产品关键词提示" v-model:value="textResourceTip" />
          </a-form-item>
          <a-form-item label="视频">
            <a-textarea placeholder="产品关键词提示" v-model:value="videoResourceTip" />
          </a-form-item>
          <a-form-item label="图片">
            <a-textarea placeholder="产品关键词提示" v-model:value="imageResourceTip" />
          </a-form-item>
        </a-form-item>
        <a-divider v-show="configPreviewList.length">预览</a-divider>
        <div style="">
          <draggable :list="configPreviewList" item-key="index" tag="div" handle=".drag" append-to-body>
            <template #item="{ element, index }">

              <div class="drag" style="position:relative;cursor:move;">
                <a-descriptions @click="openEditDia(element, index)" :title="(index+1)+'、'+element.label" style="margin-bottom:10px;border:1px solid #eee;box-sizing: border-box;padding: 20px;">
                  <template #title>
                    <span>{{ (index+1)+'、'+element.label }}</span>
                  </template>
                  <a-descriptions-item label="类型">{{ formatType(element.type) }}</a-descriptions-item>
                  <a-descriptions-item label="数据源" >
                    <span >{{ formatDataSource(element) }}</span>
                    <template v-if="element.fromData==='custom'&&element.options.length">
                      {{ element.options }}
                    </template>
                  </a-descriptions-item>
                  <a-descriptions-item label="必填">{{ formatBoolean(element.isRequired) }}</a-descriptions-item>
                  <a-descriptions-item label="字数限制">
                    {{ formatBoolean(element.isLimit) }}
                    <template v-if="element.isLimit">
                      <span> 最大：{{ element.inputMaxNumber }}</span>
                      <span> 最小：{{ element.inputMinNumber }}</span>
                    </template>
                  </a-descriptions-item>
                  <a-descriptions-item label="编辑">{{ formatBoolean(element.isEditabled) }}</a-descriptions-item>
                  <a-descriptions-item label="判断展示身份">
                    {{ element.isRole?'':formatBoolean(element.isRole) }}
                    <template v-if="element.isRole&&element.roleList.length">
                      {{ formatRoleList(element.roleList) }}
                    </template>
                  </a-descriptions-item>
                  <a-descriptions-item label="干扰方式">
                    {{ element.isBelongIntervene?'':formatBoolean(element.isBelongIntervene) }}
                    <template v-if="element.isBelongIntervene">
                      {{ element.intervene }}
                    </template>
                  </a-descriptions-item>
                  <a-descriptions-item label="复制文案">{{ element.copy_url }}</a-descriptions-item>
                  <a-descriptions-item label="占位文案">{{ element.placeholder }}</a-descriptions-item>
                  <a-descriptions-item label="对应字段">{{ element.field_name }}</a-descriptions-item>
                  <a-descriptions-item label="验证规则">{{ element.vertify }}</a-descriptions-item>
                  <a-descriptions-item style="position:absolute;top:10px;right:10px">
                    <MinusCircleOutlined style="font-size:24px;margin-left:10px;cursor:pointer" @click="configPreviewList.splice(index, 1)" />
                  </a-descriptions-item>
                </a-descriptions>



              </div>

            </template>
          </draggable>
        </div>

        <a-form-item>
          <a-button type="primary" @click="saveConfig">保存</a-button>
        </a-form-item>
      </a-form>

    </a-space>
  </a-card>

  <a-modal v-model:visible="openDia" :title="!currentData?'新增':'编辑'" width="800px" @cancel="resetForm" @ok="handleConfirm">
    <a-form style="margin: 10px auto;" :model="formConfig" ref="formConfigRef" :rules="rulesConfig">
      <a-form-item label="类型">
        <a-radio-group v-model:value="formConfig.type">
          <a-radio value="input" :disabled="from==='ganyu'">输入框</a-radio>
          <a-radio value="select">下拉框</a-radio>
          <a-radio value="textarea" :disabled="from==='ganyu'">文本框</a-radio>
          <a-radio value="copybtn">复制按钮</a-radio>
          <a-radio value="upload">上传图片</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="标题" name="label">
        <a-input placeholder="标题" :disabled="from==='ganyu'" v-model:value="formConfig.label" />
      </a-form-item>
      <a-form-item v-show="from!=='ganyu'" name="field_name">
        <template #label>
          对应字段
          <a-tooltip placement="top">
            <template #title>
              <span>需要对接服务端接口文档</span>
            </template>
            <span class="notice-icon">!</span>
          </a-tooltip>
        </template>
        <a-input placeholder="对应字段" :disabled="from==='ganyu'" v-model:value="formConfig.field_name" />
      </a-form-item>
      <a-form-item label="占位文案" v-show="from!=='ganyu'">
        <a-input placeholder="占位文案" :disabled="from==='ganyu'" v-model:value="formConfig.placeholder" />
      </a-form-item>

      <a-form-item label="复制文案" v-show="formConfig.type=='copybtn'">
        <a-input placeholder="复制文案" v-model:value="formConfig.copy_url" />
      </a-form-item>
      <a-form-item label="是否限制字数" v-show="formConfig.type!=='select'">
        <a-radio-group v-model:value="formConfig.isLimit">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
        <template v-if="formConfig.isLimit">
          <span>最大：</span>
          <a-input-number id="inputNumber" v-model:value="formConfig.inputMaxNumber" :min="1" />
          <span>最小：</span>
          <a-input-number id="inputNumber" v-model:value="formConfig.inputMinNumber" :min="1" />
        </template>
      </a-form-item>
      <div v-show="formConfig.type==='select'">
        <a-form-item label="数据来源">
          <a-radio-group v-model:value="formConfig.fromData">
            <a-radio value="custom">自定义</a-radio>
            <a-radio value="book" :disabled="from==='ganyu'">图书</a-radio>
            <a-radio value="director" :disabled="from==='ganyu'">商务</a-radio>
            <a-radio value="blogger" :disabled="from==='ganyu'">博主</a-radio>
          </a-radio-group>
        </a-form-item>
        <div v-show="formConfig.fromData==='custom'">
          <draggable :list="formConfig.options" item-key="index" tag="ul" handle=".drag" append-to-body>
            <template #item="{ element, index }">
              <a-form-item :label="'选项'+(index+1)">
                <div class="select-config-item">
                  <DragOutlined class="drag" style="font-size:20px;margin-left:5px;margin-right:10px;cursor: move;" />
                  <a-input placeholder="选项" v-model:value="element.name" />
                  <PlusCircleOutlined style="font-size:24px;margin-left:10px;cursor:pointer" @click="formConfig.options.push({name:''})" />
                  <MinusCircleOutlined style="font-size:24px;margin-left:10px;cursor:pointer" v-show="formConfig.options.length > 1" @click="formConfig.options.splice(index, 1)" />
                </div>
              </a-form-item>
            </template>
          </draggable>
        </div>
      </div>
      <a-form-item label="是否必填" v-if="from!=='ganyu'">
        <a-radio-group v-model:value="formConfig.isRequired">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="是否属于某项干预方式" v-show="hasIntervene && from!=='ganyu'">
        <a-radio-group v-model:value="formConfig.isBelongIntervene">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
        <a-select v-model:value="formConfig.intervene" v-show="formConfig.isBelongIntervene" style="width:200px;margin-left:10px">
          <a-select-option v-for="(item) in interveneList" :value="item" :key="item">{{item}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否可编辑">
        <a-radio-group v-model:value="formConfig.isEditabled">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="是否对应身份">
        <a-radio-group v-model:value="formConfig.isRole">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
        <a-checkbox-group v-show="formConfig.isRole" style="margin-top:10px" v-model:value="formConfig.roleList" name="checkboxgroup" :options="rolesLists" />
      </a-form-item>
      <a-form-item label="验证规则" v-show="from!=='ganyu'&&formConfig.type!=='select'">
        <a-radio-group v-model:value="formConfig.vertify">
          <a-radio value="">无</a-radio>
          <a-radio value="http">网站</a-radio>
          <a-radio value="mobile">手机</a-radio>
          <a-radio value="number">数字</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import draggable from "vuedraggable";
import { defineComponent } from 'vue'
import {LeftOutlined, DragOutlined, PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons-vue'
import request from '@/utils/request'
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    LeftOutlined,
    DragOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    draggable,
  },
  data(){
    return {
      rolesLists: [
        {label: '运营', value: 1},
        {label: '商务主管', value: 2},
        {label: '商务', value: 3},
        {label: '博主', value: 4},
        {label: '审核', value: 5},
      ],
      title: '新增',
      platform: '',
      hasIntervene: false,
      platformDesc: '',
      textResourceTip: '',
      videoResourceTip: '',
      imageResourceTip: '',
      interveneSort: 1,
      formAdd: {
        platform: ''
      },
      initConfigForm: {
        type: 'input',
        label: '',
        fromData: 'custom',
        isRequired: true,
        inputMinNumber: '',
        inputMaxNumber: '',
        isLimit: false,
        isBelongIntervene: false,
        options: [{}],
        intervene: '',
        isEditabled: true,
        isRole: false,
        roleList: [],
        field_name: '',
        placeholder: '',
        vertify: '',
        value: '',
        index: null,
      },
      formConfig: {},
      drag:false,
      rulesConfig: {
        label: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        field_name: [{ required: true, message: '请输入对应字段', trigger: 'blur' }]
      },
      currentData: null,
      currentIndex: null,
      openDia: false,
      configPreviewList: [],
      from: '',
      interveneList: [],

    }
  },
  created(){
    this.formAdd.platform = this.$route.query.platform
    this.resetForm()
  },
  async mounted(){
    await this.getPlatformInfo()
  },
  methods: {
    openEditDia(row,index) {
      this.openDia = true;
      this.currentIndex = index

      this.currentData = {...row}
      this.formConfig = {...row}
      if (row.options.length) {
        this.formConfig.options = row.options.map(v=>{
          let obj = {'name':v}
          return obj
        })
        console.log(this.formConfig);
      }
    },
    formatRoleList(list) {
      console.log(list);
      list = list.map(v=> {
        console.log(v, this.rolesLists.find(val=>val.value==v));
        return this.rolesLists.find(val=>val.value==v).label

      })
      return list.join('、')
    },
    formatType(type) {
      let res = ''
      switch(type) {
        case 'input':
          res = '输入框';
          break;
        case 'textarea':
          res = '多行文本框';
          break;
        case 'select':
          res = '下拉框';
          break;
        case 'copybtn':
          res = '复制按钮';
          break;
        case 'upload':
          res = '上传图片';
          break;
      }
      return res
    },
    formatDataSource(item) {
      if (item.type!=='select')return '无'
      else if (item.fromData==='blogger') return '博主'
      else if (item.fromData==='director') return '商务'
      else if (item.fromData==='book') return '图书'
    },
    async getPlatformInfo() {
      const {data} = await request.get(`/api/platforms/${this.$route.query.id}`)
      const {form_data} = data
      this.configPreviewList = form_data.formList
      this.interveneSort = form_data.interveneSort
      this.hasIntervene = form_data.hasIntervene
      this.platformDesc = form_data.platformDesc
      this.textResourceTip = form_data.textResourceTip
      this.videoResourceTip = form_data.videoResourceTip
      this.imageResourceTip = form_data.imageResourceTip
      this.interveneList = form_data.interveneList
      
    },
    formatBoolean(val) {
      return val ? '是' : '否'
    },
    addConfigItem(from='') {
      if (!this.formAdd.platform) {
        message.error('请选择平台')
        return
      }
      this.from = from
      if (this.from==='ganyu') {
        this.formConfig.type = 'select'
        this.formConfig.label='干预方式'
      }
      console.log(this.hasIntervene);
      this.openDia = true
    },
    resetForm() {
      console.log('reset');
      this.formConfig = {...this.initConfigForm}
      this.formConfig.options = [{}]
    },
    async handleConfirm() {
      
        
      this.$refs.formConfigRef.validate()
      .then(async () => {
        if (this.from==='ganyu') {
          this.interveneList = this.formConfig.options.map(v=>v.name)
          this.openDia = false
          this.resetForm()
        } else {
          if (this.formConfig.isBelongIntervene && !this.formConfig.intervene) {
            return message.error('请选择属于的干预方式')
          } else {
            this.formConfig.intervene = ''
          }
          if (this.formConfig.isRole && !this.formConfig.roleList.length) {
            return message.error('请选择对应身份')
          } else {
            // this.formConfig.roleList = []
          }
          if (this.formConfig.type==='select' && this.formConfig.fromData==='custom') {
            let list = this.formConfig.options.filter(v=>v.name)
            if ( !list.length) {
              return message.error('请添加选项')
            }
            this.formConfig.options = this.formConfig.options.map(v=>v.name)
            this.formConfig.isLimit = false
          } else {
            this.formConfig.options = []

          }
          if (this.formConfig.isLimit) {
            if ( !this.formConfig.inputMinNumber && !this.formConfig.inputMaxNumber) {
              return message.error('请填写限制字数')
            }
          } else {
            this.formConfig.inputMinNumber = ''
            this.formConfig.inputMaxNumber = ''
          }

          let params = {...this.formConfig}
          if (this.currentData) {
            this.configPreviewList[this.currentIndex] = params
          } else {
            this.configPreviewList.push(params)
          }
          this.currentData = null
          this.currentIndex = null
          this.openDia = false
          this.resetForm()
        }
      })
      .catch(error => {
        console.log('error', error);
      });
    },
    async saveConfig() {
      
      if (this.configPreviewList.length) {
        this.configPreviewList.forEach(v=> {
          if (!v.field_name) {
            message.error('有未填写的对应字段！')
            throw Error()
          }
        })
      }
      const params = {
        platform: this.formAdd.platform,
        interveneSort: this.interveneSort,
        hasIntervene: this.hasIntervene,
        platformDesc: this.platformDesc,
        textResourceTip: this.textResourceTip,
        videoResourceTip: this.videoResourceTip,
        imageResourceTip: this.imageResourceTip,
        interveneList: this.interveneList,
        interveneIndex: 0,
        formList: this.configPreviewList
      }

      console.log(params);
      await request.put('/api/platforms/'+this.$route.query.id, {form_data: params})

    }
  },
  setup() {
    return {
    };
  },
})
</script>
<style scoped>
.select-config-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.select-preview-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>