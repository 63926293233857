<template>
  <a-modal v-model:visible="visible" title="审核拒绝" :footer="null" >
   
      <a-form ref="formRef" :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
    
      <a-form-item   label="关键词">
          <a-input disabled v-model:value="formState.text" />
      </a-form-item>
      <a-form-item  required label="理由">
          <a-input  placeholder="请填写理由"  v-model:value="formState.reason"/>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }" >
          <a-button type="danger" html-type="submit" >确定拒绝</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import request from '@/utils/request'
import { message } from 'ant-design-vue';



export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      formState: {
        id: '',
        text: '',
        reason: ''
      }
    }
  },
  watch: {
    record: function(v1, v2) {
      this.formState.text = v1.text
      this.formState.id = v1.id
    }
  },
  methods: {
    async onSubmit(){
      if (!this.formState.reason) {
        return message.error("请填写拒绝原因")
      }
      await request.put(`/api/keywords/audit/${this.formState.id}`, {
            status: 2,
            reason: this.formState.reason
          })
      this.$emit("ok")
      this.$emit("cancel", false)
    },
    onCancle: function () {
      this.$emit("cancel", false)
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    return { 
      visible,
    };
  },
});
</script>