<template>
  <a-modal v-model:visible="visible" title="发布通知" :footer="null" >
      <a-form :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
       
    
        <a-form-item  label="发布平台">
          <a-select
            placeholder="请选择"
          v-model:value="formState.platform"
          >
          <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item required  label="通知内容">
            <a-textarea placeholder="通知内容" v-model:value="formState.content" />
        </a-form-item>
        <a-form-item required  label="发送对象">
          <a-radio-group name="radioGroup" v-model:value="formState.method">
            <a-radio value="1">条件发送</a-radio>
            <a-radio disabled value="2">指定对象</a-radio>
          </a-radio-group>
        </a-form-item>
        
        <a-form-item  required label="发布对象角色">
          <a-select
            placeholder="请选择"
            v-model:value="formState.target_role"
          >
          {{ UserRoleOptions }}
          <a-select-option :value='i.value' v-for='i of UserRoleOptions' :key='i.value' >
            {{i.text}}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item required  label="发布对象平台">
          <a-select
            placeholder="请选择"
            mode="multiple"
            v-model:value="formState.target_platforms"
          >
          <a-select-option :value='"所有"' :key='"0"' >
            所有
          </a-select-option>
          <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
        </a-form-item>
       
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
          <a-button html-type="submit" type="primary" >确认</a-button>
          <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
      </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import {UserRoleMap, PlatformEnum, UserRoleOptions} from '@/utils/macro'
import request from '@/utils/request'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'

const initialForm = {
  id: '',

  platform: '', // 运营专属
  content: '',
  target_role: '0',
  target_platform: '',
  target_platforms: [],
  method : '1',
}
let UserRoleList = Object.keys(UserRoleMap)
UserRoleList.shift()
export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      formState: {
        ...initialForm
      },
      UserRoleList,
      UserRoleMap,
      labelCol: { span: 8 },
      wrapperCol: { span: 10 },

      bloggerSuggestion:[],
     
    }
  },
  computed:{
  },
  mounted(){
    console.log("monted")
  },
  watch:{
    record: function(record, v2) {
      this.formState = {
        ...initialForm
      }

      for (let item in this.formState) {
        if (record[item]) {
          this.formState[item] =  record[item];
        }
      }
    }
  },
  methods: {
    async onSubmit(){
      if (!this.formState.content ||
        !this.formState.method) {
        return message.error("请填写必填信息")
      }
      let param = {
        ...this.formState,
        target_platform: this.formState.target_platforms.join(',')
      }
      param.method = +param.method
      if (this.formState.id) {
        await request.put(`/api/op_notifications/${this.record.id}`, param)
      } else {
        await request.post(`/api/op_notifications`,param )
      }
      this.$emit("ok")
    },
    onCancle: function () {
      this.$emit("cancel", false)
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    const store = useStore()
    return {
      PlatformEnum,
      UserRoleOptions,
      visible
    };
  },
});
</script>