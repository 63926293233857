<template>
  <a-card title="悬赏资源管理">
    <a-space>
      <a-form layout="inline" class="resource-form"  labelWidth="120" :model="formSearch" @keyup.enter="handleTableChange()" style="margin: 10px auto;"   >
        <a-form-item label="资源名称">
          <a-input placeholder="资源名称" v-model:value="formSearch.title" :allowClear="true" />
        </a-form-item>
        <a-form-item label="上传人">
          <a-input placeholder="上传人" v-model:value="formSearch.operator_id" :allowClear="true" />
        </a-form-item>
        <a-form-item label="关键词">
          <a-select
            v-model:value="formSearch.keyword_id"
            :options="keywordsSuggestion"
            @search="searchKeywords"
            show-search
            :filter-option="false"
            :not-found-content="null"
            :allowClear="true"
            placeholder="选择关键词"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-model:value="formSearch.category"
            :options="typeList"
            @change="handleTableChange()"
            placeholder="类型"
            :allowClear="true"
            style="width: 200px"
          ></a-select>
        </a-form-item>
        <a-form-item label="平台">
          <a-select v-model:value="formSearch.platform" @change="handleTableChange()" placeholder="平台" :allowClear="true" style="width: 200px">
            <a-select-option v-for="(item, index) in platformList" :value="item.name" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="上传时间">
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleTableChange()" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>

    </a-space>
    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="uploadResourceVisible=true">上传资源</a-button>
      <a-button type="primary"  style="margin-left:10px" @click="exportResources">导出资源</a-button>
     
      <a-dropdown-button type="primary" style="margin-left:10px">
        创建文本
        <template #overlay>
          <a-menu @click="handleCreateText">
            <a-menu-item key="1">
              创建文本
            </a-menu-item>
            <a-menu-item key="2">
              批量上传
            </a-menu-item>
            <a-menu-item key="3">
              模版下载
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown-button>
      <a-button type="primary" @click="deleteBatch" style="margin-left:10px">批量删除</a-button>

      <input ref='uploadTextInput' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeUploadText"/>
  
    </a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    @change="handleTableChange"
    :row-selection="rowSelection"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #resourceTitle="{ record }" >
        <pre style="white-space: pre-wrap;">{{record.title}}</pre>
      </template>
      <template #status="{ record }" >
        <span >{{formatStatus(record.status)}}</span>
      </template>
      <template #platforms="{ record }" >
        <span >{{record.platforms?.join(',')}}</span>
      </template>
      <template #category="{ record }" >
        <span >{{formatCategory(record.category)}}</span>
      </template>
      <template #keywords="{ record }" >
        <div v-for="(val, i) in record.keywords" :key="i" style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{val.text}}</div>
      </template>
      <template #packages="{ record }" >
        <div v-for="(val, i) in record.packages" :key="i" style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{val.title}}</div>
      </template>
      <template #online_status="{ record }" >
        <a-switch v-model:checked="record.online_status" :checkedValue="true" :unCheckedValue="false" @change="handleChangeStatus($event, record, index)" />
      </template>
      <template #text="{ record }" >
        <span v-if="record.category=='text'" style="cursor:pointer;"  @click="previewModel(record)">{{ record.text }}</span>
        <PictureOutlined v-if="record.category=='image'" style="cursor:pointer;font-size:20px;"  @click="previewModel(record)" />
        <PlaySquareOutlined v-if="record.category=='video'" style="cursor:pointer;font-size:20px;"  @click="previewModel(record)" />
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="previewModel(record)" >预览</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" @click="deleteSource(record)" >删除</a-button>
        </span>
      </template>
    </a-table>
  </a-card>

  <UploadText  v-model:visible="uploadTextVisible"  @cancel="uploadTextVisible = false" @ok="handleUploadText" />
  <UploadResourceModel  v-model:visible="uploadResourceVisible"  @ok="handleUploadResource" @refresh="handleTableChange" />
  <PreviewModel v-model:visible="previewOpen"  @ok="previewOpen=false" :record="previewData" />

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined, PictureOutlined, PlaySquareOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, CREATE_RESOURCE_PACKAGE } from '@/utils/macro'
import { Modal, message, Upload } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import OSS from 'ali-oss'
import UploadText from './components/UploadText.vue'
import UploadResourceModel from './components/UploadResourceModel.vue'
import PreviewModel from './components/PreviewModel.vue'

let _searchKeywordsDebounce = null

export default defineComponent({
  components: {
    SearchOutlined,
    UploadText,
    UploadResourceModel,
    PreviewModel,
    PictureOutlined,
    PlaySquareOutlined,
  },
  data(){
    return {
      selectedRowKeys: [],
      keywordsSuggestion: [],
      UserRoleMap,
      uploadLoading: false,
      uploadTextVisible: false,
      uploadResourceVisible: false,
      platformList: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      loading: false,
      dataSource: [],
      noticeMessage: '',
      formSearch: {
        title: '',
      },
      openDia: false,
      confirmLoading: false,
      currentData: null,
      fileList: [],
      loadingImage: false,
      imageUrl: '',
      formAdd: {
        platform: '',
      },
      rules: {
        platform: [{ required: true, message: '请选择平台', trigger: 'change' }],
      },
      previewData: {},
      previewOpen: false,
      typeList: [
        {
          value: 'video',
          label: '视频'
        }, {
          value: 'image',
          label: '图片'
        }, {
          value: 'text',
          label: '文本'
        }
      ],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
        }
      },
    }
  },
  mounted(){
    if (this.user_role !== 1 && !this.daifa_perms) {
      return
    }
    this.handleTableChange()
    this.getPlatforms()
  },
  filter: {
// 1 已上传
// 2 转码中
// 3 转码失败
// 4 转码成功
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    daifa_perms: () => {
      const store = useStore()
      return store.state.user.perms && store.state.user.perms.includes(CREATE_RESOURCE_PACKAGE)
    }
  },
  methods: {
    moment,
    searchKeywords(text) {
      clearTimeout(_searchKeywordsDebounce)
      _searchKeywordsDebounce = setTimeout(() => {
        request.get(`/api/keywords`, {
          text: text
        }).then((rsp) => {
          this.keywordsSuggestion = []
          for (let v of rsp.data.list) {
            this.keywordsSuggestion.push({
              id: v.id,
              value: v.id,
              label: v.text,
            })
          }
        })
      }, 600)
    },
    handleUploadText(val) {
      if (typeof val == 'boolean') {
        this.uploadTextVisible = val
        this.handleTableChange()
      }
    },
    handleUploadResource(val) {
      if (typeof val == 'boolean') {
        this.uploadResourceVisible = val
        this.handleTableChange()
      }
    },
    deleteBatch() {
      if (!this.selectedRowKeys.length) {
        return message.error('请选择')
      }
      let that = this;
      Modal.confirm({
        title: `删除后不可恢复，确认继续删除吗？`,
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            that.selectedRowKeys.forEach(async (v, i, arr)=> {
              const rsp = await request.delete('/api/resources/'+v)
              if (i==arr.length-1) {
                that.handleTableChange(that.pagination)
                Modal.destroy();
              }
            })
          } catch(err) {
            console.log(err);
          }
        },
      });
    },
    formatCategory(category) {
      let res = category
      switch(res){
        case 'video':
          res = '视频';
          break;
        case 'image':
          res = '图片';
          break;
        case 'text':
          res = '文本';
          break;
      }
      return res
    },
    formatStatus(status) {
      let res = ''
      switch(status){
        case 1:
          res = '已上传';
          break;
        case 2:
          res = '转码中';
          break;
        case 3:
          res = '转码失败';
          break;
        case 4:
          res = '转码成功';
          break;
      }
      return res
    },
    async handleChangeStatus(value, item, index) {
      try {
        const rsp = await request.post(`/api/resources/${item.id}/online`, { online_status: value })
        this.handleTableChange(this.pagination)
      } catch(err) {
        this.dataSource[index].online_status = value?false:true
      }
    },
    async getPlatforms () {
      const rsp = await request.get('/api/platforms')
      this.platformList = rsp.data.platforms
    },
    genQuery (){
      let q = this.formSearch
      let querys = []
      for (let k in q) {
        querys.push(`${k}=${encodeURIComponent(q[k])}`)
      }
      return querys.join('&')
    },
    async exportResources () {
      window.open("/api/resources?is_export=1&" + this.genQuery())
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/resources', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
        })
        this.loading = false
        this.dataSource = rsp.data.list
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    deleteSource(initial = {}) {
      let that = this;
      Modal.confirm({
        title: initial.keywords?`该文件已关联关键词，删除后不可恢复，确认继续删除吗？`:'确认删除？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            const rsp = await request.delete('/api/resources/'+initial.id)
            that.handleTableChange(that.pagination)
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    async previewModel(initial = {}) {
      this.previewData = {...initial}
      this.previewOpen = true
    },
    openUploadDia() {
      this.openDia = true
    },
    handleCreateText(e) {
      if (e.key == "1") { // 弹窗创建
        this.uploadTextVisible = true
      } else if (e.key=='2') {
        this.uploadTextInput.click()
      } else if (e.key=='3') {
        window.open("https://wwbkbm5zi2g.feishu.cn/sheets/W1PssZadWhGWBkt3rflcWSrlnRT")
      }
    },
    onChangeUploadText(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/resources/import-text`, files[0]).then((rsp) => {
          this.showErrorModal(rsp.data.fails)
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
          this.uploadLoading = false
        })
        this.uploadTextInput.value = null
      }
    },
    showErrorModal(fails) {
      if(fails && fails.length > 0 ){
        let vnode = []
        for (let o of fails) {
          vnode.push(<div>{o}</div>)
        }
        Modal.error({
          title: "发生错误",
          content: () => <div >{vnode}</div>,
        });
      }
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };

    return {
      uploadTextInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '资源名称',
          dataIndex: 'title',
          key: 'title',
          slots: { customRender: 'resourceTitle' },
          width: 120,
        },
        {
          title: '类型',
          dataIndex: 'category',
          key: 'category',
          slots: { customRender: 'category' },
          width: 120,
        },
        {
          title: '资源内容',
          dataIndex: 'text',
          key: 'text',
          slots: { customRender: 'text' },
          ellipsis: true,
          width: 120,
        },
        {
          title: '资源包名称',
          dataIndex: 'packages',
          key: 'packages',
          slots: { customRender: 'packages' },
          width: 120,
        },
        {
          title: '平台',
          dataIndex: 'platforms',
          key: 'platforms',
          slots: { customRender: 'platforms' },
          width: 150,
        },
        {
          title: '关键词',
          dataIndex: 'keywords',
          key: 'keywords',
          slots: { customRender: 'keywords' },
          width: 150,
        },
        {
          title: '上传时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '上传人',
          dataIndex: 'operator',
          key: 'operator',
          width: 120,
        },
        {
          title: '上传状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 120,
        },
        {
          title: '资源状态',
          dataIndex: 'online_status',
          key: 'online_status',
          slots: { customRender: 'online_status' },
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.resource-form.ant-form-inline .ant-form-item {
  margin-bottom: 10px;
}
</style>