
import MobileLayout from '@/layouts/MobileLayout.vue'
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    MobileLayout,
  },
})
export default class Home extends Vue {}
