
import { defineComponent, reactive, toRaw } from 'vue';
import type { UnwrapRef } from 'vue';
import request from '@/utils/request'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { message } from 'ant-design-vue';
import md5 from 'js-md5'
import { isMobile } from '@/utils/client'

interface FormState {
  username: string;
  password: string;
}
export default defineComponent({
  setup() {
    const formState: UnwrapRef<FormState> = reactive({
      username: '',
      password: '',
    });
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    if (!isMobile()) {
        router.push({
          path: '/'
        })
        return {
          formState
        }
    }

    const onSubmit = async () => {
      const ret: any = await request.post('/api/login', {
        username: formState.username,
        password: md5(formState.password)
      })
      if (ret.data && ret.data.user_id) {
        message.success("登录成功")
        store.commit("setLogin", ret.data)
        router.push({
          path: '/mobile/keyword'
        })
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formState,
      onSubmit,
    };
  },
});
