<template>
  <a-card title="会员码管理">
    <a-tabs v-model:activeKey="activeKey" type="card" @change="handleTableChange({pageSize: 10, current: 1})">
      <a-tab-pane key="2,3,4" tab="夸克" />
      <a-tab-pane key="5,6" tab="迅雷" />
    </a-tabs>
    <a-space   >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="类型">
          <a-select style="width: 120px;" v-model:value="formSearch.code_type" :allowClear="true" >
            <a-select-option :value='item.type' :key='index' v-for="(item, index) in codeTypeList" >{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item  label="状态">
          <a-select style="width: 120px;" v-model:value="formSearch.status" :allowClear="true" >
            <a-select-option :value='1' :key='1' >已开通</a-select-option>
            <a-select-option :value='0' :key='0' >未开通</a-select-option>
          </a-select>
        </a-form-item>
       
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="handleImport">导入</a-button>
      <a-button type="primary" @click="openBannerDia(null)" style="margin-left: 10px;"><a href="https://wwbkbm5zi2g.feishu.cn/sheets/RHhds1M02hrcXutCZY9cMYgpnif" target="_blank">模板预览</a></a-button>
      <a-spin v-if="uploadLoading" />
      <input ref='uploadInput' style="visibility: hidden;" type= "file" class="dl-none" name="icon" @change="onChangeFile"/>
    </a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #code_type="{ record }">
        <span>{{ formatCodeType(record.code_type) }}</span>
      </template>
      <template #status="{ record }">
        <span>{{ record.status===1?'已开通':'未开通' }}</span>
      </template>
      <template #code="{ record }">
        <span>{{ record.code.slice(0, 5) + '***' }}</span>
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #activated_at="{ record }" >
        <span >{{record.activated_at===0?'-':moment(record.activated_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
    </a-table>
  </a-card>

</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';



export default defineComponent({
  components: {
    SearchOutlined
  },
  data(){
    return {
      UserRoleMap,
      activeKey: '2,3,4',
      uploadLoading: false,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        code_type: '',
        result: ''
      },
      codeTypeList: [
        // {type: 1, name: 'UC会员'},
        {type: 2, name: '夸克1个月'},
        {type: 3, name: '夸克3个月'},
        {type: 4, name: '夸克12个月'},
        {type: 5, name: '迅雷1个月'},
        {type: 6, name: '迅雷3个月'},
      ]
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      console.log(this.formSearch);
      const rsp = await request.get('/api/vip_code/codes', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        code_type: this.formSearch.code_type || this.activeKey,
        status: this.formSearch.status,
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    formatCodeType(type) {
      return this.codeTypeList.find(v=>v.type===type).name
    },
    handleImport() {
      this.$refs.uploadInput.click()
    },
    async onChangeFile(e){
      let files = e.target.files;
      if(files){
        this.uploadLoading = true
        request.upload(`/api/vip_code/import`, files[0] ).then((rsp) => {
          message.success(`成功更新 ${rsp.data.count} 条，失败: ${rsp.data.fails.join(",")}`)
          this.handleTableChange()
          this.uploadLoading = false
        })
      }
    }
  },
  setup() {
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '类型',
          dataIndex: 'code_type',
          key: 'code_type',
          slots: { customRender: 'code_type' },
          width: 130,
        },
        {
          title: '会员码',
          dataIndex: 'code',
          key: 'code',
          slots: { customRender: 'code' },
          width: 130,
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 120,
        },
        {
          title: '领取时间',
          dataIndex: 'activated_at',
          key: 'activated_at',
          slots: { customRender: 'activated_at' },
          width: 180,
        },
        {
          title: '领取账号',
          dataIndex: 'username',
          key: 'username',
          width: 180,
        },
        // {
        //   title: '操作',
        //   key: 'action',
        //   fixed: 'right',
        //   width: 200,
        //   slots: { customRender: 'action' },
        // },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>