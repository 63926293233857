<template>
  <div>
    <a-form ref="formRef" name="dynamic_form_nest_item" :model="dynamicValidateForm" >
      <div v-for="(sight, index) in dynamicValidateForm.sights" :key="sight.id" style="display: flex; margin-bottom: 8px" align="baseline" >
        <a-form-item
          :name="['sights', index, 'name']"
          label="名称"
          style="width:45%;margin-right:10px;"
          :rules="{ required: true, message: '请输入名称', }" >
          <a-input v-model:value="sight.name" />
        </a-form-item>
        <a-form-item
          label="文本"
          style="width:45%;margin-right:10px;"
          :name="['sights', index, 'text']"
          :rules="{ required: true, message: '请输入文本', }" >
          <a-textarea v-model:value="sight.text" />
        </a-form-item>
        <a-form-item >
          <MinusCircleOutlined style="font-size:24px" @click="removeSight(sight)" />
        </a-form-item>
      </div>
      <a-form-item>
        <a-button type="dashed" block @click="addSight"><PlusOutlined />添加</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import { Modal, message, Upload } from 'ant-design-vue';
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    MinusCircleOutlined,
    PlusOutlined,
  },
  data() {
    return {
      dynamicValidateForm: {
        sights: [
        //   {
        //   id: Date.now(),
        //   name: '',
        //   text: ''
        // }
      ]
      }
    }
  },
  watch: {

  },
  methods: {
    addSight() {
      this.dynamicValidateForm.sights.push({
        name: undefined,
        text: undefined,
        id: Date.now(),
      });
    },
    removeSight(item) {
      const index = this.dynamicValidateForm.sights.indexOf(item);
        if (index !== -1) {
          this.dynamicValidateForm.sights.splice(index, 1);
        }
    }
  }
})
</script>
<style lang='scss' scoped>

</style>