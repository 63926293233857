<template>
  <a-modal v-model:visible="visible" @cancel="handleCancel"  :title="record.is_valid==1?'有效博主':'新增博主'"  :footer="null" width="60%">

    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="商务名称">
          <a-select
            v-model:value="formSearch._director_ids"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="商务名称"
            style="width: 200px"
          />
        </a-form-item>
        <a-form-item label="博主注册时间" >
          <a-range-picker v-model:value="formSearch.date_range" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
    </a-table>

  
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import request from '@/utils/request'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons-vue';
import { Modal, message } from 'ant-design-vue';

let _searchDirectorDebounce = null 

export default defineComponent({
  components: {SearchOutlined},
  props: {
    record: Object
  },
  data(){
    return {
      directorSuggestion: [],
      initial: {},
      bloggerVisible: false,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        director_id: '',
        date_range: '',
      },
    }
  },
  watch:{
    record(newVal, oldVal) {
      if (newVal) {
        console.log(newVal, newVal.is_valid);
        this.handleTableChange()
      }
    },
  },
  methods: {
    moment,
    handleCancel() {
      this.formSearch = {}
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      console.log(123123123,this.record);
      this.loading = true
      try {
        let date_range = ''
        if (this.formSearch.date_range && this.formSearch.date_range.length) {
          date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        } else {
          date_range = this.record.stats_date+','+this.record.stats_date
        }
        const rsp = await request.get('/api/manager_leader/sub/summary/blogger/detail', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          manager_id: this.record.manager_id,
          ...this.record,
          ...this.formSearch,
          date_range,

        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    openDetailDia(row) {
      this.initial = {...row}
      this.bloggerVisible = true
    },
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_ids = [this.formSearch._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_id = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/director_core/sub/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    console.log('record', visible, record);
    watch(props, (newProps, oldProps) => {
      console.log(newProps);
    });
    return { 
      visible,
      columns: [
        {
          title: '商务主管名称',
          dataIndex: 'manager_name',
          key: 'manager_name',
        },
        {
          title: '商务名称',
          dataIndex: 'director_name',
          key: 'director_name',
        },
        {
          title: '博主数量',
          dataIndex: 'blogger_count',
          key: 'blogger_count',
        },
        {
          title: '统计日期',
          dataIndex: 'stats_date',
          key: 'stats_date',
        },
      ],
    };
  },
});
</script>