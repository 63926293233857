<template>
  <a-card title="配置主管关系">

    <a-row style="margin-bottom:10px"><a-button type="primary" @click="bindVisible=true">新增绑定关系</a-button></a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="false"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0;color:red" @click="confirmUnbind(record)" >解绑</a-button>
        </span>
      </template>
    </a-table>


    <BindDia v-model:visible="bindVisible"  @cancel="bindVisible = false" @confirm="handleTableChange" />
  

  </a-card>

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import BindDia from './components/BindDia.vue'


let UserRoleList = Object.keys(UserRoleMap).map(v=> {
  return {
    label: UserRoleMap[v],
    value: v
  }
})
export default defineComponent({
  components: {BindDia},
  data(){
    return {
      bindVisible: false,
      UserRoleList,
      UserRoleMap,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      confirmLoading: false,
      currentData: null,
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/manager_leader/bound/managers', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    resetFresh() {
      this.handleTableChange()
    },
    confirmUnbind(initial) {
      console.log(initial);
      let that = this;
      Modal.confirm({
        title: '确认解绑？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            const rsp = await request.delete(`/api/manager_leader/bound/managers`, {...initial})
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '商务主管 Leader',
          dataIndex: 'leader_name',
          key: 'leader_name',
        },
        {
          title: '商务主管',
          dataIndex: 'manager_name',
          key: 'manager_name',
        },
        {
          title: '绑定时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>