<template>
  <a-modal v-model:visible="visible" title="数据信息" >
   
      <a-table :dataSource="dataSource" :columns="columns" :pagination="false" >
        <template #created_at="{ record }" >
          <span >{{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
        </template>
      </a-table>
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';

export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {}
  },
  methods: {
    moment,
  },
  setup(props, context) {
    let dataSource = ref([])
    const { visible, record } = toRefs(props)
    watch(props, (newProps, oldProps) => {
      dataSource.value = newProps.record
    });
    
    return { 
      dataSource,
      columns:[
        {
          title: "投放日期",
          dataIndex: 'stats_date',
          key: 'stats_date'
        },
        {
          title: "数量",
          dataIndex: 'stats_count',
          key: 'stats_count'
        },
        {
          title: "收费收益",
          dataIndex: 'fee_profit',
          key: 'fee_profit'
        },
        {
          title: "其他费用",
          dataIndex: 'extra_data',
          key: 'extra_data'
        },
         {
          title: "创建时间",
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
        },
      ],
      visible,
    };
  },
});
</script>