<template>
  <a-card title="商务主管Leader 管理">
    <a-row><a-button type="primary" @click="bindVisible=true">新增商务主管Leader</a-button></a-row>
    <a-space v-if="user_role==1">
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="用户名">
          <a-input placeholder="用户名" @keyup.enter="handleTableChange" v-model:value="formSearch.username" :allowClear="true" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange"
            shape="circle"
          >
            <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="pagination"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"></a-table>

    <AddManagerLeaderDia v-model:visible="bindVisible"  @cancel="bindVisible = false" @confirm="handleTableChange" />
  

  </a-card>

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import AddManagerLeaderDia from './components/AddManagerLeaderDia.vue'

let _searchDirectorCoreDebounce = null 

let UserRoleList = Object.keys(UserRoleMap).map(v=> {
  return {
    label: UserRoleMap[v],
    value: v
  }
})
export default defineComponent({
  components: {
    SearchOutlined,AddManagerLeaderDia
  },
  data(){
    return {
      bindVisible: false,
      UserRoleList,
      UserRoleMap,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      confirmLoading: false,
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      formSearch: {username:''},
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
        const rsp = await request.get('/api/manager_leader/suggestion', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data?.pagination?.total || 0} 条`
        this.pagination.total = rsp.data?.pagination?.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    resetFresh() {
      this.handleTableChange()
    },
    setUserDirector(initial) {
      console.log(initial);
      let that = this
      Modal.confirm({
        title: `是否确认设置 ${initial.username} 为商务主管Leader？`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          const rsp = await request.post(`/api/manager_leader/set/${initial.id}`)
          console.log(rsp);
          that.handleTableChange()
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
  },
  setup() {
    
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '用户ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username',
        },
      ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>