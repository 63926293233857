<template>
  <a-card title="收入明细">
    <div>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" :label-col="{ style: { width: '80px' } }" >
        <a-row :gutter="24" style="width:100%">
          <a-col :span="6">
            <a-form-item label="收入明细ID" >
              <a-input placeholder="收入明细ID" v-model:value="formSearch.payment_id" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="申请ID" >
              <a-input placeholder="申请ID" v-model:value="formSearch.apply_id" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="付款批次ID" >
              <a-input placeholder="付款批次ID" v-model:value="formSearch.batch_id" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="用户ID" >
              <a-input placeholder="收款人的用户ID" v-model:value="formSearch.user_id" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row  style="width:100%;margin-top:10px">
          <a-col :span="6">
            <a-form-item label="付款名称" >
              <a-input placeholder="付款名称" v-model:value="formSearch.title" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="产品">
              <a-select
                v-model:value="formSearch.platform"
                :options="platformsSuggestion"
                placeholder="产品"
                style="width:150px"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="上传日期" >
              <a-range-picker v-model:value="formSearch.date_range" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-button
                type="primary"
                @click="searchQuery"
                shape="circle"
              >
              <template #icon><SearchOutlined /></template>
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="handleExport">
        <template #icon>
          <DownloadOutlined />
        </template>
        导出全部
      </a-button>
    </a-row>
      <a-tabs type="card" v-model:activeKey="formSearch.payment_status" @change="handleChangeTab">
        <a-tab-pane key="" :tab="'全部'"></a-tab-pane>
        <a-tab-pane :tab="`${key}(${statusCounts[value] || 0})`" v-for="(key,value) in PaymentTransactionStatusMap" :key="value"></a-tab-pane>
      </a-tabs>

    </div>
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #audit_status="{ record }" >
        <span>{{ PaymentApplyAuditStatusMap[record.audit_status] }}</span>
      </template>
      <template #payment_status="{ record,index }" >
        <a-tag v-if="index>0" :color="PaymentTransactionStatusColorMap[record.payment_status]">{{ PaymentTransactionStatusMap[record.payment_status] }}</a-tag>
      </template>
      <template #created_at="{ record }" >
        <span >{{record.created_at?moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss'):''}}</span>
      </template>
      <template #action="{ record, index }" >
        <!-- <a-button type="primary" size="small" @click="onCheckChannels(record)">查看付款明细</a-button> -->
        <!--  class="hover-link" -->
        <router-link v-if="index > 0" :to="{name:'PaymentDetail',query:{payment_id:record.id}}" target="_blank">
          <!-- 查看付款明细 -->
          <a-button type="primary" size="small">查看付款单</a-button>
        </router-link>
      
      </template>
    </a-table>
  </a-card>

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined,DownloadOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap, PaymentApplyAuditStatusMap, PaymentTransactionStatusMap, PaymentTransactionStatusColorMap, PlatformSug } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';

const renderContent = ({ text, index }) => {
  const obj = {
    children: text,
    props: {},
  };
  if (index === 0) {
    obj.props.colSpan = 0;
  }
  return obj;
};

export default defineComponent({
  components: {
    SearchOutlined,
  },
  data(){
    return {
      UserRoleMap,
      PaymentApplyAuditStatusMap,
      PaymentTransactionStatusMap,
      PaymentTransactionStatusColorMap,
      platformsSuggestion: [].concat(PlatformSug),
      loading: false,
      uploadLoading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 50,
        // 'show-size-changer': true,
        pageSizeOptions: ['10', '50', '100', '200']
      },
      noticeMessage: '',
      formSearch: {
        user_id: '',
        date_range: '',
        payment_status: '',
      },
      statusCounts: {},
      totalAmount: {},
    }
  },
  mounted(){
    if (this.$route.query.apply_id) {
      this.formSearch.apply_id = this.$route.query.apply_id
    }
    if (this.$route.query.batch_id) {
      this.formSearch.batch_id = this.$route.query.batch_id
    }
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 50, current: 1}) {
      this.loading = true
      try {
        let date_range = ''
        if (this.formSearch.date_range && this.formSearch.date_range.length) {
          date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        }
        const rsp = await request.get('/api/payments/incomes', {
          offset: (pagi.current - 1) * pagi.pageSize - 1,
          limit: pagi.pageSize,
          ...this.formSearch,
          date_range,
        })
        this.loading = false

        this.dataSource = rsp.data.list? [{id: '总计',amount:rsp.data.total_amount}].concat(rsp.data.list) : []
        this.statusCounts = rsp.data.status_counts
        this.totalAmount = rsp.data.total_amount
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize+1
      } catch(err) {
        this.loading = false
      }
    },
    handleExport() {
      let date_range = ''
      if (this.formSearch.date_range && this.formSearch.date_range.length) {
        date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
      }
      let params={
        ...this.formSearch,
        date_range,
      }
      let urlParams = ''
      for(let key in params) {
        console.log(params, key);
        urlParams += '&'+key+'='+params[key]
      }
      window.open(`/api/payments/incomes?is_export=1${urlParams}`)
      return
    },
    handleChangeTab() {
      this.handleTableChange()
    },
    searchQuery() {
      this.handleTableChange()
    },
  },
  setup() {
    

    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '付款名称',
          dataIndex: 'title',
          key: 'title',
          width: 120,
        },
        {
          title: '开始日期',
          dataIndex: 'start_date',
          key: 'start_date',
          width: 120,
        },
        {
          title: '结束日期',
          dataIndex: 'end_date',
          key: 'end_date',
          width: 120,
        },
        {
          title: '产品',
          dataIndex: 'platform',
          key: 'platform',
          width: 120,
        },
        {
          title: '收款人',
          dataIndex: 'username',
          key: 'username',
          width: 120,
        },
        {
          title: '金额',
          dataIndex: 'amount',
          key: 'amount',
          width: 120,
        },
        {
          title: '付款状态',
          dataIndex: 'payment_status',
          key: 'payment_status',
          slots: { customRender: 'payment_status' },
          width: 120,
        },
        {
          title: '提交人用户名',
          dataIndex: 'applicant',
          key: 'applicant',
          width: 120,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: 120,
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 120,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 120,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped lang="less">
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.count-num-table {
  margin-bottom: 10px;
  td {
    border: 1px solid #ddd;
    min-width:120px;
    text-align: center;
  }
}
</style>