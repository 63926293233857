<template>
  <a-modal width="80%"  v-model:visible="visible" title="发布链接"  @ok="handleOk">
    <a-table :dataSource="dataSource" :columns="columns" :pagination="false" >
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #images="{ record }">
        <a-image-preview-group>
            <a-image
            :width="30"
            :src="item" v-for="item in record.images" :key="item"
          />
        </a-image-preview-group>
      </template>
        <template #action="{ record, index }" >
        <span>
          <a-space>
              <a v-if="dataSource.length == 1 && keyword.stats_count == 0" @click="onEdit(record)" >编辑</a>
              <a v-if="dataSource.length > 1 && keyword.stats_count == 0" style="color: red;" @click="onDelete(record, index)">删除</a>
          </a-space>
        </span>
      </template>
    </a-table>
    <PublishEditor :visible="publishEditorVisible"  @ok="onEditSuccess" @cancel="onEditSuccess" :record="publishEditorData"  ></PublishEditor>
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import moment from 'moment';
import request from '@/utils/request'
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import PublishEditor from './PublishEditor.vue';

export default defineComponent({
  props: {
    record: Object
  },
  components:{
    PublishEditor
  },
  data(){
    return {
      dataSource : [],
      keyword: {},
      publishEditorVisible: false,
      publishEditorData: null,
    }
  },
  watch:{
    record(n, o) {
      this.load()
      this.keyword = this.record
    },
  },
  methods: {
    handleOk() {
      this.$emit('cancel')
    },
    async load(){
      let res = await request.get(`/api/keywords/${this.record.id}/published`, {})
      this.dataSource = res.data
    },
    onEditSuccess(){
      this.publishEditorVisible = false
      this.load()
    },
    onEdit(record){
      this.publishEditorData = {
        ...record,
        id: record.keyword_id,
        publish_id: record.id,
        account_id: record.blogger_account_id,
        blogger_id: record.user_id,
      }
      this.publishEditorVisible = true
    },
    async onDelete(item, index){
      console.log(index)
      Modal.confirm({
        content: () => `确认删除 ${item.published_url} 吗？操作不可恢复`,
        onOk: () => {
           request.delete(`/api/keywords/${item.keyword_id}/published`, {
            published_url : item.published_url,
            published_id : item.id,
           })
           this.dataSource[index].published_url = "[已删除]"
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    moment,
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    return { 
      columns:[
        {
          title: "平台",
          dataIndex: 'platform',
          key: 'platform',
          width: '100px'
        },
        {
          title: "地址",
          dataIndex: 'published_url',
          key: 'published_url',
          width: '150px'
        },
        {
          title: "推广截图",
          dataIndex: 'images',
          key: 'images',
          slots: { customRender: 'images' },
          width: '150px'
        },
        {
          title: "类型",
          dataIndex: 'artwork_type',
          key: 'artwork_type',
          width: '80px'
        },
        {
          title: "账号",
          dataIndex: 'blogger_account',
          key: 'blogger_account',
        },
        {
          title: "发布日期",
          dataIndex: 'published_date',
          key: 'published_date',
        },
        {
          title: "发布时间",
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
        },
        {
          title: "操作",
          dataIndex: 'action',
          key: 'action',
          width: '80px',
          slots: { customRender: 'action' },
        }
      ],
      visible,
    };
  },
});
</script>