<template>
  <a-upload
    v-model:file-list="fileList"
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :customRequest="handleUpload"
    :before-upload="beforeUpload"
  >
    <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="height:100px" />
    <div v-else>
      <loading-outlined v-if="loadingImage"></loading-outlined>
      <plus-outlined v-else></plus-outlined>
      <div class="ant-upload-text">点击上传</div>
    </div>
  </a-upload>
</template>
<script>

import { defineComponent, reactive, ref } from 'vue'
import request from '@/utils/request'
import { Modal, message } from 'ant-design-vue';
import OSS from 'ali-oss'

export default defineComponent({
  props: {
    imageUrl: {
      type: String,
      default: '',
    }
  },
  data(){
    return {
      fileList: [],
      loadingImage: false,
      // imageUrl: '',
    }
  },
  mounted() {
    // this.imageUrl = this.modelValue
  },
  methods: {
    async handleUpload(file) {
      if (!file.file) {
          message.error("请选择文件")
          return
      }
      this.loadingImage = true
      // /api/aliyun/sts
      const sts = await request.post(`/api/aliyun/sts`, {})
      if (sts.error) {
        message.error(`存储服务授权失败:` + sts.error.message)
        return
      }
      const client =  new OSS({
          accessKeyId: sts.data.AccessKeyId,
          accessKeySecret: sts.data.AccessKeySecret,
          stsToken: sts.data.SecurityToken,
          bucket: 'applet-promotion',
          region: 'oss-cn-beijing'
      });
      const name = `/keyword-image/${file.filename}-${Date.now()}.${file.file.type.split('/')[1]}`
      const result = await client.multipartUpload(name, file.file);
      var url = "http://applet-promotion.oss-cn-beijing.aliyuncs.com/" // 待定
      if (result && result.name) {
          url = url + result.name
      }
      // this.imageUrl = url
      this.$emit('update:imageUrl', url)
      this.loadingImage = false
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Image must smaller than 10MB!');
      }
      return isJpgOrPng && isLt2M;
    },
  },
})
</script>
<style lang='scss' scoped>

</style>