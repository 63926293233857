<template>
  <a-modal v-model:visible="visible" title="平台明细"  :footer="null" width="80%">

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #action="{ record }" >
        <span>
          <a-button type="link" style="padding:0" @click="openBloggerDetailDia(record)" >博主明细</a-button>
        </span>
      </template>
    </a-table>
      

    <BloggerDia v-model:visible="bloggerVisible"  @cancel="bloggerVisible = false" :record="initial" />
  
  </a-modal>
 
</template>

<script >
import { defineComponent, ref, toRefs, watch} from 'vue';
import request from '@/utils/request'
import moment from 'moment';
import BloggerDia from './BloggerDia.vue'

export default defineComponent({
  components: {BloggerDia},
  props: {
    record: Object
  },
  data(){
    return {
      initial: {},
      bloggerVisible: false,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
    }
  },
  watch:{
    record(newVal, oldVal) {
      if (newVal) {
        this.handleTableChange()
      }
    },
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      console.log(123123123,this.record);
      this.loading = true
      try {
        // let date_range = ''
        // if (this.formSearch.date_range && this.formSearch.date_range.length) {
        //   date_range = moment(this.formSearch.date_range[0]).format('YYYY-MM-DD')+ ','+moment(this.formSearch.date_range[1]).format('YYYY-MM-DD')
        // }
        const rsp = await request.get('/api/director_core/sub/detail/platform', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.record,
          // platform: this.record.platform,
          // director_id: this.record.director_id,
          date_range: this.record.stats_date+','+this.record.stats_date
        })
        this.loading = false
        this.dataSource = rsp.data.list? [].concat(rsp.data.list) : []
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
      }
    },
    openBloggerDetailDia(row) {
      this.initial = {...row,director_core_id:this.record.director_core_id}
      this.bloggerVisible = true
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    console.log('record', visible, record);
    watch(props, (newProps, oldProps) => {
      console.log(newProps);
    });
    return { 
      visible,
      columns: [
        {
          title: '商务名称',
          dataIndex: 'director_name',
          key: 'director_name',
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
        },
        {
          title: '拉新数量',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
        },
        {
          title: '拉新时间',
          dataIndex: 'stats_date',
          key: 'stats_date',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
});
</script>