<template>
  <a-card title="举报抄袭处理">
    <a-tabs v-model:activeKey="activeKey" type="card" @change="handleTableChange({pageSize: 10, current: 1})">
      <a-tab-pane :key="0" tab="未处理" />
      <a-tab-pane :key="1" tab="已处理" />
    </a-tabs>
    <a-space   >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item label="提交人账号ID">
          <a-input placeholder="" v-model:value="formSearch.user_id" />
        </a-form-item>
       
        <a-form-item>
          <a-button
            type="primary"
            @click="handleTableChange({pageSize: 10, current: 1})"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #status="{ record }">
        <span>{{ record.status===1?'已处理':'未处理' }}</span>
      </template>
      <template #created_at="{ record }" >
        <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #origin_img="{ record }" >
        <div class="report-images">
          <!-- <img :src="item" v-for="item in formatImage(record.origin_img)" :key="item" /> -->
          <a-image-preview-group>
              <a-image
              :width="80"
              :src="item" v-for="item in formatImage(record.origin_img)" :key="item"
            />
          </a-image-preview-group>
        </div>
      </template>
      <template #target_img="{ record }" >
        <div class="report-images">
          <!-- <img :src="item" v-for="item in formatImage(record.target_img)" :key="item" /> -->
          <a-image-preview-group>
              <a-image
              :width="80"
              :src="item" v-for="item in formatImage(record.target_img)" :key="item"
            />
          </a-image-preview-group>
        </div>
      </template>
      <template #operated_at="{ record }" >
        <span >{{record.operated_at===0?'-':moment(record.operated_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
      <template #action="{ record }" >
        <span>
          <a-button type="link" :disabled="record.status===1" style="padding:0" @click="openSolveDia(record)" >处理</a-button>
        </span>
      </template>
    </a-table>
  </a-card>


  <a-modal v-model:visible="openDia" title="处理反馈" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" >
        <a-form-item label="处理建议" name="operation_reason">
          <a-textarea placeholder="请输入处理建议，最多支持500字" :auto-size="{ minRows: 3 }" show-count :maxlength="500" v-model:value="formAdd.operation_reason" />
        </a-form-item>
      </a-form>
  </a-modal>

</template>
<script>
import { defineComponent } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
// import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';



export default defineComponent({
  components: {
    SearchOutlined
  },
  data(){
    return {
      UserRoleMap,
      openDia: false,
      currentData: {},
      activeKey: 0,
      uploadLoading: false,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',
      formSearch: {
        user_id: '',
      },
      formAdd: {
        operation_reason: '',
        operation_type: '',
      },
      rules: {
        operation_reason: [{ required: true, message: '请输入处理建议', trigger: 'blur' }],
      },
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      console.log(this.formSearch);
      const rsp = await request.get('/api/reports', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        ...this.formSearch,
        status: this.activeKey,
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    formatImage(images) {
      return JSON.parse(images)
    },
    openSolveDia(initial=null) {
      this.currentData = initial ? {...initial} : null
      this.openDia = true
    },
    handleConfirm() {
      this.$refs.formRef.validate()
      .then(async () => {
        const rsp = await request.post(`/api/reports/${this.currentData.id}/solve`, {
          ...this.formAdd,
        })
        this.resetFresh()
      })
      .catch(error => {
        console.log('error', error);
      });
    },
    resetFresh() {
      this.resetForm()
      this.openDia = false;
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    }
  },
  setup() {
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: '用户ID',
          dataIndex: 'user_id',
          key: 'user_id',
          width: 100,
        },
        {
          title: '提交人账号',
          dataIndex: 'username',
          key: 'username',
          width: 130,
        },
        {
          title: '原链接',
          dataIndex: 'origin_url',
          key: 'origin_url',
          width: 130,
        },
        {
          title: '原截图',
          dataIndex: 'origin_img',
          key: 'origin_img',
          slots: { customRender: 'origin_img' },
          width: 300,
        },
        {
          title: '抄袭链接',
          dataIndex: 'target_url',
          key: 'target_url',
          width: 120,
        },
        {
          title: '抄袭截图',
          dataIndex: 'target_img',
          key: 'target_img',
          slots: { customRender: 'target_img' },
          width: 300,
        },
        {
          title: '提交时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
          width: 100,
        },
        {
          title: '处理时间',
          dataIndex: 'operated_at',
          key: 'operated_at',
          slots: { customRender: 'operated_at' },
          width: 180,
        },
        {
          title: '处理人',
          dataIndex: 'operator',
          key: 'operator',
          width: 130,
        },
        {
          title: '处理建议',
          dataIndex: 'operation_reason',
          key: 'operation_reason',
          width: 180,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.report-images {
  &>img {
    height: 80px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
</style>