<template>
  <a-modal v-model:visible="visible" title="使用须知" :closable="false" :keyboard="false" :maskClosable="false" :footer="null" >
      <div>
        <p>欢迎各位KOC博主使用此平台，为了维护KOC整体生态，使用此平台，默认博主同意遵守以下几条约定。</p>
        <p>1.博主的拉新均为平台图文视频发布流量转化所得，严禁通过地推、积分墙等其他非KOC模式刷量，一经发现视为作弊，一律不结算，并停止合作。</p>
        <p>2.博主在平台发布的视频内容，以及推广的产品内容，均需合规合法，不得有任何涉政、涉黄等违法内容。</p>
        <p>3.博主在平台的创作应保证不侵犯他人合法权益，不恶意抄袭他人作品。经判定确认抄袭者，抄袭所得收益归被抄袭方。</p>
        <p>4.博主在合作过程中产生的拉新数据，以后台输出数据为准，对数据有疑问者，我们协助与官方确认，最终以官方反馈为准。</p>
        <p>5.博主不应将与骄阳合作的细节透露给其他第三方，包括：合作单价，后台系统等。否则骄阳保留追究责任的权利。</p>

      </div>
      <a-button type="primary" @click="onSubmit" html-type="submit" >同意</a-button>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import  { toRefs} from 'vue';
import request from '@/utils/request'
import { message } from 'ant-design-vue';



export default defineComponent({
  props: {
    record: Object
  },
  data(){
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      formState: {
        id: '',
        text: '',
        reason: ''
      }
    }
  },
  watch: {
    record: function(v1, v2) {
      this.formState.text = v1.text
      this.formState.id = v1.id
    }
  },
  methods: {
    async onSubmit(){
      await request.post(`/api/users/agreement`, {})
      this.$emit("ok")
    }
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    return { 
      visible,
    };
  },
});
</script>