<template>
  <a-card title="关键词平台配置">
    <a-row style="margin-bottom:10px"><a-button type="primary" @click="addPlatformConfig(null)">新增</a-button></a-row>
    <a-card>
      <div class="platform-config-list">
        <div class="platform-infos">
          <img src="https://jyyx-keyword.oss-cn-beijing.aliyuncs.com/image/platform/UC网盘.png" alt="">
          <div>
            <div>平台</div>
          </div>
        </div>
        <div>
          <a-button type="link" style="padding:0" @click="addPlatformConfig(null)">编辑</a-button>
          <a-divider type="vertical" />
          <a-button type="link" style="padding:0" >删除</a-button>
        </div>
      </div>
      <!-- <a-table 
        class="ant-table-striped"
        :row-key="record => record.id"
        :data-source="dataSource" 
        :columns="columns" 
        :loading="loading"
        :pagination="false"
        :scroll="{ x: 800 }"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
          <template #created_at="{ record }" >
            <span >{{moment(record.created_at*1000).format('YYYY-MM-DD HH:mm:ss')}}</span>
          </template>
          <template #action="{ record }" >
            <span>
              <a-button type="link" style="padding:0" @click="addPlatformConfig(record)" >编辑</a-button>
              <a-divider type="vertical" />
              <a-button type="link" style="padding:0" @click="deleteConfig(record)" >删除</a-button>
            </span>
          </template>
      </a-table> -->
    </a-card>
  </a-card>


</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';


export default defineComponent({
  components: {},
  data(){
    return {
      UserRoleMap,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      currentData: null,
    }
  },
  mounted(){
    if (this.user_role !== 1) {
      return
    }
    // this.handleTableChange()
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    addPlatformConfig() {
      this.$router.push({name: 'PlatformIndexConfig'})
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true
      try {
      
      const rsp = await request.get('/api/secret-books')
      this.loading = false
      this.dataSource = [].concat(rsp.data.secret_books)
      this.noticeMessage = `共 ${rsp.data.secret_books.length} 条`
      }catch(err){
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    deleteConfig(initial = {}) {
      let that = this;
      Modal.confirm({
        title: '确认删除？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            const rsp = await request.delete('/api/secret-books/'+initial.id)
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    
  },
  setup() {
    
    return {
      StatusMap: UserStatusMap,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: 100,
        },
        {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
          width: 150,
        },
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '类型',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '字数限制',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '下拉框数据',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '是否属于某项干预方式',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '是否必填',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '是否可编辑',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '干预方式',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '关键词提示',
          dataIndex: 'title',
          key: 'title',
          width: 150,
        },
        {
          title: '发布时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
          width: 180,
        },
        {
          title: '添加人',
          dataIndex: 'operator',
          key: 'operator',
          width: 150,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ],
    };
  },
})
</script>
<style scoped>
.platform-config-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.platform-infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
}
.platform-infos>img {
  width: 80px;
  margin-right: 10px;
}
.platform-infos>div {
  font-weight: 600;
  font-size: 16px;
}
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>