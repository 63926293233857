import { createApp } from 'vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import App from './App.vue';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

// import 'ant-design-vue/dist/reset.css';

// import DatePicker from 'ant-design-vue/es/date-picker/moment';
// import TimePicker from 'ant-design-vue/es/time-picker/moment';
// import Calendar from 'ant-design-vue/es/calendar/moment';

const app = createApp(App);
// app.config.productionTip = false;

// .use(DatePicker).use(TimePicker).use(Calendar)
app.use(Antd);

app.use(store).use(router).mount('#app')

document.title = "图书关键词管理系统"