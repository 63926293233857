<template>

  <a-card title="用户操作日志">
   
    <a-space>
     <a-form :model="formSearch" :label-col=" { span: 6 }" :wrapper-col="{ span: 14}" style="margin: 10px auto;" >
        <a-form-item label="用户" >
          <a-select
            v-model:value="formSearch._user_ids"
            :options="userSuggestion"
            @search="searchUsers"
            @change="onChangeUsers"
            mode="multiple"
            placeholder="用户名称"
            style="width: 200px"
          >
          </a-select>
        </a-form-item>
         <a-form-item label="内容 ID">
          <a-input placeholder="内容 ID" v-model:value="formSearch.content_id" />
        </a-form-item>
        <a-form-item label="内容">
          <a-input placeholder="内容" v-model:value="formSearch.content" />
        </a-form-item>
      <a-form-item>
         <a-button
            type="primary"
            @click="handleTableChange()"
          >
          搜索
          </a-button>
      </a-form-item>
     
    </a-form>
    </a-space>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => `${record.keyword_id}+${record.stats_date}`"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #role="{ record }">
        <a>{{ RoleMap[record.role] }}</a>
      </template>
     
       <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-DD-MM HH:mm:ss')}}</span>
      </template>
    </a-table>
  </a-card>

</template>
<script >
import { defineComponent} from 'vue'

import { message } from 'ant-design-vue';
import request from '@/utils/request'

import {UserRoleMap, KeywordStatusMap} from '@/utils/macro'
import _ from 'lodash'
import { useStore } from 'vuex'
import moment from 'moment'

let _searchUserDebounce = null 


export default defineComponent({
  components: {},
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
    
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        _user_ids: [],
        user_id: '',
        content: '',
  
      },

      userSuggestion: [],

    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  mounted(){
    this.searchUsers()
    this.handleTableChange()
  },
  methods: {
    onChangeUsers(item, options){
     if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._user_ids = [this.formSearch._user_ids[0]]
        return
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.user_id = ids.join(',')
    },
    
    searchUsers(userName) {
      clearTimeout(_searchUserDebounce)
      _searchUserDebounce =  setTimeout(() => {
          request.get(`/api/users`, {
            username: userName,
            limit: 200,
          }).then((rsp) => {
            this.userSuggestion = []
            for (let v of rsp.data.list) {
              this.userSuggestion.push({
                value: `${v.username} (${v.id})`,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
     
    getQuery(param){
      return {
        ...param,
        ...this.formSearch,
      }
    },
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true

     
      const rsp = await request.get('/api/audits/list', this.getQuery({
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
      }))
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
   
    return {
     
      columns: [
        {
          title: 'User ID',
          dataIndex: 'user_id',
          key: 'user_id',
        },
         {
          title: 'Content ID',
          dataIndex: 'content_id',
          key: 'content_id',
        },
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username',
        },
         {
          title: '关键词',
          dataIndex: 'keyword_name',
          key: 'keyword_name',
        },
         {
          title: '信息',
          dataIndex: 'content',
          key: 'content',
          width: 300,
        },
       
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
        },
         {
          title: '日期',
          dataIndex: 'created_at',
          key: 'created_at',
        }
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>