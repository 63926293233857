<template>

  <a-card title="通知消息">
   
    <a-space>
     <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
       
       <a-form-item label="平台" >
          <a-select
            v-model:value="formSearch.platform"
            placeholder="平台"
            style="width: 200px"
          >
            <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
              {{i}}
            </a-select-option>
          </a-select>
        </a-form-item>
      
        <a-form-item label="通知日期" >
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      
        <a-form-item label="类型" >
          <a-radio-group v-model:value="formSearch.types"  >
            <a-radio-button value="">无</a-radio-button>
            <a-radio-button value="keyword_stats,keyword_audit">系统通知</a-radio-button>
            <a-radio-button value="operation">运营通知</a-radio-button>
          </a-radio-group>
        </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          @click="handleTableChange()"
        >
         查询
        </a-button>
      </a-form-item>
     
    </a-form>
    </a-space>
    

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => record.director_name"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
    <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
      </template>
  </a-table>
  </a-card>
</template>
<script >
import { defineComponent, ref} from 'vue'
import { message } from 'ant-design-vue';
import _ from 'lodash'
import { useStore } from 'vuex'
import moment from 'moment'

import request from '@/utils/request'
import {UserRoleMap, KeywordStatusMap, PlatformEnum} from '@/utils/macro'


export default defineComponent({
  components: {
  },
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        query: '',
        platform: '',
        time_range: [],
        types: '',
      },
    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{
    'formSearch.types': function(v1, v2){
      this.handleTableChange()
    }
  },
  mounted(){
    this.handleTableChange()
  },
  methods: {
   
    async handleTableChange (pagi = {pageSize: 10, current: 1}) {
      this.loading = true

      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      const rsp = await request.get('/api/notifications', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        platform: this.formSearch.platform,
        time_range: timeRange.join(','),
        types: this.formSearch.types
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
    const timeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!timeRange.value || timeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(timeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        timeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      timeRange.value = val;
    };


    return {
      PlatformEnum,
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
      {
          title: '平台',
          dataIndex: 'platform',
          key: 'platform',
        },
         
        {
          title: '通知内容',
          dataIndex: 'content',
          key: 'content',
        },
         
         {
          title: '通知时间',
          dataIndex: 'created_at',
          key: 'created_at',
          slots: { customRender: 'created_at' },
        }
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>